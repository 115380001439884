
































































































































import Vue from 'vue'
import { uploadConstructionFile } from '@/services/Documents'
import { createNamespacedHelpers } from 'vuex'
import { getConstructionSubfoldersByDefaultFolderId, createSubfolder } from '@/services/Construction'

import LoadingSpinner from '@/components/utils/Loading.vue'

const constructionModule = createNamespacedHelpers('constructions')

export default Vue.extend({
  name: 'AddSubfolder',

  components: {
    LoadingSpinner
  },

  data () {
    return {
      dialog: false as boolean,
      formData: new FormData() as any,
      id: 333 as number,
      isLoading: false as boolean,
      subfolderName: '' as string,
      rules: {
        required: (value: any) => (value && value !== undefined) || 'Requerido'
      },
      valid: true as boolean
    }
  },
  computed: {
    ...constructionModule.mapState([
      'idCurrentSubfolder'
    ])
  },
  methods: {

    ...constructionModule.mapActions([
      'setObjectFilesToShow'
    ]),

    async inputFilehandleChange (e: any) {
      if (e.target.files[0]) {
        this.isLoading = true
        this.file = e.target.files[0]
        await this.uploadingFile(this.file)
        await this.refreshFileList()
        this.file = {}
        this.isLoading = false
      }
      this.dialog = false
    },

    async refreshFileList () {
      try {
        const response = await getConstructionSubfoldersByDefaultFolderId(this.idCurrentSubfolder)
        await this.setObjectFilesToShow(response.fileChildren)
      } catch (error) {
        console.error(error)
      }
    },

    async createSubfolder () {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      if (this.subfolderName !== '') {
        const subfolderData = {
          name: this.subfolderName,
          parentId: this.idCurrentSubfolder
        }
        try {
          const response = await createSubfolder(subfolderData)
        } catch (error: any) {
        } finally {
          this.formData = new FormData()
          this.$emit('setReRender')
          this.dialog = false
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          // this.$router.go()
        }
      }
    }
  }
})
