

















































import Vue from 'vue'
import { createNamespacedHelpers } from 'vuex'

const constructionsModule = createNamespacedHelpers('constructions')

/*eslint-disable */
export default Vue.extend({
  name: 'ConstructionUserItem',
  props: {
    user: Object,
    rol: String
  },
  methods: {
    ...constructionsModule.mapActions([
      'setConstructionManager',
      'setProductionDirector',
      'setGroupManager'
  ]),
    setUser () {
      switch (this.rol) {
        case 'Jefe de Obra': {
          this.setConstructionManager(this.user)
        }; break

        case 'Director de producción': {
          this.setProductionDirector(this.user)
        }; break

        case 'Jefe de Grupo': {
          this.setGroupManager(this.user)
        }; break
      }
      this.$emit('closeDialog')
    }
  }

})
