import { SupplierList } from '@/store/types/index'

import { getSuppliersByPage } from '@/services/Supplier'

const state = {
  photoProfile: null,
  photoProfileUrl: null,
  arrayChoosenSubcategoriesIds: [],
  rec: {},
  supplierObjectForEdit: {},
  supplierList: {} as SupplierList,
  supplierSearchFormObject: {},
  supplierListSearchByField: {} as SupplierList,
  loggedInSupplierId: null,
  selectedSupplier: {}
}

const actions = {

  async refreshSupplierListByPage ({ commit }: any) {
    const page = state.supplierList && state.supplierList.currentPage
    const suppliers = await getSuppliersByPage(true, page)
    commit('SETTING_SUPPLIER_LIST', suppliers)
  },

  async setArrayChoosenSubcategoriesIds ({ commit }: any, arrSubategories: any) {
    await commit('SET_ARRAY_CHOOSEN_SUBCATEGORIES_IDS', arrSubategories)
  },

  async setSupplierObjectForEdit ({ commit }: any, payload: any) {
    await commit('SET_OBJECT_FOR_EDIT', payload)
  },

  async setSupplierList ({ commit }: any, supplierList: any) {
    commit('SETTING_SUPPLIER_LIST', supplierList)
  },

  async settingSupplierListByFields ({ commit }:any, suppliersList: any) {
    commit('SETTING_SUPPLIER_LIST_BY_FIELDS', suppliersList)
  },

  async resetRec ({ commit }: any) {
    await commit('RESET_REC')
  },

  async resetSupplierList ({ commit }: any) {
    await commit('RESET_SUPPLIER_LIST')
  },

  async resetSupplierListByField ({ commit }: any) {
    await commit('RESET_SUPPLIER_LIST_BY_FIELDS')
  },

  async resetSupplierSearchForm ({ commit }:any) {
    await commit('RESET_SUPPLIER_SEARCH_OBJECT_FORM')
  },

  async setPhotoProfile ({ commit }: any, photo: any) {
    await commit('SET_PHOTO_PROFILE', photo)
  },

  async resetPhotoProfile ({ commit }: any) {
    await commit('RESET_PHOTO_PROFILE')
  },

  async setPhotoProfileUrl ({ commit }: any, photo: any) {
    await commit('SET_PHOTO_PROFILE_URL', URL.createObjectURL(photo))
  },

  async resetPhotoProfileUrl ({ commit }: any) {
    await commit('RESET_PHOTO_PROFILE_URL')
  },

  async updateSupplierId ({ commit }, supplierId: string) {
    commit('SET_SUPPLIER_ID', supplierId)
  },

  async setSelectedSupplier ({ commit }, supplier: any) {
    commit('SET_SELECTED_SUPPLIER', supplier)
  }

}

const mutations = {

  SET_PHOTO_PROFILE (state: any, photo: any) {
    state.photoProfile = photo
  },

  RESET_PHOTO_PROFILE (state: any) {
    state.photoProfile = null
  },

  SET_PHOTO_PROFILE_URL (state: any, photo: any) {
    state.photoProfileUrl = photo
  },

  RESET_PHOTO_PROFILE_URL (state: any) {
    state.photoProfileUrl = null
  },

  RESET_REC (state: any) {
    state.rec = {}
  },

  RESET_SUPPLIER_LIST (state: any) {
    state.supplierList = {}
  },

  RESET_SUPPLIER_LIST_BY_FIELDS (state: any) {
    state.supplierListSearchByField = {}
  },

  RESET_SUPPLIER_SEARCH_OBJECT_FORM (state: any) {
    state.supplierSearchFormObject = {}
  },

  SET_OBJECT_FOR_EDIT (state: any, payload: any) {
    state.supplierObjectForEdit = payload
  },

  SET_ARRAY_CHOOSEN_SUBCATEGORIES_IDS (state: any, arrSubcategoriesIds:any) {
    state.arrayChoosenSubcategoriesIds = arrSubcategoriesIds
  },

  SETTING_SUPPLIER_LIST (state: any, supplierList: any) {
    state.supplierList = supplierList
  },

  SETTING_SUPPLIER_LIST_BY_FIELDS (state: any, supplierList: any) {
    state.supplierListSearchByField = supplierList
  },

  SET_SUPPLIER_ID (state, supplierId: string) {
    state.loggedInSupplierId = supplierId
  },

  SET_SELECTED_SUPPLIER (state, supplier: any) {
    state.selectedSupplier = supplier
  }
}

export const suppliers = {
  namespaced: true,
  actions,
  mutations,
  state
}
