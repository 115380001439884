

























































































import Vue from 'vue'
import { createNamespacedHelpers } from 'vuex'

const proformaModule = createNamespacedHelpers('proforma')

export default Vue.extend({

  name: 'RowComponent',

  data () {
    return {
      onMonthQuantity: 0 as number,
      onMonthAmount: 0 as number,
      totalOriginQuantity: '' as string,
      totalOriginAmount: 0 as number,
      totalBeforeAmount: 0 as number
    }
  },

  props: {

    isDisabled: {
      type: Boolean,
      required: false
    },

    isEditComponent: {
      type: Boolean,
      required: true
    },

    proformaMeasurements: {
      type: Object,
      required: true
    }
  },

  computed: {
    ...proformaModule.mapState([
      'isLiquidationProforma',
      'newProformaObject'
    ]),

    bgColor () {
      if (this.proformaMeasurements.isFromExtension) {
        return 'extension-bg'
      } else {
        return 'no-extension-bg'
      }
    }
  },

  methods: {

    onInputHandler () {
      if (this.isLessQuantityThanContractQuantity()) {
        const numberA = this.proformaMeasurements.measurement.unitPrice || 0
        const numberB = this.totalOriginQuantity || ''

        this.totalOriginAmount = numberA * Number(numberB)

        this.calculateOnMonthValues()

        this.functionEmitProformaData()
      }
    },

    isLessQuantityThanContractQuantity (): boolean {
      const contractQuantity = parseInt(this.proformaMeasurements.measurement.measurement)
      const beforeQuantity = parseInt(this.proformaMeasurements.unitsPaidBefore)

      // if (parseInt(this.totalOriginQuantity) > contractQuantity) {
      //   this.$fire({
      //     title: 'Error de cantidad',
      //     text: 'La cantidad seleccionada supera la cantidad del contrato',
      //     type: 'error',
      //     confirmButtonColor: '#F7A549'
      //   })
      //   this.totalOriginQuantity = 0
      //   return false
      // }

      return true
    },

    calculateLiquidation () {
      const result = Number(this.proformaMeasurements.measurement.measurement)
      this.totalOriginQuantity = result.toString()

      this.calculateOnMonthValues()
      this.functionEmitProformaData()
    },

    calculateOnMonthQuantity () {
      if (this.totalOriginQuantity > 0) {
        this.onMonthQuantity = Number(this.totalOriginQuantity) - this.proformaMeasurements.unitsPaidBefore
      } else {
        this.onMonthQuantity = 0
      }
    },

    calculateOnMonthAmount () {
      this.onMonthAmount = this.onMonthQuantity * this.proformaMeasurements.measurement.unitPrice
    },

    calculateOriginValues () {
      const numberA = this.proformaMeasurements.measurement.unitPrice || 0
      const numberB = this.proformaMeasurements.totalUnitsPaid || 0

      this.totalOriginQuantity = this.proformaMeasurements.totalUnitsPaid
      this.totalOriginAmount = numberA * numberB
    },

    calculateOnMonthValues () {
      this.calculateOnMonthQuantity()
      this.calculateOnMonthAmount()
    },

    calculateBeforeValues () {
      this.totalBeforeAmount = this.proformaMeasurements.unitsPaidBefore * this.proformaMeasurements.measurement.unitPrice
    },

    functionEmitProformaData () {
      this.$emit(
        'emitProformaData',
        this.proformaMeasurements.id,
        this.totalOriginQuantity,
        this.proformaMeasurements.totalUnitsPaid,
        this.proformaMeasurements.measurement.unitPrice
      )
    },

    resetProformaToInitial () {
      this.totalOriginQuantity = ''
      this.onMonthQuantity = 0
      this.onMonthAmount = 0
      this.functionEmitProformaData()
    }

  },

  /* watch: {
    isLiquidationProforma (value: boolean) {
      if (value) {
        this.calculateLiquidation()
      } else {
        this.resetProformaToInitial()
      }
    }
  }, */

  beforeMount () {
    if (this.isEditComponent) {
      this.calculateOriginValues()
      this.calculateOnMonthValues()
      this.calculateBeforeValues()
    }
  }
})
