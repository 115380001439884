import { CurrentUser } from '@/store/types/index'

const state = {
  isShowRemberPassword: false as boolean,
  isShowLanding: true as boolean
}

const actions = {

  showingRemenberPasswordComponent ({ commit }: any) {
    commit('SET_SHOW_REMENBER_PASSWORD')
  }

}

const mutations = {

  SET_SHOW_REMENBER_PASSWORD (context: { isShowRemberPassword: boolean }) {
    context.isShowRemberPassword = true
  }

}

export const login = {
  namespaced: true,
  state,
  actions,
  mutations
}
