export enum Sections {
  HOME = 'Home',
  PROVIDERS = 'Providers',
  USERS = 'Users',
  STATS = 'Stats',
  NOTIFICATIONS = 'Notifications',
  CONSTRUCTIONS = 'Constructions',
  CONTRACTS = 'Contracts',
  PROFORMA = 'Proforma',
  EXTENSION = 'Extension',
  BILLS = 'Bills'
}
