




















































































































































































































import Vue from 'vue'
import { createNamespacedHelpers } from 'vuex'

import moment from 'moment'

import LoadingSpinner from '@/components/utils/Loading.vue'

import { addSubcategoryToSupplier, createSupplier, updateProfileImage } from '@/services/Supplier'
import { getSubcategoryByCategoryId } from '@/services/Category'

const categoriesModule = createNamespacedHelpers('categories')
const globalModule = createNamespacedHelpers('global')
const supplierModule = createNamespacedHelpers('suppliers')

export default Vue.extend({

  name: 'NewSupplierForm',

  components: {
    LoadingSpinner
  },
  data () {
    return {
      categoryIdLocal: 0 as number,
      isEmailRegistered: false,
      emailRegisteredMessage: '',
      isLoading: false as boolean,
      rules: {
        required: value => !!value || 'Requerido'
      },
      subcategoriesList: [],
      subcategoryLocal: null,
      supplierDateMenu: '',
      valid: false
    }
  },

  computed: {

    ...categoriesModule.mapState(
      ['categoriesGlobalList']
    ),
    ...globalModule.mapState([
      'currentDate'
    ]),
    ...supplierModule.mapState(
      ['rec']
    ),
    ...supplierModule.mapState(
      ['photoProfile']
    )

  },

  methods: {

    ...supplierModule.mapActions([
      'resetRec',
      'resetSupplierList',
      'resetPhotoProfile'
    ]),

    async addingCategory (response: any) {
      if (response && response.message && response.message.id) {
        const suppliedId = response.message.id
        await addSubcategoryToSupplier(this.subcategoryLocal, suppliedId)
      }
    },
    resetErrorState () {
      this.isEmailRegistered = false
      this.emailRegisteredMessage = ''
    },

    async actionCreateSupplier () {
      console.log(this.$refs.form.validate())
      this.resetErrorState()
      console.log(this.$refs.form.validate())
      const isFormValid = this.$refs.form.validate()
      if (isFormValid) {
        try {
          this.isLoading = true
          const response = await createSupplier(this.rec)
          await this.updatePhotoProfile(response.message.id)
          await this.addingCategory(response)
          this.$router.push({ name: 'Suppliers' })
          await this.resetRec()
          this.resetPhotoProfile()
        } catch (error) {
          if (error.response && error.response.data && error.response.data.message === 'El correo electrónico ya está registrado como usuario.') {
            this.isEmailRegistered = true
            this.emailRegisteredMessage = 'Correo ya registrado, use otro'
          }
        } finally {
          this.isLoading = false
        }
      }
    },

    async updatePhotoProfile (id: any) {
      try {
        this.isLoading = true
        updateProfileImage(id, this.photoProfile)
      } catch (error) {
        console.dir(error)
      } finally {
        this.isLoading = false
      }
    }

  },

  watch: {

    async 'categoryIdLocal' (value) {
      try {
        this.isLoading = true

        const categoryId = this.categoriesGlobalList.find((category) => category.name === value).id
        const response = await getSubcategoryByCategoryId(categoryId)

        this.subcategoriesList = response
      } catch (error) {
        console.error(error)
      } finally {
        this.isLoading = false
      }
    },

    'rec.entryDate' (value) {
      this.supplierDateMenu = moment(value).format('DD/MM/YYYY')
    }
  },

  beforeDestroy () {
    this.resetRec()
    this.resetSupplierList()
  }

})
