import { RouteConfig } from 'vue-router'
import SuppliersManagment from '@/views/suppliers/SuppliersManagment.vue'
import NewSupplier from '@/views/suppliers/NewSupplier.vue'
import SearchSupplier from '@/views/suppliers/SearchSupplier.vue'
import EditSupplier from '@/views/suppliers/EditSupplier.vue'
import SuppliersLogin from '@/views/suppliersSubdomain/SuppliersLogin.vue'
import SuppliersUploadInvoice from '@/views/suppliersSubdomain/SuppliersUploadInvoice.vue'
import Vue from 'vue'
import { Roles } from '@/shared/enums/Roles'

const commonRoles = [
  Roles.GROUP_MANAGER,
  Roles.PRODUCTION_DIRECTOR,
  Roles.ADMINISTRATION,
  Roles.CFO,
  Roles.CONSULTANT,
  Roles.ADMIN,
  Roles.SHOPPING,
  Roles.PROVIDER
]

const routes: Array<RouteConfig> =
[
  {
    path: '/suppliers',
    name: 'Suppliers',
    component: SuppliersManagment,
    meta: {
      requiresAuth: true,
      requiredRoles: commonRoles
    }
  },
  {
    path: '/suppliers/edit/:id',
    name: 'EditSupplier',
    component: EditSupplier,
    meta: {
      requiresAuth: true,
      requiredRoles: commonRoles
    }
  },
  {
    path: '/suppliers/new',
    name: 'NewSuppliers',
    component: NewSupplier,
    meta: {
      requiresAuth: true,
      requiredRoles: commonRoles.filter(rol => [
        Roles.GROUP_MANAGER,
        Roles.PRODUCTION_DIRECTOR,
        Roles.ADMIN,
        Roles.SHOPPING
      ].includes(rol))
    }
  },
  {
    path: '/suppliers/search',
    name: 'SearchSupplier',
    component: SearchSupplier,
    meta: {
      requiresAuth: true,
      requiredRoles: commonRoles
    }
  },
  {
    path: '/suppliers/login',
    name: 'SuplierLogin',
    component: SuppliersLogin,
    meta: {
      requiresAuth: false,
      supplierInvoiceRoute: true
    },
    beforeEnter: (to, from, next) => {
      Vue.$cookies.remove('token')
      Vue.$cookies.remove('user')
      next()
    }
  },
  {
    path: '/suppliers/upload-invoice',
    name: 'SuplierUploadInvoice',
    component: SuppliersUploadInvoice,
    meta: {
      requiresAuth: true,
      supplierInvoiceRoute: true
    },
    beforeEnter: (to, from, next) => {
      const user = Vue.$cookies.isKey('user')
      if (user) {
        next('/suppliers/login')
      } else {
        next()
      }
    }
  }
]

export default routes
