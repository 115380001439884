import __axios from '@/plugins/axios'
import Vue from 'vue'

function getHeaders () {
  const token = Vue.$cookies.get('token')
  return token ? { Authorization: `Bearer ${token}` } : {}
}

async function findOne (billId: number) {
  const response = await __axios.get(`api/bill/${billId}`, { headers: getHeaders() })
  return response
}

async function updateSigns (billId: number, payload: any) {
  const response = await __axios.put(`api/bill/${billId}/update-signs`, payload, { headers: getHeaders() })
  return response
}

async function downloadFile (billId: number) {
  const response = await __axios.get(`api/bill/${billId}/download`, {
    headers: getHeaders(),
    responseType: 'blob'
  })
  return response
}

async function sendSupplierInvoice (invoiceData: Record<any, any>): Promise<any> {
  return await __axios.post('api/bill', invoiceData, { headers: getHeaders() })
}

async function uploadInvoiceFile (file: any, contractId: number): Promise<any> {
  const formData = new FormData()
  formData.append('file', file)
  const response = await __axios.post(`api/bill/upload-file/${contractId}`, formData, { headers: getHeaders() })
  return response
}

async function remove (invoiceId: number): Promise<any> {
  return await __axios.delete(`api/bill/${invoiceId}`, { headers: getHeaders() })
}

async function getBillsPendingToSignByUser (userId: number) {
  const response = await __axios.get(`api/bill/pendings-to-sign/${userId}`, { headers: getHeaders() })
  return response
}

async function sendByEmail (payload: any) {
  const response = await __axios.post('api/bill/sendByMail', payload, { headers: getHeaders() })
  return response
}

export {
  findOne,
  updateSigns,
  downloadFile,
  sendSupplierInvoice,
  uploadInvoiceFile,
  remove,
  getBillsPendingToSignByUser,
  sendByEmail
}
