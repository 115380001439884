import { render, staticRenderFns } from "./SearchSupplier.vue?vue&type=template&id=24704718&scoped=true&lang=pug&"
import script from "./SearchSupplier.vue?vue&type=script&lang=ts&"
export * from "./SearchSupplier.vue?vue&type=script&lang=ts&"
import style0 from "./SearchSupplier.vue?vue&type=style&index=0&id=24704718&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "24704718",
  null
  
)

export default component.exports