import __axios from '@/plugins/axios'
import Vue from 'vue'

function getHeaders () {
  const token = Vue.$cookies.get('token')
  return token ? { Authorization: `Bearer ${token}` } : {}
}

async function createUser (objUser: unknown) {
  return await __axios.post('api/user', objUser, { headers: getHeaders() })
}

async function deleteUserbById (userId: number) {
  const response = await __axios.delete(`api/user/${userId}`, { headers: getHeaders() })
  return response
}

async function findUsersByRoles (arrRolsId: any, page: number) {
  const response = await __axios.post(`api/user/find-by-rols/page/${page}`, arrRolsId, { headers: getHeaders() })
  return response.data
}

async function getUsersByPage (page: number) {
  const response = await __axios.get(`api/user/page/${page}`, { headers: getHeaders() })
  return response.data
}

async function uploadProfileImage (userId: any, img: File) {
  const formData = new FormData()
  formData.append('file', img)
  const response = await __axios.put(`api/user/${userId}/profile-image`, formData, { headers: getHeaders() })
  return response.data
}

async function getProfileImage (userId: any) {
  const response = await __axios.get(`api/user/${userId}/profile-image`, {
    responseType: 'blob',
    headers: getHeaders()
  })
  return response.data
}

async function uploadProfileSign (userId: any, img: File) {
  const formData = new FormData()
  formData.append('signature', img)
  const response = await __axios.put(`api/user/${userId}/signature`, formData, { headers: getHeaders() })
  return response.data
}

async function getProfileSign (userId: any) {
  const response = await __axios.get(`api/user/${userId}/signature`, {
    responseType: 'blob',
    headers: getHeaders()
  })
  return response.data
}

async function getGroupLeaders () {
  const response = await __axios.get('api/user/rol/get-group-leaders', { headers: getHeaders() })
  return response.data
}

async function getUserbById (userId: number) {
  const response = await __axios.get(`api/user/${userId}`, { headers: getHeaders() })
  return response.data
}

async function modifyUserbById (userId: number, userObject: unknown) {
  const response = await __axios.put(`api/user/${userId}`, userObject, { headers: getHeaders() })
  return response
}

async function findUsersByRol (rolId: any) {
  const response = await __axios.get(`api/user/find-by-rol/${rolId}`, { headers: getHeaders() })
  return response.data
}

export {
  deleteUserbById,
  createUser,
  findUsersByRoles,
  getUsersByPage,
  getGroupLeaders,
  getUserbById,
  modifyUserbById,
  uploadProfileImage,
  getProfileImage,
  uploadProfileSign,
  getProfileSign,
  findUsersByRol
}
