
































































import Vue from 'vue'
import { createNamespacedHelpers } from 'vuex'

import FolderIcon from '@/components/Constructions/FolderIcon.vue'

const constructionModule = createNamespacedHelpers('constructions')

export default Vue.extend({
  name: 'ConstructionDocumentation',
  components: {
    FolderIcon
  },
  data () {
    return {
      isDataLoaded: false as boolean,
      rows: 9 as number
    }
  },
  computed: {
    ...constructionModule.mapState([
      'defaultFolders'
    ])
  },
  async beforeMount () {
    this.isDataLoaded = true
  }
})
