

























































































import Vue from 'vue'
import { createNamespacedHelpers } from 'vuex'

import { getProformaById, updateSigns, editProforma } from '@/services/Proforma'
import BackArrowComp from '@/components/utils/BackArrowComp.vue'
import LoadingSpinner from '@/components/utils/Loading.vue'
import ProformaContractDataHeader from '@/components/Proforma/table/ProformaContractDataHeader.vue'
import ProformaTable from '@/components/Proforma/ProformaTable.vue'
import SignComponent from '@/components/shared/SignComponent.vue'
import { defaultPermissions } from '@/shared/types/Permission'
import { Sections } from '@/shared/enums/Sections'
import { PermissionsMixin } from '@/mixins/PermissionsMixin'
import { RolNames } from '@/shared/enums/Roles'
import ConfirmAction from '@/components/Modals/ConfirmAction.vue'

const proformaModule = createNamespacedHelpers('proforma')
const contractModule = createNamespacedHelpers('contracts')
const globalModule = createNamespacedHelpers('global')
const suppliersModule = createNamespacedHelpers('suppliers')

export default Vue.extend({

  name: 'NewProforma',
  mixins: [
    PermissionsMixin
  ],
  components: {
    BackArrowComp,
    LoadingSpinner,
    ProformaContractDataHeader,
    ProformaTable,
    SignComponent,
    ConfirmAction
  },

  data () {
    return {
      dataObjectList: {
        construction: {
          isDisabled: false,
          rolName: 'Jefe de obra',
          signed: false,
          user: {
            name: ''
          }
        },
        group: {
          isDisabled: false,
          rolName: 'Jefe de grupo',
          signed: false,
          user: {
            name: ''
          }
        },
        production: {
          isDisabled: false,
          rolName: 'Director de producción',
          signed: false,
          user: {
            name: ''
          }
        }
      },
      isDataLoaded: false as boolean,
      isDisabled: false as boolean,
      isLiquidationButtonState: false as boolean,
      isLoading: false as boolean,
      isShowingActionAfterSigns: false as boolean,
      proformData: {},
      reRender: 0,
      permissions: defaultPermissions,
      showConfirmModal: false,
      modalTitle: '',
      modalSubtitle: ''
    }
  },

  computed: {

    ...contractModule.mapState([
      'objectCurrentConstruction',
      'rec',
      'selectedContractId'
    ]),

    ...suppliersModule.mapState([
      'selectedSupplier'
    ]),

    currentUser () {
      return this.$cookies.get('user')
    },

    ...proformaModule.mapState([
      'newProformaObject'
    ])

  },

  methods: {
    ...proformaModule.mapActions([
      'setIsLiquidationProforma',
      'setNewProformaObject'
    ]),

    ...globalModule.mapActions([
      'setRefreshPendingAlerts'
    ]),

    checkCurrentStep () {
      if (
        this.newProformaObject.signedByCM === false &&
        this.currentUser.rol.id === 1
      ) {
        this.dataObjectList.construction.isDisabled = false
        this.dataObjectList.group.isDisabled = true
        this.dataObjectList.production.isDisabled = true
      } else if (
        this.newProformaObject.signedByCM === true &&
        this.newProformaObject.signedByGM === false &&
        this.currentUser.rol.id === 3
      ) {
        this.dataObjectList.construction.isDisabled = true
        this.dataObjectList.group.isDisabled = false
        this.dataObjectList.production.isDisabled = true
      } else if (
        this.newProformaObject.signedByCM === true &&
        this.newProformaObject.signedByGM === true &&
        this.newProformaObject.signedByPD === false &&
        this.currentUser.rol.id === 4
      ) {
        this.dataObjectList.construction.isDisabled = true
        this.dataObjectList.group.isDisabled = true
        this.dataObjectList.production.isDisabled = false
      } else {
        this.dataObjectList.construction.isDisabled = true
        this.dataObjectList.group.isDisabled = true
        this.dataObjectList.production.isDisabled = true
      }
    },

    promptConfirmation (newValue) {
      this.confirmationValue = newValue
      this.modalTitle = newValue ? '¿Seguro que quieres marcar esta proforma como de autoliquidación?' : '¿Seguro que quieres desmarcar esta proforma como de autoliquidación?'
      this.modalSubtitle = ''
      this.showConfirmModal = true
    },

    confirmAction () {
      this.editProformaLiquidation()
      this.showDialog = false
    },

    cancelAction () {
      console.log(111111)
      this.showConfirmModal = false
      this.switchKey += 1
    },

    confirmLiquidation () {
      this.isLiquidationButtonState = this.confirmationValue
      this.showConfirmModal = false
    },

    checkSomeoneIsSigned () {
      if (
        this.newProformaObject.signedByCM &&
        this.newProformaObject.signedByGM
      ) {
        this.isShowingActionAfterSigns = true
      } else {
        this.checkCurrentStep()
      }
    },

    async gettigProforma () {
      try {
        const response = await getProformaById(this.$router.currentRoute.params.id)
        await this.setNewProformaObject(response)
      } catch (error) {
        console.error(error)
      }
    },

    async getProformaAgain () {
      await this.gettigProforma()
      this.reRender += 1
    },

    async firstThingToDo () {
      try {
        this.isLoading = true
        await this.gettigProforma()
        this.settingUsersDataObjectList()
        this.settingSignsDataObjectList()
        this.isLiquidationButtonState = this.newProformaObject.liquidation
        this.setIsLiquidationProforma(this.newProformaObject.liquidation)
        this.settingDisabledSignForNoAuthorizedUsers()
      } catch (error) {
        console.error(error)
      } finally {
        this.isDataLoaded = true
        this.isLoading = false
      }
    },

    settingIsLiquidation (event: boolean) {
      if (event) {
        this.setIsLiquidationProforma(true)
      } else {
        this.setIsLiquidationProforma(false)
      }
    },

    settingSignsDataObjectList () {
      this.dataObjectList.construction.signed = this.newProformaObject.signedByCM
      this.dataObjectList.group.signed = this.newProformaObject.signedByGM
      this.dataObjectList.production.signed = this.newProformaObject.signedByPD
    },

    settingUsersDataObjectList () {
      this.dataObjectList.construction.user = this.newProformaObject.constructionManager
      this.dataObjectList.group.user = this.newProformaObject.groupManager
      this.dataObjectList.production.user = this.newProformaObject.productionDirector
    },

    settingDisabledSignForNoAuthorizedUsers () {
      if (this.currentUser.rol.id === 1 ||
          this.currentUser.rol.id === 3 ||
          this.currentUser.rol.id === 4 ||
          this.currentUser.rol.id === 2
      ) {
        this.checkSomeoneIsSigned()
      } else {
        this.isDisabled = true
        this.dataObjectList.construction.isDisabled = true
        this.dataObjectList.group.isDisabled = true
        this.dataObjectList.production.isDisabled = true
      }
    },

    async signProforma (user: any) {
      try {
        const response = await updateSigns(this.newProformaObject.id, this.updateSignPayload(user))

        if (response.statusCode && response.statusCode === 200) {
          this.updateDataObjectListSign(user)
          this.setRefreshPendingAlerts()
          this.$showSuccess('Firma realizada!')
        }
      } catch (error) {
        console.log('error: ', error)
        if (error.response.status === 409) {
          this.$fire({
            title: 'Error enviando proforma a Navision',
            text: 'no es posible firmar el documento, inténtalo más tarde o ponte en contacto con el administrador del sistema',
            type: 'error',
            confirmButtonColor: '#F7A549'
          })
        }
      }
    },

    updateSignPayload (user: any) {
      const payload = {
        signedByCM: this.newProformaObject.signedByCM,
        signedByGM: this.newProformaObject.signedByGM,
        signedByPD: this.newProformaObject.signedByPD
      }

      switch (user.rolName.toUpperCase()) {
        case RolNames.CONSTRUCTION_MANAGER.toUpperCase(): payload.signedByCM = true; break
        case RolNames.GROUP_MANAGER.toUpperCase(): payload.signedByGM = true; break
        case RolNames.PRODUCTION_DIRECTOR.toUpperCase(): payload.signedByPD = true; break
      }

      return payload
    },

    updateDataObjectListSign (user: any) {
      switch (user.rolName.toUpperCase()) {
        case RolNames.CONSTRUCTION_MANAGER.toUpperCase(): this.dataObjectList.construction.signed = true; break
        case RolNames.GROUP_MANAGER.toUpperCase(): this.dataObjectList.group.signed = true; break
        case RolNames.PRODUCTION_DIRECTOR.toUpperCase():
          this.dataObjectList.production.signed = true
          this.signProductionDirector(); break
      }
    },

    signProductionDirector () {
      this.isShowingActionAfterSigns = true
    },

    disableSign (rolName: any): boolean {
      switch (rolName.toUpperCase()) {
        case RolNames.GROUP_MANAGER.toUpperCase(): return !this.newProformaObject.signedByCM
        case RolNames.PRODUCTION_DIRECTOR.toUpperCase():
          return !this.newProformaObject.signedByCM && !this.newProformaObject.signedByGD
      }
      return false
    },

    async editProformaLiquidation () {
      try {
        await editProforma(this.newProformaObject.id, { liquidation: this.isLiquidationButtonState })
      } catch (error) {
        this.$showError('Error al actualizar proforma de liquidación')
        console.log(error)
      }
    },

    checkPermissions () {
      this.permissions = this.getPermissions(Sections.PROFORMA)
    }
  },

  watch: {
    '$route.query.contractId': {
      immediate: true,
      handler (newVal, oldVal) {
        console.log('Contract ID changed:', newVal)
        this.contractId = newVal
        this.fetchData()
      }
    },
    async isLiquidationButtonState (value) {
      this.settingIsLiquidation(value)
      await this.editProformaLiquidation()
    },

    async '$route' (to, from) {
      await this.firstThingToDo()
      this.reRender += 1
    }
  },

  beforeMount () {
    this.checkPermissions()
    this.firstThingToDo()
  }

})
