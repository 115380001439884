import { Roles } from '../enums/Roles'
import { Sections } from '../enums/Sections'

export interface Permission {
  view?: boolean,
  create?: boolean,
  edit?: boolean,
  sign?: boolean,
  lock?: boolean
}

export const defaultPermissions: Permission = {
  view: true,
  create: false,
  edit: false,
  sign: false,
  lock: false
}

export type PermissionByRol = {
  [key in Roles]?: Permission
}

export type PermissionBySection = {
  [key in Sections]?: PermissionByRol
}

export const GlobalPermissions: PermissionBySection = {
  [Sections.HOME]: {
    [Roles.ASSISTAN_FOREMAN]: { view: true, create: false, edit: false, sign: false, lock: false },
    [Roles.CONSTRUCTION_MANAGER]: { view: true, create: false, edit: false, sign: false, lock: false },
    [Roles.GROUP_MANAGER]: { view: true, create: false, edit: false, sign: false, lock: false },
    [Roles.PRODUCTION_DIRECTOR]: { view: true, create: false, edit: false, sign: false, lock: false },
    [Roles.ADMINISTRATION]: { view: true, create: false, edit: false, sign: false, lock: false },
    [Roles.CFO]: { view: true, create: false, edit: false, sign: false, lock: false },
    [Roles.CONSULTANT]: { view: true, create: false, edit: false, sign: false, lock: false },
    [Roles.ADMIN]: { view: true, create: false, edit: false, sign: false, lock: false },
    [Roles.SHOPPING]: { view: true, create: false, edit: false, sign: false, lock: false },
    [Roles.PROVIDER]: { view: true, create: false, edit: false, sign: false, lock: false }
  },
  [Sections.PROVIDERS]: {
    [Roles.ASSISTAN_FOREMAN]: { view: false, create: false, edit: false, sign: false, lock: false },
    [Roles.CONSTRUCTION_MANAGER]: { view: false, create: false, edit: false, sign: false, lock: false },
    [Roles.GROUP_MANAGER]: { view: true, create: true, edit: false, sign: false, lock: false },
    [Roles.PRODUCTION_DIRECTOR]: { view: true, create: true, edit: false, sign: false, lock: true },
    [Roles.ADMINISTRATION]: { view: true, create: false, edit: false, sign: false, lock: false },
    [Roles.CFO]: { view: true, create: false, edit: false, sign: false, lock: true },
    [Roles.CONSULTANT]: { view: true, create: false, edit: false, sign: false, lock: false },
    [Roles.ADMIN]: { view: true, create: true, edit: true, sign: false, lock: false },
    [Roles.SHOPPING]: { view: true, create: true, edit: true, sign: false, lock: true },
    [Roles.PROVIDER]: { view: false, create: false, edit: false, sign: false, lock: false }
  },
  [Sections.USERS]: {
    [Roles.ASSISTAN_FOREMAN]: { view: false, create: false, edit: true, sign: false, lock: false },
    [Roles.CONSTRUCTION_MANAGER]: { view: false, create: false, edit: true, sign: false, lock: false },
    [Roles.GROUP_MANAGER]: { view: false, create: false, edit: true, sign: false, lock: false },
    [Roles.PRODUCTION_DIRECTOR]: { view: true, create: false, edit: true, sign: false, lock: false },
    [Roles.ADMINISTRATION]: { view: false, create: false, edit: true, sign: false, lock: false },
    [Roles.CFO]: { view: false, create: false, edit: true, sign: false, lock: false },
    [Roles.CONSULTANT]: { view: true, create: false, edit: true, sign: false, lock: false },
    [Roles.ADMIN]: { view: true, create: true, edit: true, sign: false, lock: false },
    [Roles.SHOPPING]: { view: true, create: false, edit: true, sign: false, lock: false },
    [Roles.PROVIDER]: { view: false, create: false, edit: true, sign: false, lock: false }
  },
  [Sections.STATS]: {
    [Roles.ASSISTAN_FOREMAN]: { view: false, create: false, edit: false, sign: false, lock: false },
    [Roles.CONSTRUCTION_MANAGER]: { view: false, create: false, edit: false, sign: false, lock: false },
    [Roles.GROUP_MANAGER]: { view: false, create: false, edit: false, sign: false, lock: false },
    [Roles.PRODUCTION_DIRECTOR]: { view: true, create: false, edit: false, sign: false, lock: false },
    [Roles.ADMINISTRATION]: { view: false, create: false, edit: false, sign: false, lock: false },
    [Roles.CFO]: { view: true, create: false, edit: false, sign: false, lock: false },
    [Roles.CONSULTANT]: { view: true, create: false, edit: false, sign: false, lock: false },
    [Roles.ADMIN]: { view: true, create: false, edit: false, sign: false, lock: false },
    [Roles.SHOPPING]: { view: true, create: false, edit: false, sign: false, lock: false },
    [Roles.PROVIDER]: { view: false, create: false, edit: false, sign: false, lock: false }
  },
  [Sections.NOTIFICATIONS]: {
    [Roles.ASSISTAN_FOREMAN]: { view: false, create: false, edit: false, sign: false, lock: false },
    [Roles.CONSTRUCTION_MANAGER]: { view: true, create: false, edit: false, sign: false, lock: false },
    [Roles.GROUP_MANAGER]: { view: true, create: false, edit: false, sign: false, lock: false },
    [Roles.PRODUCTION_DIRECTOR]: { view: true, create: false, edit: false, sign: false, lock: false },
    [Roles.ADMINISTRATION]: { view: true, create: false, edit: false, sign: false, lock: false },
    [Roles.CFO]: { view: false, create: false, edit: false, sign: false, lock: false },
    [Roles.CONSULTANT]: { view: false, create: false, edit: false, sign: false, lock: false },
    [Roles.ADMIN]: { view: true, create: false, edit: false, sign: false, lock: false },
    [Roles.SHOPPING]: { view: true, create: false, edit: false, sign: false, lock: false },
    [Roles.PROVIDER]: { view: false, create: false, edit: false, sign: false, lock: false }
  },
  [Sections.CONSTRUCTIONS]: {
    [Roles.ASSISTAN_FOREMAN]: { view: true, create: false, edit: false, sign: false, lock: false },
    [Roles.CONSTRUCTION_MANAGER]: { view: true, create: false, edit: false, sign: false, lock: false },
    [Roles.GROUP_MANAGER]: { view: true, create: true, edit: true, sign: false, lock: false },
    [Roles.PRODUCTION_DIRECTOR]: { view: true, create: true, edit: true, sign: false, lock: false },
    [Roles.ADMINISTRATION]: { view: true, create: false, edit: false, sign: false, lock: false },
    [Roles.CFO]: { view: true, create: false, edit: false, sign: false, lock: false },
    [Roles.CONSULTANT]: { view: true, create: false, edit: false, sign: false, lock: false },
    [Roles.ADMIN]: { view: true, create: true, edit: true, sign: false, lock: false },
    [Roles.SHOPPING]: { view: true, create: true, edit: true, sign: false, lock: false },
    [Roles.PROVIDER]: { view: false, create: false, edit: false, sign: false, lock: false }
  },
  [Sections.CONTRACTS]: {
    [Roles.ASSISTAN_FOREMAN]: { view: true, create: true, edit: true, sign: false, lock: false },
    [Roles.CONSTRUCTION_MANAGER]: { view: true, create: true, edit: true, sign: true, lock: false },
    [Roles.GROUP_MANAGER]: { view: true, create: true, edit: true, sign: true, lock: false },
    [Roles.PRODUCTION_DIRECTOR]: { view: true, create: true, edit: true, sign: true, lock: false },
    [Roles.ADMINISTRATION]: { view: true, create: false, edit: false, sign: false, lock: false },
    [Roles.CFO]: { view: true, create: false, edit: false, sign: false, lock: false },
    [Roles.CONSULTANT]: { view: true, create: false, edit: false, sign: false, lock: false },
    [Roles.ADMIN]: { view: true, create: false, edit: false, sign: false, lock: false },
    [Roles.SHOPPING]: { view: true, create: true, edit: true, sign: false, lock: true },
    [Roles.PROVIDER]: { view: false, create: false, edit: false, sign: false, lock: false }
  },
  [Sections.PROFORMA]: {
    [Roles.ASSISTAN_FOREMAN]: { view: true, create: true, edit: true, sign: false, lock: false },
    [Roles.CONSTRUCTION_MANAGER]: { view: true, create: true, edit: true, sign: true, lock: false },
    [Roles.GROUP_MANAGER]: { view: true, create: false, edit: true, sign: true, lock: false },
    [Roles.PRODUCTION_DIRECTOR]: { view: true, create: false, edit: false, sign: true, lock: false },
    [Roles.ADMINISTRATION]: { view: true, create: false, edit: true, sign: false, lock: false },
    [Roles.CFO]: { view: true, create: false, edit: false, sign: false, lock: false },
    [Roles.CONSULTANT]: { view: true, create: false, edit: false, sign: false, lock: false },
    [Roles.ADMIN]: { view: true, create: false, edit: false, sign: false, lock: false },
    [Roles.SHOPPING]: { view: true, create: false, edit: true, sign: false, lock: false },
    [Roles.PROVIDER]: { view: false, create: false, edit: false, sign: false, lock: false }
  },
  [Sections.EXTENSION]: {
    [Roles.ASSISTAN_FOREMAN]: { view: true, create: true, edit: true, sign: false, lock: false },
    [Roles.CONSTRUCTION_MANAGER]: { view: true, create: true, edit: true, sign: true, lock: false },
    [Roles.GROUP_MANAGER]: { view: true, create: false, edit: true, sign: true, lock: false },
    [Roles.PRODUCTION_DIRECTOR]: { view: true, create: false, edit: false, sign: true, lock: false },
    [Roles.ADMINISTRATION]: { view: true, create: false, edit: false, sign: false, lock: false },
    [Roles.CFO]: { view: true, create: false, edit: false, sign: false, lock: false },
    [Roles.CONSULTANT]: { view: true, create: false, edit: false, sign: false, lock: false },
    [Roles.ADMIN]: { view: true, create: false, edit: false, sign: false, lock: false },
    [Roles.SHOPPING]: { view: true, create: true, edit: true, sign: false, lock: true },
    [Roles.PROVIDER]: { view: false, create: false, edit: false, sign: false, lock: false }
  },
  [Sections.BILLS]: {
    [Roles.ASSISTAN_FOREMAN]: { view: true, create: false, edit: false, sign: false, lock: false },
    [Roles.CONSTRUCTION_MANAGER]: { view: true, create: false, edit: false, sign: true, lock: false },
    [Roles.GROUP_MANAGER]: { view: true, create: false, edit: false, sign: true, lock: false },
    [Roles.PRODUCTION_DIRECTOR]: { view: true, create: false, edit: false, sign: true, lock: false },
    [Roles.ADMINISTRATION]: { view: true, create: false, edit: false, sign: false, lock: false },
    [Roles.CFO]: { view: true, create: false, edit: false, sign: false, lock: false },
    [Roles.CONSULTANT]: { view: true, create: false, edit: false, sign: false, lock: false },
    [Roles.ADMIN]: { view: true, create: false, edit: false, sign: false, lock: false },
    [Roles.SHOPPING]: { view: true, create: false, edit: false, sign: false, lock: true },
    [Roles.PROVIDER]: { view: true, create: false, edit: false, sign: false, lock: false }
  }
}
