












































































import Vue from 'vue'
import PendingToSignByUser from '@/components/Modals/PendingsToSignByUser.vue'
import { Sections } from '@/shared/enums/Sections'
import { PermissionsMixin } from '@/mixins/PermissionsMixin'
import { PermissionByRol } from '@/shared/types/Permission'

export default Vue.extend({
  name: 'NavBar',
  mixins: [
    PermissionsMixin
  ],
  components: {
    PendingToSignByUser
  },

  data () {
    return {
      notificationPermissions: Object as () => PermissionByRol,
      idUser: 'a' as string
    }
  },

  computed: {
    currentUser () {
      return this.$cookies.get('user')
    }
  },

  methods: {
    goTo () {
      this.$router.push(`/users/myprofile/${this.idUser}`)
    },

    checkPermissions () {
      this.notificationPermissions = this.getPermissions(Sections.NOTIFICATIONS)
    }
  },

  beforeMount () {
    this.checkPermissions()
    this.idUser = this.currentUser.id
  }
})
