const state = {
  newProformaObject: {},
  isLiquidationProforma: false
}

const actions = {
  async setNewProformaObject ({ commit }: any, payload:any) {
    await commit('SET_NEW_PROFORMA_OBJECT', payload)
  },

  async setIsLiquidationProforma ({ commit }: any, boolean: boolean) {
    await commit('SET_IS_LIQUIDATION_PROFORMA', boolean)
  },

  async resetState ({ commit }: any) {
    await commit('SET_RESET_STATE')
  }
}

const mutations = {
  SET_NEW_PROFORMA_OBJECT (state: any, payload: any) {
    state.newProformaObject = payload
  },

  SET_IS_LIQUIDATION_PROFORMA (state: any, boolean:boolean) {
    state.isLiquidationProforma = boolean
  },

  SET_RESET_STATE (state: any) {
    state.newProformaObject = {}
    state.isLiquidationProforma = false
  }
}

export const proforma = {
  namespaced: true,
  actions,
  mutations,
  state
}
