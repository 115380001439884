





































































































































































































import Vue from 'vue'
import { createNamespacedHelpers } from 'vuex'

import moment from 'moment'

import { editConstructionById, getAllConstruction } from '@/services/Construction'
import DeleteModal from '@/components/Modals/DeleteModal.vue'
import BanSupplier from '@/components/Modals/BanSupplier.vue'
import FolderIcon from '@/components/Constructions/FolderIcon.vue'
import NoResultMessage from '@/components/utils/NoResultMessage.vue'
import Pagination from '@/components/table/Pagination.vue'
import UnlockSupplier from '@/components/Modals/UnlockSupplier.vue'

import { PaginationObjectList } from '@/store/types/index'
import { PermissionByRol } from '@/shared/types/Permission'

const constructionModule = createNamespacedHelpers('constructions')

export default Vue.extend({
  name: 'DisplayTable',
  components: {
    BanSupplier,
    DeleteModal,
    FolderIcon,
    NoResultMessage,
    Pagination,
    UnlockSupplier
  },
  props: {
    columns: {
      type: Array,
      required: true
    },
    completedObject: {
      type: Object as () => PaginationObjectList,
      required: true
    },
    constructionTypeList: {
      type: String,
      required: false
    },
    section: {
      type: String,
      required: true
    },
    permissions: {
      type: Object as () => PermissionByRol,
      required: true
    }
  },
  data () {
    return {
      isDataLoaded: false as boolean,
      isConstructionSection: false as boolean,
      isShowNoResultMessage: false as boolean,
      isSuppliersSection: false as boolean,
      isUsersSections: false as boolean,
      objectGoTo: {
        users: '/users/profile/',
        constructions: '/constructions/edit/',
        suppliers: '/suppliers/edit/'
      }
    }
  },
  computed: {
    ...constructionModule.mapState([
      'constructionType'
    ])
  },

  methods: {
    computedContentToShow () {
      if (this.completedObject && this.completedObject.content) {
        return this.completedObject.content
      } else return []
    },

    computedShowPagination () {
      if (this.completedObject && this.completedObject.content) {
        return this.completedObject.content.length >= 1
      } else return false
    },

    async editConstrutionType (event: any, element: any) {
      try {
        const objectToEdit = { ...element }

        objectToEdit.constructionType = event
        const { status } = await editConstructionById(element.id, objectToEdit)
        if (status === 200) {
          this.$showSuccess('Obra actualizada correctamente')
          const { status: statusAllConstructions } = await getAllConstruction(this.completedObject.currentPage)
          if (statusAllConstructions !== 200) {
            this.$showError('Error actualizando lista de obras', 'Por favor, recarga la página manualmente')
          }
        } else {
          throw new Error()
        }
      } catch (error) {
        this.$showError('Error durante la actualización')
        console.dir(error)
      }
    },

    formattedEntryDate (date: string) {
      if (date) {
        return moment(date).format('DD/MM/YYYY')
      } else return ''
    },

    formattedState (state: boolean) {
      return state === true ? 'Bloqueado' : 'Activo'
    },

    goto (id: number) {
      this.$router.push(`/contracts-list/${id}`)
    },

    settingSection () {
      switch (this.section) {
        case 'constructions':
          this.isConstructionSection = true
          break
        case 'suppliers':
          this.isSuppliersSection = true
          break
        case 'users':
          this.isUsersSections = true
          break
        default:
          break
      }
    },

    contentCheck () {
      if (
        this.completedObject &&
        this.completedObject.content &&
        this.completedObject.content.length !== 0
      ) {
        this.isShowNoResultMessage = false
      } else {
        this.isShowNoResultMessage = true
      }
    },

    showingGroupName (rolObject: any) {
      if (rolObject && rolObject.name) {
        return rolObject.name
      } else return ''
    },

    thereIsConstructionType (field: string, element: any) {
      if (field === 'constructionKind') {
        if (element && element.constructionType && element.constructionType.name) {
          return true
        }
      } else return false
    }
  },
  beforeMount () {
    this.contentCheck()
    this.settingSection()
    this.isDataLoaded = true
  }
})
