






























import Vue from 'vue'

import { createNewPassword } from '@/services/Auth'

export default Vue.extend({
  name: 'CreateNewPassword',
  data () {
    return {
      confirmPassword: '' as string,
      isDataLoaded: false as boolean,
      password: '' as string,
      rules: {
        required: (value: any) => (value && value !== undefined) || 'Requerido'
      },
      showMessage: false as boolean,
      token: '' as string,
      valid: true as boolean
    }
  },

  methods: {

    hiddingMessage () {
      setTimeout(() => {
        this.showMessage = false
      }, 3000)
    },

    async sendNewPassword () {
      if ((this.confirmPassword === this.password)) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        if (this.$refs.form.validate()) {
          try {
            await createNewPassword(this.token, { password: this.password })
            this.$router.push({ name: 'Landing' })
          } catch (error) {
            console.error(error)
          }
        }
      } else {
        this.showMessage = true
        this.hiddingMessage()
      }
    }
  },

  beforeMount () {
    this.token = this.$router.currentRoute.params.token
    this.isDataLoaded = true
  }
})
