import Vue from 'vue'
import App from './App.vue'
import VueI18n from 'vue-i18n'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import Vuetify from 'vuetify'
import moment from 'vue-moment'
import './styles/main.scss'
import './registerServiceWorker'
import VueSimpleAlert from 'vue-simple-alert'
import Snotify from 'vue-snotify'
import 'vue-snotify/styles/material.css'
import './styles/_snotify.scss'
import Notifications from './shared/Notifications'
import VueCookies from 'vue-cookies-reactive'

Vue.use(VueCookies)
Vue.use(VueI18n)
Vue.use(moment)
Vue.use(VueSimpleAlert)
Vue.use(Vuetify)
Vue.use(Snotify)
Vue.use(Notifications)

Vue.config.productionTip = false

function loadLocaleMessages () {
  const locales = require.context('./locales', true, /[A-Za-z0-9-_,\s]+\.json$/i)
  // eslint-disable-next-line
  const messages:any = {}
  locales.keys().forEach((key) => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i)
    if (matched && matched.length > 1) {
      const locale:string = matched[1]
      messages[locale] = locales(key)
    }
  })
  return messages
}

const i18n = new VueI18n({
  locale: 'es', // set locale
  fallbackLocale: 'es', // set fallback locale
  messages: loadLocaleMessages() // set locale messages
})

new Vue({
  el: '#app',
  i18n,
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
