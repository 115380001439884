

























































































































































































































































































































































































import Vue from 'vue'
import { createNamespacedHelpers } from 'vuex'

import BackArrowComp from '@/components/utils/BackArrowComp.vue'
import ContractSelectingProvider2 from '@/components/Contracts/ContractSelectingProvider2.vue'
import ContractSelectedSupplierModule from '@/components/Contracts/selectionSupplier/ContractSelectedSupplierModule.vue'
import LoadingSpinner from '@/components/utils/Loading.vue'

import { getSupplierSearchByFields } from '@/services/Supplier'
import { createContract, importExcel } from '@/services/Contracts'

const contractModule = createNamespacedHelpers('contracts')
const globalModule = createNamespacedHelpers('global')

export default Vue.extend({
  name: 'NewContract',

  components: {
    BackArrowComp,
    ContractSelectingProvider2,
    ContractSelectedSupplierModule,
    LoadingSpinner
  },

  data () {
    return {
      clausules: '' as string,
      notes: '' as string,
      contractsType: [
        { type: 'Alquiler', id: 1 },
        { type: 'Suministro', id: 2 },
        { type: 'Renting', id: 3 },
        { type: 'Mano de obra', id: 4 },
        { type: 'Subcontrata', id: 5 }
      ],

      fileArray: [],

      isLoading: false as boolean,

      isSelectRetentionDisabled: true as boolean,
      isSelectIvaDisabled: true as boolean,

      isShowRetentionMessage: false as boolean,
      isShowRetentionNo: false as boolean,
      isShowRetentionYes: false as boolean,

      isShowSupplierMessage: false as boolean,

      isShowIvaMessage: false as boolean,
      isShowIvaNo: false as boolean,
      isShowIvaYes: false as boolean,
      ivaType2: [
        { type: '4% Reducido', percent: 4 },
        { type: '10% Especial', percent: 10 },
        { type: '21% General', percent: 21 }
      ],

      formData: new FormData(),
      retentionsType: [5, 10, 20, 20, 40, 50, 60, 70, 80, 90, 100],
      rules: {
        required: (value: any) => (value && value !== undefined) || 'Requerido'
      },
      showModuleSelectSupplier: true,
      subcategoriesList: {},
      valid: false,
      codigoContrato: '',
      selectedSupplierObject: null
    }
  },

  computed: {
    ...contractModule.mapState([
      'newContractSelection',
      'idCurrentConstruction',
      'idSelectedSupplier',
      'rec',
      'recProvider',
      'selectedSupplierObject'
    ]),
    selectedSupplierComputed () {
      console.log(55, this.selectedSupplierObject)
      return this.selectedSupplierObject
    },
    disabled () {
      return this.showModuleSelectSupplier === true
    }
  },

  methods: {
    ...contractModule.mapActions([
      'resetRec',
      'resetRecProvider',
      'resetIdSelectedProvider',
      'resetSupplierSearched ',
      'setSelectedSupplierObject',
      'resetSelectedSupplierObject',
      'resetNewContractSelection',
      'settingSupplierSearched',
      'setNewContractSelection'
    ]),

    checkRecProviderIsFull ():boolean {
      if (
        (this.recProvider.name && this.recProvider.name !== null) ||
        (this.recProvider.category && this.recProvider.category !== null)
      ) {
        return true
      } else {
        return false
      }
    },

    selectSupplier (supplier) {
      console.log(1111)
      this.$emit('selected', supplier)
    },

    setSelectedSupplier (supplier) {
      console.log(222)
      this.selectedSupplierObject = supplier
      console.log(999, this.selectedSupplierObject)
      this.showModuleSelectSupplier = false
    },

    async checkingIfSearchSupplier () {
      if (this.checkRecProviderIsFull()) {
        try {
          this.isLoading = true
          const response = await this.searchSupplier()
          await this.settingSupplierSearched(response.content)
          await this.resetIdSelectedProvider()
          this.$router.push({ name: 'ContractSuppliersList' })
        } catch (error) {
          console.error(error)
        } finally {
          this.isLoading = false
        }
      } else {
        this.$fire({
          title: 'Es necesario seleccionar un proveedor',
          type: 'warning',
          confirmButtonColor: '#F7A549'
        })
        this.isShowSupplierMessage = true
      }
    },

    clearSelectedSupplier () {
      this.selectedSupplierObject = null
      this.showModuleSelectSupplier = true
      this.$emit('cleared')
    },

    checkWhereComeFrom (from: any) {
      if (from.path.includes('/contracts-list')) {
        this.resetNewcontractObjects()
      } else {
        this.checkIfThereIsSelecction()
      }
    },

    async creatingContract () {
      this.isLoading = true
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      if (this.$refs.form.validate()) {
        console.log(6262626, this.selectedSupplierObject.id)
        console.log(6262626, this.recProvider)
        if (
          // this.recProvider.name.id !== undefined &&
          this.rec.retention !== undefined &&
          this.rec.IVA !== undefined
        ) {
          try {
            const userId = this.$cookies.get('user').id
            const payload = {
              ...this.rec,
              provider: this.selectedSupplierObject.id,
              construction: Number(this.idCurrentConstruction),
              codigoContrato: this.codigoContrato,
              createdBy: userId
            }
            const { status, data } = await createContract(payload)

            if (status === 201) {
              this.$showSuccess('Contrato creado correctamente')
              console.log(888811)
              await this.importingFile(data.message.id)
            }

            this.$router.push(`/contracts-list/${this.idCurrentConstruction}`)
          } catch (error) {
            this.$showError('Error al crear contrato')
            console.error(error)
          }
        } else {
          this.showAlertToCheckRequiredFields()
          this.whatIsMissing()
        }
      } else {
        this.showAlertToCheckRequiredFields()
      }
      this.isLoading = false
    },

    showAlertToCheckRequiredFields () {
      this.$fire({
        title: 'Comprueba los campos obligatorios',
        type: 'warning',
        confirmButtonColor: '#F7A549'
      })
    },

    async checkIfThereIsSelecction () {
      if (this.newContractSelection.iva) {
        this.isSelectIvaDisabled = false
      }
      if (this.newContractSelection.retention) {
        this.isSelectRetentionDisabled = false
      }
    },

    async importingFile (idContract: number) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      if (document.getElementById('file').value) {
        console.log(11111)
        try {
          console.log(1222221)
          for (let i = 0; i < this.fileArray.length; i++) {
            this.formData.append('files', this.fileArray[i])
          }
          const { status } = await importExcel(idContract, this.formData)
          if (status !== 201) {
            throw new Error()
          }
        } catch (error) {
          console.error(787877, error)
          this.$fire({
            title: 'Error subiendo excell',
            text: 'Por favor corrige el excell y vuelve a intentarlo',
            type: 'error',
            confirmButtonColor: '#F7A549'
          })
        } finally {
          this.formData = new FormData()
          this.fileArray = []
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          document.getElementById('file').value = null
        }
      }
    },

    inputFilehandleChange (e: any) {
      if (e.target.files[0]) {
        this.isLoading = true
        this.fileArray.push(e.target.files[0])
        this.isLoading = false
      }
    },

    resetNewcontractObjects () {
      this.resetRec()
      this.resetRecProvider()
      this.resetIdSelectedProvider()
      this.resetSelectedSupplierObject()
      this.resetNewContractSelection()
      this.showModuleSelectSupplier = true
    },

    async searchSupplier () {
      let name = null
      if (typeof this.recProvider.name === 'string') {
        name = this.recProvider.name
      } else if (typeof this.recProvider.name === 'object') {
        name = this.recProvider.name.name
      } else {
        name = ''
      }
      const payload = { name: name, category: this.recProvider.category, subcategory: this.recProvider.subcategory }
      const response = await getSupplierSearchByFields(payload, -1)
      return response
    },

    selectingRetentions (isRetention: string) {
      this.isShowRetentionMessage = false
      if (isRetention === 'yes') {
        this.newContractSelection.retention = true
        this.isShowRetentionNo = false
        this.isShowRetentionYes = true
        this.isSelectRetentionDisabled = false
      }
      if (isRetention === 'no') {
        this.newContractSelection.retention = false
        this.isSelectRetentionDisabled = true
        this.isShowRetentionYes = false
        this.isShowRetentionNo = true
        this.rec.retention = 0
      }
    },

    selectingIva (isIva: string) {
      this.isShowIvaMessage = false
      if (isIva === 'yes') {
        this.newContractSelection.iva = true
        this.isShowIvaNo = false
        this.isShowIvaYes = true
        this.isSelectIvaDisabled = false
      }
      if (isIva === 'no') {
        this.newContractSelection.iva = false
        this.isShowIvaYes = false
        this.isShowIvaNo = true
        this.rec.IVA = 0
        this.isSelectIvaDisabled = true
      }
    },

    checkSelectedSupplier () {
      if (this.idSelectedSupplier === 0) {
        this.showModuleSelectSupplier = true
      } else {
        this.showModuleSelectSupplier = false
        this.isShowSupplierMessage = false
      }
    },

    whatIsMissing () {
      if (this.rec.retention === undefined) {
        this.isShowRetentionMessage = true
      }

      if (!this.recProvider.name.id) {
        this.isShowSupplierMessage = true
      }

      if (this.rec.IVA === undefined) {
        this.isShowIvaMessage = true
      }
    }

  },

  beforeMount () {
    this.checkSelectedSupplier()
  },

  beforeRouteEnter (to, from, next) {
    next((vm: any) => {
      vm.checkWhereComeFrom(from)
    })
  }

})
