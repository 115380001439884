

























import Vue from 'vue'
import { createNamespacedHelpers } from 'vuex'

import BackArrowComp from '@/components/utils/BackArrowComp.vue'
import NewUserForm from '@/components/User/NewUserForm.vue'

const globalModule = createNamespacedHelpers('global')

export default Vue.extend({
  name: 'NewUser',
  components: {
    BackArrowComp,
    NewUserForm
  },
  methods: {
    ...globalModule.mapActions(
      ['setCurrentPath']
    )
  },
  beforeMount () {
    this.setCurrentPath()
  }
})
