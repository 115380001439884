































































































import Vue from 'vue'
import { createNamespacedHelpers } from 'vuex'

import moment from 'moment'

import { getSupplierById } from '@/services/Supplier'

const contractModule = createNamespacedHelpers('contracts')

export default Vue.extend({
  name: 'ContractSuppliersTable',

  props: {
    supplierSearched: {
      type: Array,
      required: true
    }
  },

  data () {
    return {
      columns: [
        'name',
        'id',
        'entryDate',
        'endDate',
        'state'
      ]
    }
  },

  methods: {

    ...contractModule.mapActions([
      'setIdSelectedSupplier',
      'setSelectedSupplierObject'
    ]),

    async selectingSupplier (idSupplier: number) {
      this.setIdSelectedSupplier(idSupplier)
      const response = await getSupplierById(idSupplier)
      this.setSelectedSupplierObject(response)
      this.$router.push({ name: 'NewContract' })
    },

    formattedEntryDate (date: string) {
      if (date) {
        return moment(date).format('DD/MM/YYYY')
      } else return ''
    },

    formattedState (state: boolean) {
      return state === true ? 'Bloqueado' : 'Activo'
    },

    goto (id: number) {
      this.$router.push(`/contracts-list/${id}`)
    }

  }

})
