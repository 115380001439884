
















































import Vue from 'vue'
import { createNamespacedHelpers } from 'vuex'

import { getFinishedContractByConstructionId, getPendingContractByConstructionId } from '@/services/Construction'

import BackArrowComp from '@/components/utils/BackArrowComp.vue'
import ContractLisTable from '@/components/Contracts/table/ContractsListTable.vue'
import LoadingSpinner from '@/components/utils/Loading.vue'
import NoResultMessage from '@/components/utils/NoResultMessage.vue'

const contractModule = createNamespacedHelpers('contracts')

export default Vue.extend({
  name: 'ContractsListDashboard',

  components: {
    BackArrowComp,
    ContractLisTable,
    LoadingSpinner,
    NoResultMessage
  },

  props: {
    type: {
      type: String,
      required: true
    },
    permissions: {
      type: Object,
      required: true
    }
  },

  data () {
    return {
      backText: '' as string,
      contractsList: [],
      isShowContractList: true as boolean,
      isLoading: false as boolean
    }
  },

  computed: {
    ...contractModule.mapState([
      'idCurrentConstruction'
    ])
  },

  methods: {

    async callingPendingContracts () {
      const response = await getPendingContractByConstructionId(this.idCurrentConstruction)
      try {
        this.isLoading = true
        if (response.length >= 1) {
          this.contractsList = response
          this.isShowContractList = true
        } else {
          this.contractsList = []
          this.isShowContractList = false
        }
      } catch (error) {
        this.isLoading = false
        console.error(error)
      } finally {
        this.isLoading = false
      }
    },

    async callingFinishedContracts () {
      try {
        this.isLoading = true
        const response = await getFinishedContractByConstructionId(this.idCurrentConstruction)
        if (response.length >= 1) {
          this.contractsList = response
          this.isShowContractList = true
        } else {
          this.isShowContractList = false
          this.contractsList = []
        }
      } catch (error) {
        this.isLoading = false
        console.error(error)
      } finally {
        this.isLoading = false
      }
    },

    async firstThingToDo () {
      await this.settingTable()
    },

    async settingTable () {
      if (this.type === 'pending') {
        this.backText = 'contracts pending'
        await this.callingPendingContracts()
      }
      if (this.type === 'signed') {
        this.backText = 'contracts signed'
        await this.callingFinishedContracts()
      }
    },

    reloadContracts () {
      this.settingTable()
    }

  },

  beforeMount () {
    this.firstThingToDo()
  }
})
