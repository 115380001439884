



























import Vue from 'vue'
import { createNamespacedHelpers } from 'vuex'

import { getCategoryById, getSubcategoryById } from '@/services/Category'

import BackArrowComp from '@/components/utils/BackArrowComp.vue'
import ContractSelectingSupplierDashboard from '@/components/Contracts/selectionSupplier/ContractSelectingSupplierDashboard.vue'
import LoadingSpinner from '@/components/utils/Loading.vue'

const contractsModule = createNamespacedHelpers('contracts')

export default Vue.extend({
  name: 'ContractSuppliersList',

  components: {
    BackArrowComp,
    ContractSelectingSupplierDashboard,
    LoadingSpinner
  },

  data () {
    return {
      categoryText: '' as string,
      isDataLoaded: false as boolean,
      isLoading: false as boolean,
      subcategoryText: '' as string
    }
  },

  computed: {
    ...contractsModule.mapState([
      'recProvider'
    ])
  },

  methods: {

    async gettingCategory () {
      if (this.recProvider && this.recProvider.category && this.recProvider.category !== 0) {
        const response = await getCategoryById(this.recProvider.category)
        this.categoryText = response.name
      } else {
        this.categoryText = ''
      }
    },

    async gettingSubcategory () {
      if (this.recProvider && this.recProvider.subcategory && this.recProvider.subcategory !== 0) {
        const response = await getSubcategoryById(this.recProvider.subcategory)
        this.subcategoryText = response.name
      } else {
        this.subcategoryText = ''
      }
    }
  },

  async beforeMount () {
    this.isLoading = true
    try {
      await this.gettingCategory()
      await this.gettingSubcategory()
    } catch (error) {
      console.error(error)
    } finally {
      this.isLoading = false
      this.isDataLoaded = true
    }
  }

})
