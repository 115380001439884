





































































































































import Vue from 'vue'
import { createNamespacedHelpers } from 'vuex'

import moment from 'moment'

import { getConstructionSubfoldersByDefaultFolderId } from '@/services/Construction'
import { downloadDocument, returnFile } from '@/services/Documents'

import DeleteModal from '@/components/Modals/DeleteModal.vue'
import LoadingSpinner from '@/components/utils/Loading.vue'
import NoResultMessage from '@/components/utils/NoResultMessage.vue'
import AddSubfolder from '@/components/Modals/AddSubfolder.vue'

const globalModule = createNamespacedHelpers('global')
const constructionsModule = createNamespacedHelpers('constructions')

export default Vue.extend({
  name: 'DocumentationTable',

  components: {
    DeleteModal,
    LoadingSpinner,
    NoResultMessage,
    AddSubfolder
  },

  props: {
    headers: {
      type: Array,
      required: true
    },

    idSubfolder: {
      type: Number,
      required: false
    },

    isSecondary: {
      type: Boolean,
      required: false
    }

  },

  data () {
    return {
      isDataLoaded: false as boolean,
      isLoading: false as boolean,
      isObjectFilesEmpty: false as boolean,
      today: '' as string,
      reKey: 0 as number
    }
  },

  computed: {
    ...constructionsModule.mapState([
      'idCurrentSubfolder',
      'objectFilesToShow'
    ]),

    ...globalModule.mapState([
      'currentDate'
    ])
  },

  methods: {

    ...constructionsModule.mapActions([
      'setObjectFilesToShow'
    ]),

    checkObJectFilesToShowIsEmpty () {
      if (this.objectFilesToShow.length === 0) {
        this.isObjectFilesEmpty = true
      }
    },

    async clickToDownloadFIle (idFile: number, nameFile: string) {
      try {
        this.isLoading = true
        const response = await returnFile(idFile)
        let disposition = response.request.getResponseHeader('content-type')
        if (disposition === 'application/octet-stream') {
          disposition = 'application/pdf'
        }
        const blobObject = new Blob([response.data], { type: disposition })
        const url = window.URL.createObjectURL(blobObject)
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', nameFile)
        document.body.appendChild(link)
        link.click()
        setTimeout(() => {
          document.body.removeChild(link)
        }, 3000)
      } catch (error) {
        console.dir(error)
      } finally {
        this.isLoading = false
      }
    },

    async firstThingsTodo () {
      try {
        this.isLoading = true
        await this.settingFileContent()
        this.checkObJectFilesToShowIsEmpty()
      } catch (error) {
        console.error(error)
      } finally {
        this.isDataLoaded = true
        this.isLoading = false
      }
    },

    formattingDate (dateToShow: string) {
      const date = moment(dateToShow).format('DD/MM/YYYY')
      return date
    },

    async reRender () {
      this.isLoading = true
      await this.settingFileContent()
      this.isLoading = false
    },

    async settingFileContent () {
      try {
        const response = await getConstructionSubfoldersByDefaultFolderId(this.idCurrentSubfolder.toString())
        await this.setObjectFilesToShow(response.fileChildren)
      } catch (error) {
        console.error(error)
      }
    }
  },

  async beforeMount () {
    this.firstThingsTodo()
  }

})
