const state = {
  extensionList: [],
  idToExtension: 0 as number
}

const actions = {

  async setExtensionList ({ commit }: any, payload:any):Promise<any> {
    commit('SET_EXTENSION_LIST', payload)
  },

  async setIdGoingToExtension ({ commit }: any, payload: number):Promise<any> {
    commit('SET_ID_GOING_EXTENSION', payload)
  },

  async resersetIdGoingToExtension ({ commit }: any):Promise<any> {
    commit('RESET_ID_GOING_EXTENSION')
  }

}

const mutations = {

  SET_EXTENSION_LIST (state:any, payload: any):void {
    state.extensionList = payload
  },

  SET_ID_GOING_EXTENSION (state:any, payload: number):void {
    state.idToExtension = payload
  },

  RESET_ID_GOING_EXTENSION (state:any):void {
    state.idToExtension = 0
  }

}

export const extensions = {
  namespaced: true,
  actions,
  state,
  mutations
}
