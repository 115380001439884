































import Vue from 'vue'
import SuppliersUploadInvoiceForm from '@/components/SuppliersSubdomain/SuppliersUploadInvoiceForm.vue'

export default Vue.extend({
  name: 'SuppliersUploadInvoice',
  components: {
    SuppliersUploadInvoiceForm
  }
})
