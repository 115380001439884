































































import Vue from 'vue'
import { createNamespacedHelpers } from 'vuex'
import { getProfileImage } from '@/services/Supplier'

const supplierModule = createNamespacedHelpers('suppliers')

export default Vue.extend({
  name: 'InputLogo',
  props: {
    name: {
      type: String,
      required: false
    },
    logo: {
      type: String,
      required: false
    }
  },

  computed: {
    ...supplierModule.mapState(
      [
        'photoProfile',
        'photoProfileUrl'
      ]
    )
  },

  methods: {

    ...supplierModule.mapActions([
      'setPhotoProfile',
      'resetPhotoProfile',
      'setPhotoProfileUrl',
      'resetPhotoProfileUrl'
    ]),

    showPreviewImage (event: { target: { files: string[] } }) {
      this.setPhotoProfile(event.target.files[0])
      this.setPhotoProfileUrl(event.target.files[0])
    },
    removeLogo () {
      this.resetPhotoProfile()
      this.resetPhotoProfileUrl()
    },

    async downloadProfileImage () {
      const image = await getProfileImage(this.$router.currentRoute.params.id)
      if (image) {
        this.setPhotoProfileUrl(image)
      }
    }
  },
  mounted () {
    this.removeLogo()
    this.downloadProfileImage()
  }
})
