export enum Roles {
CONSTRUCTION_MANAGER = 1,
ASSISTAN_FOREMAN = 2,
GROUP_MANAGER = 3,
PRODUCTION_DIRECTOR = 4,
ADMINISTRATION = 5,
CFO = 6,
CONSULTANT = 7,
ADMIN = 8,
PROVIDER = 9,
SHOPPING = 10
}

export enum RolNames {
  CONSTRUCTION_MANAGER = 'Jefe de obra',
  ASSISTAN_FOREMAN = 'Ayudante jefe de obra',
  GROUP_MANAGER = 'Jefe de grupo',
  PRODUCTION_DIRECTOR = 'Director de producción',
  ADMINISTRATION = 'Administración',
  CFO = 'Director financiero',
  CONSULTANT = 'Consultor',
  ADMIN = 'Administrador del sistema y usuarios',
  PROVIDER = 'Proveedor',
  SHOPPING = 'Compras'
}
