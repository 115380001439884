<style lang="scss" scoped>
  @import '@/styles/mixins.scss';

  .construction-managers-container {
    display: flex;
    flex-flow: row nowrap;
    margin: 5px 0 5px 0;
    width: 100%;
    padding: 0 5px 0 5px;

    .construction-managers_list {
      flex: 1 1 92%;
      background-color: white;
      box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
      border: 0.5px solid gray;
      border-radius: 5px;
      height: auto;
      overflow-y: scroll;
      overflow: hidden;
      padding: 2px 0;
    }

    .constructions-manager-items {
      list-style: none;
      display: grid;
      padding-left: 0;
      grid-template-columns: 1fr 1fr 1fr;
    }

    .construction-managers_add {
      flex: 1 1 6%;
      display: flex;
      flex-flow: row;
      justify-content: end;
      align-items: center;
      margin: 0.5rem;
    }
  }

</style>

<template lang="pug">
  .construction-managers-container
    .construction-managers_list
      ul.constructions-manager-items
        li(v-for="user in constructionManagers")
          ConstructionManagerItem(:constructionManager="user" :disabled="disabled")
    .construction-managers_add
      AddConstructionManagers(:disabled="disabled")
</template>

<script>
import Vue from 'vue'
import { createNamespacedHelpers } from 'vuex'
import AddConstructionManagers from './AddConstructionManagers'
import ConstructionManagerItem from './ConstructionManagerItem'

const usersModule = createNamespacedHelpers('users')

export default Vue.extend({
  name: 'ConstructionManagers',

  props: {
    disabled: {
      type: Boolean,
      required: false
    }
  },

  components: {
    AddConstructionManagers,
    ConstructionManagerItem
  },

  computed: {
    ...usersModule.mapState([
      'constructionManagers'
    ])
  },

  methods: {
    ...usersModule.mapActions([
      'resetConstructionManagers'
    ])
  },

  beforeDestroy () {
    this.resetConstructionManagers()
  }

})
</script>
