import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'

Vue.use(Vuetify)

export default new Vuetify({

  // please check variables.scss to see colors concord
  theme: {
    themes: {
      light: {
        primary: '#F7A549', // $corp-yellow
        secondary: '#545454', // $edit-btn
        tertiary: '#797979', // $bg-btn-login
        quaternary: '#B1B1B1', // $bg-btn-login
        white: '#FFFFFF', // $pure white
        black: '#000000', // $pure black
        dark_grey: '#3A3A3A' // $bg-lateral-menu
      }
    }
  }
})
