




























































































import Vue from 'vue'
import { createNamespacedHelpers } from 'vuex'

import { getAllCategoriesSupplier, getAllSubcategories, getSubcategoryByCategoryId } from '@/services/Category'
import { getSupplierSearchByFields, getSuppliersByPage } from '@/services/Supplier'

import LoadingSpinner from '@/components/utils/Loading.vue'
import NoResultMessageForList from '@/components/utils/NoResultMessageForList.vue'

const categoriesModule = createNamespacedHelpers('categories')
const contractModule = createNamespacedHelpers('contracts')

export default Vue.extend({
  name: 'ContractSelectingProvider2',

  components: {
    LoadingSpinner,
    NoResultMessageForList
  },

  props: {
    isShowSupplierMessage: {
      type: Boolean,
      required: true
    }
  },

  data () {
    return {
      searchCriteria: {
        name: '',
        CIF: '',
        category: null,
        subcategory: null
      },
      headers: [
        { text: 'Nombre', value: 'name' },
        { text: 'CIF', value: 'CIF' },
        { text: 'Categoría', value: 'relations[0].category.name' },
        { text: 'Subcategoría', value: 'relations[0].subcategory.name' }
      ],
      isFiltering: false,
      footerProps: {
        totalVisible: 3,
        'items-per-page-options': [4],
        'show-first-last-page': true
      },
      categories: [],
      subcategories: [],
      providers: [],
      isDataLoaded: false as boolean,
      totalPages: 3,
      currentPage: 1,
      isLoading: false as boolean,
      subcategoriesList: [],
      providerList: []
    }
  },

  computed: {
    ...categoriesModule.mapState([
      'categoriesGlobalList',
      'subcategoriesGlobalList'
    ]),

    ...contractModule.mapState([
      'recProvider'
    ])
  },

  methods: {

    ...categoriesModule.mapActions([
      'setSubategoriesGlobalList',
      'resetSubcategoriesGlobalList'
    ]),

    async callingSupplierList () {
      try {
        const response = await getSuppliersByPage(false, 0)
        this.providerList = response.content
      } catch (error) {
        console.error(error)
      }
    },

    onProviderSelected (selectedProvider) {
      console.log(selectedProvider)
      if (selectedProvider) {
        this.$emit('selected', selectedProvider)
        console.log('Proveedor seleccionado:', selectedProvider)
      }
    },
    async loadProviders () {
      this.isLoading = true
      try {
        const criteria = {
          ...this.searchCriteria,
          category: this.searchCriteria.category ? this.searchCriteria.category.id : null,
          subcategory: this.searchCriteria.subcategory ? this.searchCriteria.subcategory.id : null
        }
        const response = await getSupplierSearchByFields(criteria, this.currentPage - 1)
        console.log(response)
        this.providers = response.content
        this.totalPages = response.lastPage + 1
        this.serverItemsLength = this.totalPages * 4
      } catch (error) {
        console.error('Error cargando los proveedores:', error)
      } finally {
        this.isLoading = false
      }
    },

    async searchProviders () {
      this.currentPage = 1
      await this.loadProviders()
    },

    onPageChange (page) {
      this.currentPage = page
      this.loadProviders()
    },
    onRowClicked (item) {
      this.onProviderSelected(item)
      console.log(888, item)
    }

  },

  async beforeMount () {
    this.categories = await getAllCategoriesSupplier()
    this.subcategories = await getAllSubcategories()
    await this.loadProviders()
    this.isLoading = true
    await this.callingSupplierList()
    this.isLoading = false
    this.isDataLoaded = true
    this.currentPage = 1
  }
})
