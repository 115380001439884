<style lang="scss" scoped>
  @import '@/styles/mixins.scss';

  .new-user-form-wrapper {
    max-height: 100%;
    width: 70rem;
    form {
      @include form;
    }
  }
  .error-message {
  margin: 0;
  padding: 0;
  color: red;
}

</style>

<template lang="pug">
  .new-user-form-wrapper
    LoadingSpinner(v-show="isLoading")
    v-form(
            ref="form"
            v-model="valid"
            v-if="isDataLoaded"
          )

      v-row
        v-col(cols="4" )
          label.mb-4(for="name") {{$t('name')}}
          v-text-field.mt-2(  autocomplete="false"
                              background-color="white"
                              color="black" dense id="name"
                              outlined
                              :placeholder="$t('name')"
                              v-model="rec.name"
                            )

        v-col(cols="4" )
          label(for="surname") {{$t('surname')}}
          v-text-field.mt-2(
                              background-color="white"
                              color="black"
                              dense
                              id="cif"
                              outlined
                              :placeholder="$t('surname')"
                              :rules="[rules.required]"
                              v-model="rec.surnames"
                            )
        v-col(cols="4" )
          label(for="role") {{$t('rol')}}
          v-select.mt-2(  background-color="white"
                          color="black"
                          dense
                          id="role"
                          :items="rolesList"
                          item-color="orange"
                          item-text="name"
                          item-value="id"
                          outlined
                          :placeholder="$t('rol')"
                          :rules="[rules.required]"
                          v-model="rec.rolId"
                        )
                          //- return-object
      v-row
        v-col(cols="4")
          label(for="email") {{$t('email')}}
          v-text-field.mt-2(
                              autocomplete="false"
                              background-color="white"
                              color="black"
                              dense
                              id="email"
                              :placeholder="$t('email')"
                              outlined
                              :rules="[rules.required]"
                              v-model="rec.email"
                            )
        v-col(cols="4")
          label(for="phone") {{$t('phone')}}
          v-text-field.mt-2(
            type="number"
            autocomplete="false"
            background-color="white"
            color="black"
            dense
            id="phone"
            outlined
            :placeholder="$t('phone')"
            :rules="[rules.required]"
            v-model="rec.phone"
          )
        v-col(cols="4" v-if="computedCheckRolId")
          label(for="teamLeader") Jefe de Grupo
          v-select.mt-2(
                          background-color="white"
                          color="black"
                          dense
                          id="role"
                          :items="groupLeadersList"
                          item-color="orange"
                          item-text="leader.name"
                          item-value="leader.id"
                          outlined
                          placeholder="Jefe de Grupo"
                          :rules="[rules.required]"
                          v-model="rec.groupLeaderId"
                        )

      v-row(v-if="isAssistantForeman")
        v-col(cols="12")
          label {{$t('construction managers')}}
          ConstructionManagers

      v-row
        v-col(cols="12")
          label(for="observations") {{$t('observations')}}
          v-textarea.mt-2(
                              autocomplete="false"
                              background-color="white"
                              color="black"
                              heigh="131"
                              id="observations"
                              :placeholder="$t('observations')"
                              outlined
                              v-model="rec.description"
                            )

      v-row.mt-10(justify="end")
        v-col(cols="3")
          v-btn(  @click="creatingUser"
                  color="primary"
                  height="40"
                  rounded
                  width="170"
              ) {{$t('save')}}
</template>

<script>
import Vue from 'vue'
import { createNamespacedHelpers } from 'vuex'

import { createUser, getGroupLeaders } from '@/services/Users'

import LoadingSpinner from '@/components/utils/Loading.vue'
import ConstructionManagers from './assistantForeman/ConstructionManagers'

const userModule = createNamespacedHelpers('users')
const rolesModule = createNamespacedHelpers('roles')

export default Vue.extend({
  name: 'NewUserForm',

  components: {
    LoadingSpinner,
    ConstructionManagers
  },

  data () {
    return {
      groupLeadersList: [],
      isDataLoaded: false,
      isEmailDuplicate: false,
      isLoading: false,
      rolesList: [],
      constructionBossObject: [{
        createdAt: '2021-07-28T09:42:27.764Z',
        id: 3,
        name: 'Jefe de grupo',
        updatedAt: '2021-07-28T09:42:27.764Z'
      }],
      rules: {
        required: (value) => (value && value !== undefined) || 'Requerido'
      },
      valid: false
    }
  },
  computed: {
    ...userModule.mapState([
      'rec',
      'userList',
      'constructionManagers'
    ]),
    ...rolesModule.mapState([
      'rolesGlobalList'
    ]),

    computedCheckRolId () {
      return this.rec.rolId === 1 || this.rec.rolId === 2
    },

    computedThereIsLeaderGroup () {
      return this.groupLeadersList.length > 0
    },

    isAssistantForeman () {
      return this.rec.rolId === 2
    }
  },
  methods: {

    ...userModule.mapActions([
      'resetRecObject'
    ]),

    async callAllGroupLeader () {
      try {
        const response = await getGroupLeaders()
        if (response && response.length > 0) {
          this.groupLeadersList = response
        }
      } catch (error) {
        console.dir(error)
      }
    },

    async creatingUser () {
      this.isEmailDuplicate = false
      if (this.$refs.form.validate()) {
        try {
          this.isLoading = true
          this.rec.constructionManagers = this.constructionManagers
          const { data, status } = await createUser(this.rec)

          if (status === 201) {
            this.$showSuccess('Usuaro creado')
            this.$router.push({ path: '/users' })
          } else {
            throw new Error()
          }
        } catch (error) {
          console.error(error)
          this.handleCreationError(error)
        } finally {
          this.isLoading = false
        }
      }
    },

    handleCreationError (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.message.includes('duplicate key value violates unique constraint "email"')
      ) {
        this.$showError('Email duplicado', `El email ${this.rec.email} ya está en uso`)
      } else {
        this.$showError('Error al crear nuevo usuario')
      }
    },

    reset () {
      this.resetRecObject()
      this.groupLeadersList = []
      this.rolesList = []
    }

  },
  async beforeMount () {
    try {
      this.isLoading = true
      this.reset()
      await this.callAllGroupLeader()
      // eslint-disable-next-line no-unused-expressions
      this.computedThereIsLeaderGroup ? this.rolesList = this.rolesGlobalList : this.rolesList = this.constructionBossObject
    } catch (error) {
      console.dir(error)
    } finally {
      this.isDataLoaded = true
      this.isLoading = false
    }
  }
})
</script>
