
















import Vue from 'vue'
import ConstructionDocumentationNewConstruction from '@/components/Constructions/NewConstrucction/ConstructionDocumentationNewConstruction.vue'
export default Vue.extend({
  name: 'ConstructionNewDocumentationSection',
  components: {
    ConstructionDocumentationNewConstruction
  }
})
