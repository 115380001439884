
const state = {
  photoProfile: null,
  photoProfileUrl: null,
  sign: null,
  signUrl: null,
  arrayChoosenRolesId: [],
  rec: {},
  userList: {},
  userObjectToModify: {},
  constructionManagers: []
}

const actions = {

  async setArraChoosenRolesId ({ commit }: any, arrIdsRoles: any) {
    commit('SETTING_ARR_CHOOSE_ROLES_ID', arrIdsRoles)
  },

  async setUserObjectToModify ({ commit }: any, userObject: any) {
    await commit('SETTING_USER_OBJECT', userObject)
  },

  async setUserList ({ commit }: any, userList: number) {
    await commit('SETTING_USERS_LIST', userList)
  },

  async setUserListByRoles ({ commit }:any, users: any) {
    await commit('SETTING_USERS_LIST', users)
  },

  async resetRecObject ({ commit }: any) {
    await commit('RESET_REC')
  },

  async setPhotoProfile ({ commit }: any, img: any) {
    await commit('SET_PHOTO_PROFILE', img)
  },

  async resetPhotoProfile ({ commit }: any) {
    await commit('RESET_PHOTO_PROFILE')
  },

  async setPhotoProfileUrl ({ commit }: any, img: any) {
    await commit('SET_PHOTO_PROFILE_URL', URL.createObjectURL(img))
  },

  async resetPhotoProfileUrl ({ commit }: any) {
    await commit('RESET_PHOTO_PROFILE_URL')
  },

  async setSign ({ commit }: any, img: any) {
    await commit('SET_SIGN', img)
  },

  async resetSign ({ commit }: any) {
    await commit('RESET_SIGN')
  },

  async setSignUrl ({ commit }: any, img: any) {
    await commit('SET_SIGN_URL', URL.createObjectURL(img))
  },

  async resetSignUrl ({ commit }: any) {
    await commit('RESET_SIGN_URL')
  },

  async setConstructionManagers ({ commit }: any, cms: any[]) {
    await commit('SET_CONSTRUCTION_MANAGERS', cms)
  },

  async resetConstructionManagers ({ commit }: any) {
    await commit('RESET_CONSTRUCTION_MANAGERS')
  }
}

const mutations = {

  SETTING_ARR_CHOOSE_ROLES_ID (state: any, arrRolesId: any) {
    state.arrayChoosenRolesId = arrRolesId
  },

  SETTING_USER_OBJECT (state: any, userObject: any) {
    state.userObjectToModify = userObject
  },

  SETTING_USERS_LIST (state: any, usersList: any) {
    state.userList = usersList
  },

  RESET_REC (state: any) {
    state.rec = {}
  },

  SET_PHOTO_PROFILE (state: any, image: any) {
    state.photoProfile = image
  },

  RESET_PHOTO_PROFILE (state: any) {
    state.photoProfile = null
  },

  SET_PHOTO_PROFILE_URL (state: any, image: any) {
    state.photoProfileUrl = image
  },

  RESET_PHOTO_PROFILE_URL (state: any) {
    state.photoProfileUrl = null
  },

  SET_SIGN (state: any, sign: any) {
    state.sign = sign
  },

  RESET_SIGN (state: any) {
    state.sign = null
  },

  SET_SIGN_URL (state: any, sign: any) {
    state.signUrl = sign
  },

  RESET_SIGN_URL (state: any) {
    state.signUrl = null
  },

  SET_CONSTRUCTION_MANAGERS (state: any, users: any[]) {
    state.constructionManagers = users
  },

  RESET_CONSTRUCTION_MANAGERS (state: any) {
    state.constructionManagers = []
  }
}

export const users = {
  namespaced: true,
  actions,
  mutations,
  state
}
