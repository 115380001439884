import { RouteConfig } from 'vue-router'
import BillDetail from '@/views/bills/BillDetail.vue'
import { Roles } from '@/shared/enums/Roles'

const commonRoles = Object.values(Roles)

const routes: Array<RouteConfig> =
[
  {
    path: '/edit-bill/:id',
    name: 'BillDetail',
    component: BillDetail,
    meta: {
      requiresAuth: true,
      requiredRoles: commonRoles
    }
  }
]

export default routes
