





















































































import { UserSign } from '@/shared/types/UserSigns'
import Vue from 'vue'

export default Vue.extend({
  name: 'SignsSection',
  props: {
    showSubcontratorSign: {
      type: Boolean,
      required: false,
      default: false
    },
    constructionManager: {
      type: Object as () => UserSign,
      required: false
    },
    groupManager: {
      type: Object as () => UserSign,
      required: false
    },
    productionDirector: {
      type: Object as () => UserSign,
      required: false
    }
  },
  data () {
    return {

    }
  },
  computed: {
    getSignedByCMDate () {
      return this.constructionManager.signed
        ? new Date(+this.constructionManager.signedDate).toLocaleString()
        : ''
    },

    getSignedByGMDate () {
      return this.groupManager.signed
        ? new Date(+this.groupManager.signedDate).toLocaleString()
        : ''
    },

    getSignedByPDDate () {
      return this.productionDirector.signed
        ? new Date(+this.productionDirector.signedDate).toLocaleString()
        : ''
    },

    constructionManagerName () {
      return this.constructionManager.user
        ? this.constructionManager.user.name + this.constructionManager.user.surnames
        : ''
    },

    groupManagerName () {
      return this.groupManager.user
        ? this.groupManager.user?.name + this.groupManager?.user.surnames
        : ''
    },

    productionDirectorName () {
      return this.productionDirector.user
        ? this.productionDirector.user?.name + this.productionDirector?.user.surnames
        : ''
    }

  }
})
