<style lang="scss" scoped>
  .profile-sign {
    width: 100%;
    height: 100%;
    max-height: 200px;
    display: flex;
    flex-flow: row;
    justify-content: center;
    align-items: center;
    .profile-sign-choose {
      display: flex;
      flex-flow: column;
      justify-content: center;
    }
    .profile-sign-show {
      width: 200px;
      height: 150px;
      position: relative;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
      }
      .close-icon {
        position: absolute;
        right: -25px;
        top: 0;
      }
    }
  }
</style>

<template lang="pug">
  section.profile-sign
    .profile-sign-choose(v-show="this.sign == null && this.signUrl == null")
      v-btn(
        :disabled="disabled"
        fab x-large color="#797979"
        @click="$refs.showPreviewSign.click()"
      )
        v-icon(class="change-profile-sign" x-large color="white") mdi-draw-pen
      input.input-sign(
        style="display:none"
        @change="showPreviewSign($event)"
        accept="image/png, image/jpeg"
        id="sign"
        name="sign"
        ref="showPreviewSign"
        type="file"
      )
    .profile-sign-show(v-show="this.signUrl != null")
      img(
        id="imageToShow"
        :src="this.signUrl"
      )
      v-icon(class="close-icon" @click="removeSign()") mdi-close-circle-outline
</template>

<script>
import Vue from 'vue'
import { createNamespacedHelpers } from 'vuex'

import { getProfileSign } from '@/services/Users'

const usersModule = createNamespacedHelpers('users')

/*eslint-disable */
export default Vue.extend({
  name: 'InputSignProfile',
  props: {
    disabled: {
      type: Boolean,
      required: true
    }
  },
  computed: {
    ...usersModule.mapState([
      'userObjectToModify',
      'sign',
      'signUrl'
    ])
  },
  methods: {
    ...usersModule.mapActions([
      'setSign',
      'setSignUrl',
      'resetSign',
      'resetSignUrl'
    ]),
    showPreviewSign (event) {
      this.setSign(event.target.files[0])
      this.setSignUrl(event.target.files[0])
    },
    removeSign () {
      this.resetSign()
      this.resetSignUrl()
    },
    async downloadProfileSign () {
      const image = await getProfileSign(this.$router.currentRoute.params.id)
      if (image) {
        this.setSignUrl(image)
      }
    }
  },
  mounted () {
    this.removeSign()
    this.downloadProfileSign()
  }
})
</script>
