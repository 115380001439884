<style lang="scss" scoped>
  @import '@/styles/mixins.scss';

  .supplier-search-form {
    /* width: 70rem;; */
    form {
      @include form;
    }
    i {
      display: none;
    }
    .search-button {
      align-items: flex-end;
      display: flex;
      height: 100%;
      justify-content: flex-end;
      margin-top: 1rem;
      padding-right: 1rem;
    }
  }

</style>

<template lang="pug">
.supplier-search-form(v-if="isDataLoaded")
  LoadingSpinner(v-show="isLoading")
  form(
    ref="form"
  )
    v-row
      v-col(cols="10")
        v-row
          v-col(cols="3")
            label {{$t('category')}}
            v-select.mt-2(  background-color="white"
                            clearable
                            color="black"
                            dense
                            hide-details
                            id="role"
                            :items="categoriesGlobalList"
                            item-color="orange"
                            item-text="name"
                            item-value="id"
                            :placeholder="$t('category')"
                            outlined
                            v-model="supplierSearchFormObject.category"
                          )
          v-col(cols="3")
            label {{$t('subcategory')}}
            v-select.mt-2(  background-color="white"
                            clearable
                            color="black"
                            dense
                            hide-details
                            id="role"
                            :items="subcategoriesList"
                            item-color="orange"
                            item-text="name"
                            :placeholder="$t('subcategory')"
                            outlined
                            return-object
                            v-model="supplierSearchFormObject.subcategory"
                              )
          v-col(cols="3")
            label(for="date") Fecha
            v-menu( ref="menu1"
                    :value="supplierDateMenu"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    id="date"
                    max-width="290px"
                    min-width="auto"
                  )
                template(v-slot:activator="{ on, attrs }")
                  v-text-field.mt-2(
                                      outlined
                                      hide-details
                                      placeholder="Fecha"
                                      persistent-hint
                                      dense
                                      v-bind="attrs"
                                      v-on="on"
                                      :value="supplierDateMenu"
                                    )
                v-date-picker(  v-model="supplierSearchFormObject.entryDate"
                                :min="currentDate"
                                no-title
                                @input="menu1 = false"
                              )
        v-row.mt-8
          v-col(cols="3" )
            label(for="name") {{$t('name')}}
            v-text-field(
                          background-color="white"
                          color="black"
                          dense
                          hide-details
                          id="name"
                          :placeholder="$t('name')"
                          outlined
                          v-model="supplierSearchFormObject.name"
                          )
          v-col(cols="3" ) {{$t('cif')}}
            label(for="cif")
            v-text-field( background-color="white"
                          color="black"
                          dense
                          hide-details
                          id="cif"
                          :placeholder="$t('cif')"
                          outlined
                          v-model="supplierSearchFormObject.CIF"
                        )
          v-col(cols="3" ) {{$t('zip')}}
            label(for="zip")
            v-text-field(
                          autocomplete="false"
                          background-color="white"
                          color="black"
                          dense
                          hide-details
                          id="zip"
                          :placeholder="$t('zip')"
                          outlined
                          v-model="supplierSearchFormObject.PC"
                          )
          v-col(cols="3") {{$t('phone')}}
            label(for="phone")
            v-text-field(
                          autocomplete="false"
                          background-color="white"
                          color="black"
                          dense
                          hide-details
                          id="phone"
                          :placeholder="$t('phone')"
                          outlined
                          v-model="supplierSearchFormObject.phone"
                          )
        v-row.mt-8
          v-col(cols="6")
            label(for="address") {{$t('address')}}
            v-text-field(
                          autocomplete="false"
                          background-color="white"
                          color="black"
                          dense
                          hide-details
                          id="address"
                          :placeholder="$t('address')"
                          outlined
                          v-model="supplierSearchFormObject.street"
                          )
          v-col(cols="3" )
                label(for="town") {{$t('town')}}
                v-text-field(
                              autocomplete="false"
                              background-color="white"
                              color="black"
                              dense
                              hide-details
                              id="town"
                              :placeholder="$t('town')"
                              outlined
                              v-model="supplierSearchFormObject.town"
                              )
          v-col(cols="3")
            label(for="province") {{$t('province')}}
            v-text-field(
                          autocomplete="false"
                          background-color="white"
                          color="black"
                          dense
                          hide-details
                          id="province"
                          :placeholder="$t('province')"
                          outlined
                          v-model="supplierSearchFormObject.province"
                          )
      v-col(cols="2")
        .search-button
          v-btn(@click="searchSupplierByFields" color="primary" rounded) Buscar
</template>

<script>
import Vue from 'vue'
import { createNamespacedHelpers } from 'vuex'

import moment from 'moment'

import LoadingSpinner from '@/components/utils/Loading.vue'

import { getSubcategoryByCategoryId } from '@/services/Category'
import { getSupplierSearchByFields } from '@/services/Supplier'

const categoriesModule = createNamespacedHelpers('categories')
const globalModule = createNamespacedHelpers('global')
const supplierModule = createNamespacedHelpers('suppliers')

export default Vue.extend({
  name: 'supplierSearchForm',

  components: {
    LoadingSpinner
  },

  data () {
    return {
      currentDate: '',
      isDataLoaded: false,
      isLoading: false,
      menu1: '',
      subcategoriesList: [],
      supplierDateMenu: ''
    }
  },

  computed: {

    ...globalModule.mapState(['currentDate']),

    ...categoriesModule.mapState(['categoriesGlobalList']),

    ...supplierModule.mapState(['supplierSearchFormObject'])

  },

  methods: {
    ...supplierModule.mapActions([
      'resetSupplierListByField',
      'resetSupplierSearchForm',
      'settingSupplierListByFields'
    ]),

    async searchSupplierByFields () {
      this.isLoading = true
      if (Object.entries(this.supplierSearchFormObject).length >= 1) {
        try {
          const response = await getSupplierSearchByFields(this.supplierSearchFormObject, 0)
          this.settingSupplierListByFields(response)
          this.isLoading = false
        } catch (error) {
          console.dir(error)
        }
      } else {
        this.isLoading = false
      }
    }
  },

  watch: {
    async 'supplierSearchFormObject.category' (value) {
      try {
        if (value) {
          try {
            this.isLoading = true
            const response = await getSubcategoryByCategoryId(value)
            this.subcategoriesList = response
          } catch (error) {
            console.error(error)
          } finally {
            this.isLoading = false
          }
        }
      } catch (error) {
        console.dir(error)
      }
    },
    'supplierSearchFormObject.entryDate' (value) {
      this.supplierDateMenu = moment(value).format('DD/MM/YYYY')
    }
  },

  beforeMount () {
    this.isLoading = true
    this.currentDate = new Date().toISOString()
    this.isDataLoaded = true
    this.isLoading = false
  },

  beforeDestroy () {
    this.resetSupplierListByField()
    this.resetSupplierSearchForm()
  }
})
</script>
