


































import Vue from 'vue'
export default Vue.extend({
  name: 'ButtonNewUser',
  props: {
    goTo: {
      type: String,
      required: true
    },
    text: {
      type: String,
      required: true
    }
  },
  methods: {
    goingToProp () {
      this.$router.push(this.goTo)
    }
  }
})
