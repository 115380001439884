
































































































import Vue from 'vue'
import { createNamespacedHelpers } from 'vuex'
import { findRelatedDocumentsByContract } from '../../services/ExtensionsAndProformas'
import CreateButton from '@/components/utils/CreateButton.vue'
import BackArrowComp from '@/components/utils/BackArrowComp.vue'
import LoadingSpinner from '@/components/utils/Loading.vue'
import NoResultMessage from '@/components/utils/NoResultMessage.vue'
import ExtensionAndProformListTable from './table/ExtensionAndProformListTable.vue'
import { PermissionsMixin } from '@/mixins/PermissionsMixin'

const contractModule = createNamespacedHelpers('contracts')
const suppliersModule = createNamespacedHelpers('suppliers')

export default Vue.extend({
  name: 'ExtensionAndProformaListDashboard',
  mixins: [
    PermissionsMixin
  ],
  props: {
    contractId: {
      type: String,
      required: true
    },
    proformaPermissions: {
      type: Object,
      required: true
    },
    extensionPermissions: {
      type: Object,
      required: true
    }
  },
  components: {
    CreateButton,
    BackArrowComp,
    LoadingSpinner,
    NoResultMessage,
    ExtensionAndProformListTable
  },

  data () {
    return {
      sectionName: String,
      isLoading: false,
      showList: true,
      extensionsAndProformaList: [],
      monthNames: [
        'Enero',
        'Febrero',
        'Marzo',
        'Abril',
        'Mayo',
        'Junio',
        'Julio',
        'Agosto',
        'Septiembre',
        'Octubre',
        'Noviembre',
        'Diciembre'
      ]
    }
  },

  computed: {
    ...contractModule.mapState([
      'idCurrentConstruction',
      'objectCurrentConstruction',
      'selectedContractId'
    ])
  },

  methods: {

    ...contractModule.mapActions([
      'settingIsShowingSigned'
    ]),

    async firstThingToDo () {
      this.isLoading = true
      try {
        await this.getData()
      } catch (error) {
        console.error(error)
        this.showList = false
      } finally {
        this.setHeadingsTexts()
      }
      this.isLoading = false
    },

    async getData () {
      const { status, data } = await findRelatedDocumentsByContract(this.contractId)
      if (status === 200) {
        this.sortByTime(data)
        this.formatData(data)
      } else {
        throw new Error()
      }
    },

    sortByTime (dataList: any) {
      return dataList.sort((a: any, b: any) => {
        return new Date(b.content.createdAt).getTime() - new Date(a.content.createdAt).getTime()
      })
    },

    formatData (data) {
      const formattedData = data.map((element) => ({
        ...element,
        createdAt: new Date(element.content.updatedAt).toLocaleString(),
        name: this.monthNames[new Date(element.content.createdAt).getMonth()] + ' - ' + element.content.createdAt.substring(0, 4),
        price: element.type === 'Proforma' ? this.getProformaTotal(element) : element.type === 'Ampliación' ? this.getExtensionTotal(element) : `${element.content.totalPrice} €`,
        status: element.content.status || '-',
        detail: 'Ver detalles'
      }))

      this.setData(formattedData)
    },

    setData (response: any) {
      this.extensionsAndProformaList = response
    },

    setHeadingsTexts () {
      this.sectionName = 'Proformas, Ampliaciones y Facturas'
    },

    getProformaTotal (proforma: any): string {
      const calculateSummatory = (measurements) =>
        measurements.reduce((sum, measurement) => {
          if (measurement.totalUnitsPaid > 0 && measurement.measurement.unitPrice > 0) {
            return sum + measurement.measurement.unitPrice * measurement.totalUnitsPaid
          }
          return sum
        }, 0)

      const summatoryOrigin = calculateSummatory(proforma.content.proformaMeasurement)
      const summatoryBeforeOrigin = proforma.content.lastProforma
        ? calculateSummatory(proforma.content.lastProforma.proformaMeasurement)
        : 0

      const summatoryTotal = summatoryOrigin - summatoryBeforeOrigin
      const retention = summatoryTotal * (proforma.content.contract.retention / 100)
      const total = summatoryTotal - retention

      return total.toFixed(2) + ' €'
    },

    getExtensionTotal (extension: any): string {
      let totalPrice = 0
      for (const measurement of extension.content.measurements) {
        totalPrice += measurement.totalPrice
      }
      return totalPrice.toFixed(2) + ' €'
    },

    backToConstructionContractsList () {
      this.settingIsShowingSigned(true)
      this.$router.push({ path: `/contracts-list/${this.objectCurrentConstruction.id}` })
    }
  },

  beforeMount () {
    this.firstThingToDo()
  }
})
