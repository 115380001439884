

































import Vue from 'vue'
import SuppliersLoginForm from '@//components/SuppliersSubdomain/SuppliersLoginForm.vue'

export default Vue.extend({
  name: 'SuppliersLanding',
  components: {
    SuppliersLoginForm
  }
})
