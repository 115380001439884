




























































































































import Vue from 'vue'
import { createNamespacedHelpers } from 'vuex'

const contractModule = createNamespacedHelpers('contracts')

export default Vue.extend({
  name: 'ContractSelectedSupplierModule',

  computed: {

    ...contractModule.mapState([
      'recProvider',
      'selectedSupplierObject'
    ])
  },
  props: {
    selectedSupplier: {
      type: Object,
      default: () => ({})
    }
  }
})
