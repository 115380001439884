
const state = {
  rolesGlobalList: []
}

const actions = {
  setRolesGlobalList ({ commit }: any, arrList: any) {
    commit('SETTING_GLOBAL_ROLES_LIST', arrList)
  }
}

const mutations = {
  SETTING_GLOBAL_ROLES_LIST (state: any, arrList: any) {
    state.rolesGlobalList = arrList
  }
}

export const roles = {
  namespaced: true,
  actions,
  mutations,
  state
}
