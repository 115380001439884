var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{staticClass:"extension-proforma-table",attrs:{"elevation":"1","headers":_vm.headers,"items":_vm.extensionAndProformaList,"items-per-page":10,"footer-props":{'items-per-page-text': 'Resultados por página:'}},scopedSlots:_vm._u([{key:"item.type",fn:function(ref){
var item = ref.item;
return [_c('td',[_vm._v(_vm._s(item.type))])]}},{key:"item.createdAt",fn:function(ref){
var item = ref.item;
return [_c('td',[_vm._v(_vm._s(new Date(item.content.createdAt).toLocaleString('es-ES')))])]}},{key:"item.name",fn:function(ref){
var item = ref.item;
return [(item.type === 'Proforma')?[_vm._v(_vm._s(item.content.proformaCode || '-'))]:(item.type === 'Ampliación')?[_vm._v(_vm._s(item.content.codigoContrato || '-'))]:[_vm._v(_vm._s(item.content.invoiceNumber || '-'))]]}},{key:"item.price",fn:function(ref){
var item = ref.item;
return [_c('td',[_vm._v(_vm._s(item.price))])]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('td',[_vm._v(_vm._s(item.status))])]}},{key:"item.detail",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"flex-end-center"},[_c('td',{staticClass:"centered-row"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"btn-detail",attrs:{"color":"#545454","elevation":"1"},on:{"click":function($event){return _vm.goToDetail(item.type, item.content.id)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-eye")])],1)]}}],null,true)},[_c('span',[_vm._v("Ver detalles")])])],1),_c('td',{staticClass:"bg-white"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(_vm.canDelete(item))?_c('v-btn',_vm._g(_vm._b({staticClass:"btn-delete",attrs:{"color":"#545454","elevation":"1"},on:{"click":function($event){return _vm.deleteExtensionOrProforma(item)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-trash-can")])],1):_vm._e()]}}],null,true)},[_c('span',[_vm._v("Eliminar")])])],1)])]}}])},[_c('template',{slot:"no-data"},[_c('td',[_c('p',[_vm._v("No hay resultados que mostrar")])])])],2)}
var staticRenderFns = []

export { render, staticRenderFns }