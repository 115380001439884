








































import Vue from 'vue'
import { createNamespacedHelpers } from 'vuex'

import { deleteConstructionById, getConstructionFinishedOrOnWork, getConstructionSubfoldersByDefaultFolderId } from '@/services/Construction'
import { deleteSupplierById, getSuppliersByPage } from '@/services/Supplier'
import { deleteUserbById, findUsersByRoles, getUsersByPage } from '@/services/Users'
import { deleteDocument } from '@/services/Documents'
import LoadingSpinner from '@/components/utils/Loading.vue'
import ConfirmAction from './ConfirmAction.vue'

const constructionsModule = createNamespacedHelpers('constructions')
const suppliersModules = createNamespacedHelpers('suppliers')
const usersModules = createNamespacedHelpers('users')

export default Vue.extend({
  name: 'DeleteUserModal',
  components: {
    ConfirmAction,
    LoadingSpinner
  },
  props: {

    constructionTypeList: {
      type: String,
      required: false
    },
    idToDelete: {
      type: Number,
      required: true
    },
    section: {
      type: String,
      required: true
    }

  },

  data () {
    return {
      dialog: false,
      isLoading: false,
      showConfirm: false,
      currentUser: this.$cookies.get('user')
    }
  },

  computed: {
    ...constructionsModule.mapState([
      'constructionFinishedList',
      'constructionOnWorkList',
      'idCurrentSubfolder',
      'filter'
    ]),

    ...suppliersModules.mapState(['supplierList']),

    ...usersModules.mapState([
      'arrayChoosenRolesId',
      'userList'
    ]),

    confirmActionTitle () {
      switch (this.section) {
        case 'users': return '¿Quieres eliminar el usuario?'
        case 'suppliers': return '¿Quieres eliminar al proveedor?'
        case 'contracts': return '¿Quieres eliminar el contrato?'
        case 'constructions': return '¿Quieres eliminar la obra?'
        case 'files': return '¿Quieres eliminar el archivo?'
      }
      return ''
    },

    confirmActionSubtitle () {
      return 'Recuerda que esto es una acción que no se puede deshacer'
    }
  },

  methods: {
    ...usersModules.mapActions(
      ['setUserList']
    ),

    ...suppliersModules.mapActions(
      ['setSupplierList']
    ),

    ...constructionsModule.mapActions([
      'setConstructionFinishedList',
      'setConstructionOnWorkList',
      'setObjectFilesToShow'
    ]),

    async deleteAction (idToDelete: number) {
      this.showConfirm = false
      if (this.section === 'users') {
        this.deleteUser(idToDelete)
      }
      if (this.section === 'suppliers') {
        this.deleteSupplier(idToDelete)
      }
      if (this.section === 'constructions') {
        this.deleteConstruction(idToDelete)
      }

      if (this.section === 'files') {
        this.deleteFile(idToDelete)
      }

      if (this.section === 'contracts') {
        this.deleteContract(idToDelete)
      }
    },

    async deleteConstruction (idToDelete: number) {
      try {
        this.isLoading = true
        const { status } = await deleteConstructionById(idToDelete)
        if (status === 200) {
          this.$showSuccess('Obra eliminada')
          this.choosingTheConstructionListToSet()
        } else {
          throw new Error()
        }
      } catch (error) {
        console.dir(error)
        this.$showError('Error al eliminar la obra')
      } finally {
        this.isLoading = false
        this.dialog = false
      }
    },

    async deleteFile (idToDelete: number) {
      try {
        this.isLoading = true
        const { status } = await deleteDocument(idToDelete)
        if (status === 200) {
          this.$showSuccess('Archivo eliminado')
          const response = await getConstructionSubfoldersByDefaultFolderId(this.idCurrentSubfolder)
          await this.setObjectFilesToShow(response)
          this.$emit('reRender')
        } else {
          throw new Error()
        }
      } catch (error) {
        console.error(error)
        this.$showError('Error al eliminar el archivo')
      } finally {
        this.isLoading = false
        this.dialog = false
      }
    },

    async deleteSupplier (idToDelete: number) {
      try {
        this.isLoading = true
        const { status, data } = await deleteSupplierById(idToDelete)
        if (status === 200) {
          this.$showSuccess('Proveedor eliminado')
        }

        if (data.code && data.code === 409) {
          this.$fire({
            title: 'Imposible eliminar proveedor',
            text: '¿Está asociado a algún contrato?',
            type: 'error',
            confirmButtonColor: '#F7A549'
          })
        }

        const response = await getSuppliersByPage(true, this.getCurrentProviderPage())
        await this.setSupplierList(response)
      } catch (error) {
        console.dir(error)
        this.$showError('Error al eliminar el proveedor')
      } finally {
        this.isLoading = false
        this.dialog = false
      }
    },

    getCurrentProviderPage (): number {
      if (this.supplierList.currentPage > 0 &&
         (this.supplierList.currentPage === this.supplierList.lastPage) &&
          this.supplierList.content.length === 1) {
        return this.supplierList.currentPage - 1
      }
      return this.supplierList.currentPage
    },

    async deleteUser (idToDelete: number) {
      try {
        this.isLoading = true
        const { status } = await deleteUserbById(idToDelete)

        if (status === 200) {
          this.$showSuccess('Usuario eliminado')
          if (Object.entries(this.arrayChoosenRolesId).length >= 1) {
            const response = await findUsersByRoles({ rolsIds: this.arrayChoosenRolesId }, this.userList.currentPage)
            this.setingtUserList(response)
          } else {
            const response = await getUsersByPage(this.userList.currentPage)
            this.setingtUserList(response)
          }
        } else {
          throw new Error()
        }
      } catch (error) {
        console.error(error)
        this.$showError('Error al eliminar el usuario')
      } finally {
        this.isLoading = false
      }
    },

    choosingTheConstructionListToSet () {
      // eslint-disable-next-line no-unused-expressions
      this.constructionTypeList === 'onWork' ? this.settingOnWorkConstructionList() : null
      // eslint-disable-next-line no-unused-expressions
      this.constructionTypeList === 'finished' ? this.settingFinishedConstructionList() : null
    },

    async settingOnWorkConstructionList () {
      this.isLoading = true
      if (this.filter.length >= 1) {
        const response = await getConstructionFinishedOrOnWork({ finished: false, groupOf: this.currentUser.id, name: this.filter }, this.constructionFinishedList.currentPage)
        await this.setConstructionOnWorkList(response)
        this.isLoading = false
      } else {
        const response = await getConstructionFinishedOrOnWork({ finished: false, groupOf: this.currentUser.id }, this.constructionFinishedList.currentPage)
        await this.setConstructionOnWorkList(response)
        this.isLoading = false
      }
    },

    async settingFinishedConstructionList () {
      this.isLoading = true
      if (this.filter.length >= 1) {
        const response = await getConstructionFinishedOrOnWork({ finished: true, groupOf: this.currentUser.id, name: this.filter }, this.constructionFinishedList.currentPage)
        await this.setConstructionOnWorkList(response)
        this.isLoading = false
      } else {
        const response = await getConstructionFinishedOrOnWork({ finished: true, groupOf: this.currentUser.id }, this.constructionFinishedList.currentPage)
        await this.setConstructionOnWorkList(response)
        this.isLoading = false
      }
    },

    async setingtUserList (response: any) {
      try {
        this.isLoading = true
        await this.setUserList(response)
      } catch (error) {
        console.dir(error)
      } finally {
        this.dialog = false
        this.isLoading = false
      }
    }
  }
})
