























import Vue from 'vue'
import { createNamespacedHelpers } from 'vuex'
import ExtensionAndProformaListDashboard from '@/components/Proforma/ExtensionAndProformaListDashboard.vue'
import { PermissionsMixin } from '@/mixins/PermissionsMixin'
import { defaultPermissions } from '@/shared/types/Permission'
import { Sections } from '@/shared/enums/Sections'

export default Vue.extend({
  name: 'RelatedDocuments',
  mixins: [
    PermissionsMixin
  ],
  components: {
    ExtensionAndProformaListDashboard
  },
  data () {
    return {
      contractId: Number,
      proformaPermissions: defaultPermissions,
      extensionPermissions: defaultPermissions
    }
  },
  methods: {
    initContractId () {
      this.contractId = this.$route.params.id
    },

    checkPermissions () {
      this.proformaPermissions = this.getPermissions(Sections.PROFORMA)
      this.extensionPermissions = this.getPermissions(Sections.EXTENSION)
    }
  },

  beforeMount () {
    this.checkPermissions()
    this.initContractId()
  }
})
