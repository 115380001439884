import { ConstructionsType, DefaultFolders, NewConstruction, PaginationObjectList } from '@/store/types/index'
import { contracts } from './contracts-module'

const state = {
  constructionEditObject: {} as NewConstruction,
  constructionOnWorkList: {} as PaginationObjectList,
  constructionFinishedList: {} as PaginationObjectList,
  constructionType: [{} as ConstructionsType],
  idCurrentSubfolder: '' as string,
  defaultFolders: [{} as DefaultFolders],
  isShowDocumentationSectionGlobal: false as boolean,
  objectFilesToShow: {},
  rec: {} as NewConstruction,
  filter: '',
  constructionManager: null,
  productionDirector: null,
  groupManager: null
}

const actions = {

  async setConstructionEditObject ({ commit }: any, payload: any) {
    commit('SET_CONSTRUCTION_EDIT_OBJECT', payload)
  },

  async setConstructionFinishedList ({ commit }:any, finishedList: any) {
    await commit('SET_CONSTRUCTION_FINISHED', finishedList)
  },

  async setConstructionOnWorkList ({ commit }:any, onWorkList: any) {
    await commit('SET_CONSTRUCTION_ON_WORK_LIST', onWorkList)
  },

  async setConstructionType ({ commit }:any, constructionType: any) {
    await commit('SET_CONSTRUCTION_TYPE', constructionType)
  },

  async setIdCurrentSubFolder ({ commit }: any, currentSubfolder: number) {
    await commit('SET_CURRENT_SUBFOLDER', currentSubfolder)
  },

  async setDefaultFolders ({ commit }: any, defaultFoldersList: any) {
    await commit('SET_DEFAULT_FOLDERS', defaultFoldersList)
  },

  async setIsShowDocumentationSectionTrue ({ commit }: any) {
    await commit('SET_IS_SHOW_DOCUMENTATION_TRUE')
  },

  async setIsShowDocumentationSectionFalse ({ commit }: any) {
    await commit('SET_IS_SHOW_DOCUMENTATION_FALSE')
  },

  async setObjectFilesToShow ({ commit }: any, payload: any) {
    await commit('SET_OBJECT_FILES_TO_SHOW', payload)
  },

  async resetRec ({ commit }: any) {
    await commit('RESET_REC')
  },

  async setFilterObject ({ commit }: any, payload: any) {
    await commit('SET_FILTER_OBJECT', payload)
  },

  async setConstructionManager ({ commit }: any, constructionManager: any) {
    await commit('SET_CONSTRUCTION_MANAGER', constructionManager)
  },

  async resetConstructionManager ({ commit }: any) {
    await commit('RESET_CONSTRUCTION_MANAGER')
  },

  async setProductionDirector ({ commit }: any, productionDirector: any) {
    await commit('SET_PRODUCTION_MANAGER', productionDirector)
  },

  async resetProductionDirector ({ commit }: any) {
    await commit('RESET_PRODUCTION_MANAGER')
  },

  async setGroupManager ({ commit }: any, groupManager: any) {
    await commit('SET_GROUP_LEADER', groupManager)
  },

  async resetGroupManager ({ commit }: any) {
    await commit('RESET_GROUP_LEADER')
  }
}

const mutations = {

  'SET_CONSTRUCTION_EDIT_OBJECT' (state: any, payload: any) {
    state.constructionEditObject = payload
  },

  'SET_CONSTRUCTION_FINISHED' (state: any, finishedList: any) {
    state.constructionFinishedList = finishedList
  },

  'SET_CONSTRUCTION_ON_WORK_LIST' (state: any, onWorkList: any) {
    state.constructionOnWorkList = onWorkList
  },

  'SET_CONSTRUCTION_TYPE' (state: any, constructionType: any) {
    state.constructionType = constructionType
  },

  'SET_CURRENT_SUBFOLDER' (state: any, currentSubfolder: number) {
    state.idCurrentSubfolder = currentSubfolder
  },

  'SET_DEFAULT_FOLDERS' (state: any, defaultFoldersList: any) {
    state.defaultFolders = defaultFoldersList
  },

  'SET_IS_SHOW_DOCUMENTATION_TRUE' (state: any) {
    state.isShowDocumentationSectionGlobal = true
  },

  'SET_IS_SHOW_DOCUMENTATION_FALSE' (state: any) {
    state.isShowDocumentationSectionGlobal = false
  },

  'SET_OBJECT_FILES_TO_SHOW' (state: any, payload: any) {
    state.objectFilesToShow = payload
  },

  'RESET_REC' (state: any) {
    state.rec = {}
  },

  'SET_FILTER_OBJECT' (state: any, payload: any) {
    state.filter = payload
  },

  'SET_CONSTRUCTION_MANAGER' (state: any, constructionManager: any) {
    state.constructionManager = constructionManager
    state.rec.constructionManager = constructionManager
    state.constructionEditObject.constructionManager = constructionManager
  },

  'RESET_CONSTRUCTION_MANAGER' (state: any) {
    state.constructionManager = null
    state.rec.constructionManager = null
    state.constructionEditObject.constructionManager = null
  },

  'SET_PRODUCTION_MANAGER' (state: any, productionDirector: any) {
    state.productionDirector = productionDirector
    state.rec.productionDirector = productionDirector
    state.constructionEditObject.productionDirector = productionDirector
  },

  'RESET_PRODUCTION_MANAGER' (state: any) {
    state.productionDirector = null
    state.rec.productionDirector = null
    state.constructionEditObject.productionDirector = null
  },

  'SET_GROUP_LEADER' (state: any, groupManager: any) {
    state.groupManager = groupManager
    state.rec.groupManager = groupManager
    state.constructionEditObject.groupManager = groupManager
  },

  'RESET_GROUP_LEADER' (state: any) {
    state.groupManager = null
    state.rec.groupManager = null
    state.constructionEditObject.groupManager = null
  }
}

export const constructions = {
  namespaced: true,
  actions,
  mutations,
  state
}
