






























import Vue from 'vue'
import LoginForm from '@/components/Landing/LoginForm.vue'

export default Vue.extend({
  name: 'Landing',
  components: {
    LoginForm
  },
  data () {
    return {
      isShowRemenberPassword: false
    }
  },
  methods: {
    showingRemenbercomponent () {
      this.isShowRemenberPassword = true
    }
  }
})
