








































import Vue from 'vue'
import { createNamespacedHelpers } from 'vuex'

import DisplayTable from '@/components/table/DisplayTable.vue'
import Pagination from '@/components/table/Pagination.vue'
import { PermissionByRol, defaultPermissions } from '@/shared/types/Permission'

const suppliersModule = createNamespacedHelpers('suppliers')

export default Vue.extend({
  name: 'SearchSupplierDashboard',
  components: {
    DisplayTable,
    Pagination
  },
  props: {
    permissions: Object as () => PermissionByRol
  },
  data () {
    return {
      columns: [
        'name',
        'id',
        'entryDate',
        'endDate',
        'state'
      ]
    }
  },
  computed: {
    ...suppliersModule.mapState(['supplierListSearchByField']),

    supplierListByFieldsCompleteObject () {
      if (Object.entries(this.supplierListSearchByField).length >= 1) {
        return this.supplierListSearchByField
      } else return {}
    }
  }
})
