




























import Vue from 'vue'
import { createNamespacedHelpers } from 'vuex'
import { PermissionsMixin } from '@/mixins/PermissionsMixin'
import BackArrowComp from '@/components/utils/BackArrowComp.vue'
import ConstructionEditForm from '@/components/Constructions/ConstructionEditForm.vue'
import { defaultPermissions } from '@/shared/types/Permission'
import { Sections } from '@/shared/enums/Sections'

const globalModule = createNamespacedHelpers('global')

export default Vue.extend({
  name: 'ConstructionEdit',
  mixins: [
    PermissionsMixin
  ],
  components: {
    BackArrowComp,
    ConstructionEditForm
  },
  data () {
    return {
      permissions: defaultPermissions
    }
  },
  methods: {
    ...globalModule.mapActions(
      ['setCurrentPath']
    ),

    checkPermissions () {
      this.permissions = this.getPermissions(Sections.CONSTRUCTIONS)
    }
  },
  async beforeMount () {
    this.checkPermissions()
    this.setCurrentPath()
  }
})
