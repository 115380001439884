import { NavigationGuardNext, RouteConfig } from 'vue-router'
import Vue from 'vue'
import Landing from '@/views/Landing.vue'
import NewPassword from '@/views/NewPassword.vue'
import Home from '@/views/Home.vue'

const isAlreadyRegistered = (): boolean => {
  const token = Vue.$cookies.isKey('token')
  const user = Vue.$cookies.isKey('user')

  if (token && user) {
    return true
  }
  return false
}

const handleAlreadyRegistered = (next: NavigationGuardNext) => {
  if (isAlreadyRegistered()) {
    next('/home')
  } else {
    next()
  }
}
const routes: Array<RouteConfig> = [

  {
    path: '/',
    name: 'Landing',
    component: Landing,
    meta: {
      requiresAuth: false,
      requiredRoles: []
    },
    beforeEnter (to, from, next) {
      handleAlreadyRegistered(next)
    }
  },
  {
    path: '/home',
    name: 'Home',
    component: Home,
    meta: {
      requiresAuth: true,
      requiredRoles: []
    }
  },
  {
    // path: '/create-password',
    path: '/create-password/:token',
    name: 'CreateNewPassword',
    component: NewPassword,
    meta: {
      requiresAuth: false,
      requiredRoles: []
    }
  }
]

export default routes
