








































import Vue from 'vue'
import InputLogo from '@/components/Suppliers/Logo/InputLogo.vue'

export default Vue.extend({
  name: 'LogoSupplierCard',
  components: {
    InputLogo
  },

  computed: {

    computedSupplierSection () {
      return this.section && this.section === 'suppliers'
    }

  },

  props: {
    name: {
      type: String,
      required: false
    },
    logo: {
      type: String,
      required: false
    },
    section: {
      type: String,
      required: false
    }
  }

})
