import __axios from '@/plugins/axios'
import Vue from 'vue'

function getHeaders () {
  const token = Vue.$cookies.get('token')
  return token ? { Authorization: `Bearer ${token}` } : {}
}

async function deleteDocument (idFile: number) {
  return await __axios.delete(`api/construction/file/${idFile}`, { headers: getHeaders() })
}

async function downloadDocument (idFile: number) {
  const response = await __axios.get(`api/construction/file/${idFile}/download`, { headers: getHeaders() })
  return response
}

async function returnFile (idFile: number) {
  const response = await __axios.get(`api/construction/file/${idFile}/returnFile`, {
    responseType: 'arraybuffer',
    headers: getHeaders()
  })
  return response
}

async function uploadConstructionFile (payload: any) {
  await __axios.post('api/construction/file', payload, { headers: getHeaders() })
}

export {
  deleteDocument,
  downloadDocument,
  returnFile,
  uploadConstructionFile
}
