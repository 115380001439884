































import Vue from 'vue'
import { createNamespacedHelpers } from 'vuex'

import BackArrowComp from '@/components/utils/BackArrowComp.vue'
import LogoSupplierCard from '@/components/Suppliers/Logo/LogoSupplierCard.vue'
import EditSupplierForm from '@/components/Suppliers/EditSupplierForm.vue'

const globalModule = createNamespacedHelpers('global')

export default Vue.extend({
  name: 'NewSupplier',
  components: {
    BackArrowComp,
    LogoSupplierCard,
    EditSupplierForm
  },

  data () {
    return {
      idSupplier: 0 as number,
      isDataLoaded: false as boolean
    }
  },

  methods: {
    ...globalModule.mapActions(
      ['setCurrentPath']
    )
  },
  mounted () {
    const idToNumber = this.$router.currentRoute.params.id
    this.idSupplier = Number(idToNumber)
    this.setCurrentPath()
    this.isDataLoaded = true
  }
})
