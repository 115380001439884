























import Vue from 'vue'

import BackArrowComp from '@/components/utils/BackArrowComp.vue'
import ConstructionDocumentation from '@/components/Constructions/ConstructionDocumentation.vue'
import LoadingSpinner from '@/components/utils/Loading.vue'

export default Vue.extend({
  name: 'ConstructionMainFoldersManagment',
  components: {
    BackArrowComp,
    ConstructionDocumentation,
    LoadingSpinner
  },
  data () {
    return {
      idConstructionRootFolder: '' as string,
      isDataLoaded: false as boolean,
      isLoading: false as boolean
    }
  },

  beforeMount () {
    this.isLoading = true
    this.idConstructionRootFolder = this.$router.currentRoute.params.id
    this.isDataLoaded = true
    this.isLoading = false
  }
})
