import { NewContractSelection } from './types'

const state = {
  idCurrentConstruction: Number,
  idSelectedSupplier: 0 as number,
  objectCurrentConstruction: {},
  objectCurrentContract: {},
  recProvider: {},
  rec: {},
  newContractSelection: {} as NewContractSelection,
  supplierSearched: [],
  selectedSupplierObject: {},
  isShowingSigned: false,
  cameFromExtensions: false,
  selectedContractId: 0
}

const actions = {

  async resetRecProvider ({ commit }: any):Promise<void> {
    await commit('RESETING_THE_REC_PROVIDER')
  },

  async resetRec ({ commit }: any):Promise<void> {
    await commit('RESET_REC')
  },

  async resetIdSelectedProvider ({ commit }: any):Promise<void> {
    await commit('RESET_ID_SELECTED_SUPPLIER')
  },

  async resetSelectedSupplierObject ({ commit }:any):Promise<void> {
    await commit('RESET_SELECTED_SUPPLIER')
  },

  async resetSupplierSearched ({ commit }: any):Promise<void> {
    await commit('RESET_SUPLIER_SEARCHED')
  },

  async setIdCurrentConstruction ({ commit }:any, payload: number):Promise<void> {
    await commit('SET_ID_CURRENT_CONSTRUCTION', payload)
  },

  async setNewContractSelection ({ commit }:any, payload: any):Promise<void> {
    await commit('SET_NEW_CONTRACT_SELECTION', payload)
  },

  async setObjectCurrentConstruction ({ commit }:any, payload: any):Promise<void> {
    await commit('SET_OBJECT_CURRENT_CONSTRUCTION', payload)
  },

  async setObjectCurrentContract ({ commit }:any, payload: any):Promise<void> {
    await commit('SET_OBJECT_CURRENT_CONTRACT', payload)
  },

  async setSelectedSupplierObject ({ commit }: any, payload: any):Promise<void> {
    await commit('SETTING_SELECTED_SUPPLIER_OBJECT', payload)
  },

  async settingSupplierSearched ({ commit }: any, payload: any):Promise<void> {
    await commit('SETTING_SUPPLIER_SEARCHED', payload)
  },

  async setIdSelectedSupplier ({ commit }: any, idSupplier:number):Promise<void> {
    await commit('SET_ID_SELECTED_SUPPLIER', idSupplier)
  },

  async settingRec ({ commit }: any, payload: any):Promise<void> {
    await commit('SET_REC', payload)
  },

  async resetNewContractSelection ({ commit }:any):Promise<void> {
    await commit('RESET_NEW_CONTRACT_SELECTION')
  },

  async settingIsShowingSigned ({ commit }: any, payload: any):Promise<void> {
    await commit('SET_IS_SHOWING_SIGNED', payload)
  },

  async settingCameFromExtensions ({ commit }: any, payload: any):Promise<void> {
    await commit('SET_CAME_FROM_EXTENSIONS', payload)
  },

  async settingSelectedContractId ({ commit }: any, payload: any):Promise<void> {
    await commit('SET_LAST_CONTRACT_ID', payload)
  }
}

const mutations = {

  RESETING_THE_REC_PROVIDER (state:any):void {
    state.recProvider = {}
  },

  RESET_REC (state:any):void {
    state.rec = {}
  },

  RESET_ID_SELECTED_SUPPLIER (state: any):void {
    state.idSelectedSupplier = 0
  },

  RESET_SUPLIER_SEARCHED (state:any):void {
    state.supplierSearched = []
  },

  RESET_SELECTED_SUPPLIER (state: any):void {
    state.selectedSupplierObject = {}
  },

  SET_ID_CURRENT_CONSTRUCTION (state: any, payload: number):void {
    state.idCurrentConstruction = payload
  },

  SET_OBJECT_CURRENT_CONSTRUCTION (state: any, payload: any):void {
    state.objectCurrentConstruction = payload
  },

  SET_OBJECT_CURRENT_CONTRACT (state: any, payload: any):void {
    state.objectCurrentContract = payload
  },

  SETTING_SELECTED_SUPPLIER_OBJECT (state: any, payload: any):void {
    state.selectedSupplierObject = payload
  },

  SET_NEW_CONTRACT_SELECTION (state: any, payload: any):void {
    state.newContractSelection[payload.type] = payload.boolean
    // if (payload.type === 'iva') {
    //   state.newContractSelection.iva = payload.state
    // } else if (payload.type === 'retention') {
    //   state.newContractSelection.retention = payload.state
    // }
  },

  SETTING_SUPPLIER_SEARCHED (state: any, payload: any):void {
    state.supplierSearched = payload
  },

  SET_ID_SELECTED_SUPPLIER (state: any, idSupplier: number):void {
    state.idSelectedSupplier = idSupplier
  },

  SET_REC (state: any, payload: any):void {
    state.rec = payload
  },

  RESET_NEW_CONTRACT_SELECTION (state: any):void {
    state.newContractSelection = {}
  },

  SET_IS_SHOWING_SIGNED (state: any, value: any):void {
    state.isShowingSigned = value
  },

  SET_CAME_FROM_EXTENSIONS (state: any, value: any):void {
    state.cameFromExtensions = value
  },

  SET_LAST_CONTRACT_ID (state: any, value: any):void {
    state.selectedContractId = value
  }
}

export const contracts = {
  namespaced: true,
  actions,
  mutations,
  state
}
