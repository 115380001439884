











































































































import Vue from 'vue'
import { uploadConstructionFile } from '@/services/Documents'
import { createNamespacedHelpers } from 'vuex'
import { getConstructionSubfoldersByDefaultFolderId } from '@/services/Construction'

import LoadingSpinner from '@/components/utils/Loading.vue'

const constructionModule = createNamespacedHelpers('constructions')

export default Vue.extend({
  name: 'AddDocumentSubfolder',

  components: {
    LoadingSpinner
  },

  data () {
    return {
      dialog: false as boolean,
      formData: new FormData() as any,
      id: 333 as number,
      isLoading: false as boolean,
      file: {}
    }
  },
  computed: {
    ...constructionModule.mapState([
      'idCurrentSubfolder'
    ])
  },
  methods: {

    ...constructionModule.mapActions([
      'setObjectFilesToShow'
    ]),

    async inputFilehandleChange (e: any) {
      if (e.target.files[0]) {
        this.isLoading = true
        this.file = e.target.files[0]
        await this.uploadingFile(this.file)
        await this.refreshFileList()
        this.file = {}
        this.isLoading = false
      }
      this.dialog = false
    },

    async refreshFileList () {
      try {
        const response = await getConstructionSubfoldersByDefaultFolderId(this.idCurrentSubfolder)
        await this.setObjectFilesToShow(response.fileChildren)
      } catch (error) {
        console.error(error)
      }
    },

    async uploadingFile (file: any) {
      try {
        this.formData.append('file', file)
        this.formData.append('parentId', this.idCurrentSubfolder)
        this.formData.append('name', file.name)
        await uploadConstructionFile(this.formData)
      } catch (error) {
        console.dir(error)
      } finally {
        this.formData = new FormData()
        this.$emit('setReRender')
        this.dialog = false
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        document.getElementById('file').value = null
      }
    }
  }
})
