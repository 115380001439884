import { RouteConfig } from 'vue-router'
import NewExtension from '@/views/extensions/NewExtension.vue'
import { Roles } from '@/shared/enums/Roles'
import { GlobalPermissions } from '@/shared/types/Permission'
import { Sections } from '@/shared/enums/Sections'

const commonRoles = Object.values(Roles)

const meta = (rolId: number) => {
  return {
    requiresAuth: true,
    permissions: GlobalPermissions[Sections.EXTENSION][rolId]
  }
}

const routes: Array<RouteConfig> =
[
  {
    path: '/new-extension/:id',
    name: 'NewExtension',
    component: NewExtension,
    meta: {
      requiresAuth: true,
      requiredRoles: commonRoles.filter((rol: Roles) => [
        Roles.ASSISTAN_FOREMAN,
        Roles.CONSTRUCTION_MANAGER,
        Roles.SHOPPING
      ].includes(rol))
    }
  }
]

export default routes
