<style lang="scss" scoped>
  .pending-to-sign-contracts {
    list-style: none;
    padding: 0;
  }
</style>

<template lang="pug">
    .pending-contract-list
      ul.pending-to-sign-contracts
          li(v-for="contract of contracts")
            PendingContractItem(:contract="contract" @closePendingDialog="closePendingDialog")
</template>

<script>
import Vue from 'vue'

import PendingContractItem from '@/components/Contracts/pendingContracts/PendingContractItem.vue'

/*eslint-disable*/
export default Vue.extend({
  name: 'PendingContractsList',
  props: {
    contracts: {
        type: [],
        required: true
    }
  },

  components: {
    PendingContractItem
  },

  methods: {
    closePendingDialog () {
      this.$emit('closingDialog')
    }
  }

})
</script>