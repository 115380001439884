var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"filter-wrapper"},[_c('LoadingSpinner',{directives:[{name:"show",rawName:"v-show",value:(_vm.isLoading),expression:"isLoading"}]}),_c('v-menu',{staticStyle:{"boxShadow":"'unset'"},attrs:{"bottom":"","close-on-content-click":false,"nudge-width":_vm.nudgeMenuWidth},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',{staticClass:"buttons"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"fab":""},on:{"click":_vm.refreshInfo}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-refresh")])],1)]}}],null,true)},[_c('span',[_vm._v("Restaurar filtros")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"tertiary","fab":""},on:{"click":_vm.resetWhenOpen}},'v-btn',attrs,false),on),[_c('img',{attrs:{"loading":"lazy","src":require("@/assets/icons/filter.svg")}})])]}}],null,true)},[_c('span',[_vm._v("Filtrar")])])],1)]}}]),model:{value:(_vm.openMenu),callback:function ($$v) {_vm.openMenu=$$v},expression:"openMenu"}},[(_vm.openMenu)?_c('div',{staticClass:"main"},[_c('div',{staticClass:"main__close"},[_c('v-btn',{attrs:{"color":"secondary","fab":"","small":""},on:{"click":_vm.closeFilter}},[_c('v-icon',{attrs:{"color":"white"}},[_vm._v("mdi-close-box-outline")])],1)],1),_c('div',{staticClass:"main__title"},[_c('p',[_c('v-icon',{staticClass:"mr-4",attrs:{"color":"black"},on:{"click":_vm.arrowAction}},[_vm._v("mdi-arrow-left")]),_c('span',[_vm._v("Filtro por nombre")])],1)]),_c('div',{staticClass:"main__search-bar"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.textForSearch),expression:"textForSearch"}],staticClass:"search",attrs:{"autocomplete":"false","type":"text"},domProps:{"value":(_vm.textForSearch)},on:{"input":function($event){if($event.target.composing){ return; }_vm.textForSearch=$event.target.value}}})]),(_vm.isShowSearchButton)?_c('div',{staticClass:"see-filter-button"},[_c('v-btn',{staticClass:"see-button",attrs:{"color":"tertiary"},on:{"click":_vm.searching}},[_vm._v(_vm._s(_vm.$t('see'))),_c('img',{staticClass:"ml-1",attrs:{"src":require("@/assets/icons/double-arrow.svg")}})])],1):_vm._e()]):_vm._e()])],1)}
var staticRenderFns = []

export { render, staticRenderFns }