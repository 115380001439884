









































































































import Vue from 'vue'
import { createNamespacedHelpers } from 'vuex'
import ConfirmAction from '../Modals/ConfirmAction.vue'
import { RolNames } from '@/shared/enums/Roles'

export default Vue.extend({
  name: 'SignComponent',
  components: {
    ConfirmAction
  },
  props: {
    user: {
      type: Object,
      required: true
    },
    disableSign: {
      type: Boolean,
      required: true
    }
  },
  data () {
    return {
      menu: false as boolean,
      showConfirm: false as boolean,
      myUser: this.user
    }
  },
  computed: {
    currentUser () {
      return this.$cookies.get('user')
    },

    getIcon (): string {
      switch (this.myUser.rolName.toUpperCase()) {
        case RolNames.CONSTRUCTION_MANAGER.toUpperCase(): return 'mdi-account-hard-hat'
        case RolNames.GROUP_MANAGER.toUpperCase(): return 'mdi-account-group-outline'
        case RolNames.PRODUCTION_DIRECTOR.toUpperCase(): return 'mdi-account-tie-voice'
      }
      return ''
    },

    disableSignButton (): boolean {
      if (this.disableSign === true) {
        return true
      }
      if (this.myUser.user.id === this.currentUser.id) {
        return false
      }

      return true
    }
  },
  methods: {
    emitSign () {
      this.$emit('signed', this.myUser)
    },

    showConfirmAction () {
      this.showConfirm = true
    }
  },
  watch: {
    user: function (newVal, oldVal) {
      this.myUser.signed = newVal.signed
    }
  }
})
