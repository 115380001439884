




























import Vue from 'vue'
import { createNamespacedHelpers } from 'vuex'

import BackArrowComp from '@/components/utils/BackArrowComp.vue'
import ProfileForm from '@/components/User/ProfileForm.vue'

const globalModule = createNamespacedHelpers('global')

export default Vue.extend({
  name: 'MyProfile',
  components: {
    BackArrowComp,
    ProfileForm
  },
  // props: {
  //   id: {
  //     type: String,
  //     required: true
  //   }
  // },
  methods: {
    ...globalModule.mapActions(
      ['setCurrentPath']
    )
  },
  beforeMount () {
    this.setCurrentPath()
  }
})
