




















































































import Vue from 'vue'
import { createNamespacedHelpers } from 'vuex'
import ConstructionUserList from '@/components/Constructions/ConstructionUser/ConstructionUsersList.vue'

const constructionsModule = createNamespacedHelpers('constructions')

/*eslint-disable */
export default Vue.extend({
  name: 'ConstructionUserCard',
  props: {
    rol: String
  },
  computed: {
    ...constructionsModule.mapState([
      'rec',
      'constructionManager',
      'productionDirector',
      'groupManager'
    ]),
    getUser () {
      switch (this.rol) {
        case 'Jefe de Obra': {
          return this.constructionManager
        }

        case 'Director de producción': {
          return this.productionDirector
        }

        case 'Jefe de Grupo': {
          return this.groupManager
        }
      }
    }
  },
  provide () {
    return {
      constructionUserProps: this.$props
    }
  },
  components: {
    ConstructionUserList
  },
  methods: {
    ...constructionsModule.mapActions([
      'resetConstructionManager',
      'resetProductionDirector',
      'resetGroupManager'
    ]),
    clearUser () {
      switch (this.rol) {
        case 'Jefe de Obra': {
          this.resetConstructionManager()
        }; break

        case 'Director de producción': {
          this.resetProductionDirector()
        }; break

        case 'Jefe de Grupo': {
          this.resetGroupManager()
        }; break
      }
    }
  }
})
