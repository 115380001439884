
const state = {
  rec: {},
  categoriesGlobalList: [],
  subcategoriesGlobalList: []
}

const actions = {
  async setCategoriesGlobalList ({ commit }: any, payload: any):Promise<any> {
    commit('SETTING_CATEGORIES_LIST', payload)
  },

  async setSubategoriesGlobalList ({ commit }: any, payload: any):Promise<any> {
    commit('SETTING_SUBCATEGORIES_LIST', payload)
  },

  async resetCategoriesGlobalList ({ commit }: any):Promise<any> {
    commit('RESET_CATEGORIES_GLOBAL_LIST')
  },

  async resetSubcategoriesGlobalList ({ commit }: any):Promise<any> {
    commit('RESET_SUBCATEGORIES_GLOBAL_LIST')
  }
}

const mutations = {
  SETTING_CATEGORIES_LIST (state: any, payload: any):void {
    state.categoriesGlobalList = payload
  },

  SETTING_SUBCATEGORIES_LIST (state: any, payload: any):void {
    state.subcategoriesGlobalList = payload
  },

  RESET_CATEGORIES_GLOBAL_LIST (state: any):void {
    state.categoriesGlobalList = []
  },

  RESET_SUBCATEGORIES_GLOBAL_LIST (state: any):void {
    state.subcategoriesGlobalList = []
  }
}

export const categories = {
  namespaced: true,
  actions,
  mutations,
  state
}
