import Vue from 'vue'
import Vuex from 'vuex'
import { categories } from '@/store/categories-module'
import { contracts } from '@/store/contracts-module'
import { constructions } from '@/store/constructions-module'
import { extensions } from '@/store/extension-module'
import { global } from '@/store/global-module'
import { login } from '@/store/login-module'
import { proforma } from '@/store/proforma-module'
import { roles } from '@/store/roles-module'
import { suppliers } from '@/store/suppliers-module'
import { users } from '@/store/users-module'
import createPersistedState from 'vuex-persistedstate'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    categories,
    contracts,
    constructions,
    extensions,
    global,
    login,
    proforma,
    roles,
    suppliers,
    users
  },
  plugins: [createPersistedState()]
})
