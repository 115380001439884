const successConfig = {
  timeout: 2000,
  position: 'rightTop',
  titleMaxLength: 100
}

const errorConfig = {
  timeout: 10000,
  position: 'rightTop',
  titleMaxLength: 1000,
  bodyMaxLength: 1000
}

export default {
  install (Vue: any) {
    Vue.prototype.$showSuccess = function (title: string, body?: string) {
      this.$snotify.success(body, title, successConfig)
    }

    Vue.prototype.$showError = function (title: string, body?: string) {
      this.$snotify.error(body, title, errorConfig)
    }
  }
}
