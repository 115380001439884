












































import Vue from 'vue'

import DisplayTable from '@/components/table/DisplayTable.vue'
import FilterConstruction from '@/components/Dialog/FilterConstruction.vue'

export default Vue.extend({
  name: 'ConstructionDasboard',
  components: {
    DisplayTable,
    FilterConstruction
  },
  props: {
    objectToShow: {
      type: Object,
      required: true
    },
    constructionTypeList: {
      type: String,
      required: false
    },
    title: {
      type: String,
      required: true
    },
    permissions: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      columns: [
        'name',
        'date',
        'endDate',
        'meassure',
        'constructionKind'
      ],
      isDataLoaded: false as boolean
      // objectToShow: {}
    }
  },
  async beforeMount () {
    this.isDataLoaded = true
  }
})
