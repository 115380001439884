






















import Vue from 'vue'
import { createNamespacedHelpers } from 'vuex'
import { getConstructionFinishedOrOnWork } from '@/services/Construction'
import { findUsersByRoles, getUsersByPage } from '@/services/Users'
import { getSuppliersByPage, getSuppliersBySubcategories, getSupplierSearchByFields } from '@/services/Supplier'

import { PaginationObjectList } from '@/store/types/index'

const constructionsModule = createNamespacedHelpers('constructions')
const suppliersModule = createNamespacedHelpers('suppliers')
const userModule = createNamespacedHelpers('users')

export default Vue.extend({
  name: 'Pagination',
  props: {
    section: {
      type: String,
      required: true
    },
    completedObject: {
      type: Object as () => PaginationObjectList,
      required: true
    },
    constructionTypeList: {
      type: String,
      required: false
    }
  },
  computed: {

    ...constructionsModule.mapState([
      'constructionFinishedList',
      'constructionOnWorkList',
      'filter'
    ]),

    currentUser () {
      return this.$cookies.get('user')
    },

    ...suppliersModule.mapState([
      'arrayChoosenSubcategoriesIds',
      'supplierSearchFormObject'
    ]),

    ...userModule.mapState([
      'arrayChoosenRolesId'
    ])

  },
  methods: {

    ...constructionsModule.mapActions([
      'setConstructionFinishedList',
      'setConstructionOnWorkList'
    ]),

    ...suppliersModule.mapActions([
      'setSupplierList',
      'settingSupplierListByFields'
    ]),

    ...userModule.mapActions([
      'setUserList'
    ]),

    calculationCurrentPage () {
      const objectCopy = { ...this.completedObject }
      const currentPage = objectCopy.currentPage + 1
      return currentPage
    },

    async nextPage () {
      // eslint-disable-next-line no-unused-expressions
      this.completedObject.currentPage === this.completedObject.lastPage ? null : this.nextPageCheckSectionToCall()
    },

    nextPageCheckSectionToCall () {
      switch (this.completedObject.calledFrom) {
        case 'ConstructionService-searchConstruction':
          this.constructionNextPage()
          break
        case 'ProviderService-findAll':
          this.supplierAllNextPage()
          break
        case 'ProviderService-searchProvider':
          this.supplierByFieldsNextPage()
          break
        case 'ProviderService-findBySubcategories':
          this.supplierCategoriesNextPage()
          break
        case 'UserService-findAll':
          this.userAllNextPage()
          break
        case 'UserService-findByRols':
          this.userRolesNextPage()
          break
      }
    },

    async previousPage () {
      // eslint-disable-next-line no-unused-expressions
      this.completedObject.currentPage === 0 ? null : this.previousPageCheckSectionToCall()
    },

    previousPageCheckSectionToCall () {
      switch (this.completedObject.calledFrom) {
        case 'ConstructionService-searchConstruction':
          this.constructionPreviousPage()
          break
        case 'UserService-findAll':
          this.userAllPreviousPage()
          break
        case 'UserService-findByRols':
          this.userRolesPreviousPage()
          break
        case 'ProviderService-findAll':
          this.supplierAllPreviuosPage()
          break
        case 'ProviderService-searchProvider':
          this.supplierByFieldsPreviousPage()
          break
        case 'ProviderService-findBySubcategories':
          this.supplierCategoriesPreviousPage()
          break
      }
    },

    // CONSTRUCTION

    constructionNextPage () {
      if (this.constructionTypeList === 'onWork') {
        this.constructionOnWorkNextPage()
      }
      if (this.constructionTypeList === 'finished') {
        this.constructionFinishedNextPage()
      }
    },

    constructionPreviousPage () {
      if (this.constructionTypeList === 'onWork') {
        this.constructionOnWorkPreviousPage()
      }
      if (this.constructionTypeList === 'finished') {
        this.constructionFinishedPreviousPage()
      }
    },

    async constructionFinishedNextPage () {
      try {
        const response = await getConstructionFinishedOrOnWork({ finished: true, groupOf: this.currentUser.id, name: this.completedObject.filter.name }, this.completedObject.currentPage += 1)
        this.constructionFinishedList(response)
      } catch (error) {
        console.dir(error)
      }
    },
    async constructionFinishedPreviousPage () {
      try {
        const response = await getConstructionFinishedOrOnWork({ finished: true, groupOf: this.currentUser.id, name: this.completedObject.filter.name }, this.completedObject.currentPage -= 1)
        this.constructionFinishedList(response)
      } catch (error) {
        console.dir(error)
      }
    },

    async constructionOnWorkNextPage () {
      try {
        let response
        if (this.filter !== '') {
          response = await getConstructionFinishedOrOnWork({ finished: false, name: this.filter }, this.completedObject.currentPage += 1)
        } else {
          response = await getConstructionFinishedOrOnWork({ finished: false, groupOf: this.currentUser.id }, this.completedObject.currentPage += 1)
        }
        this.setConstructionOnWorkList(response)
      } catch (error) {
        console.dir(error)
      }
    },

    async constructionOnWorkPreviousPage () {
      try {
        const response = await getConstructionFinishedOrOnWork({ finished: false, groupOf: this.currentUser.id, name: this.completedObject.filter.name }, this.completedObject.currentPage -= 1)
        this.setConstructionOnWorkList(response)
      } catch (error) {
        console.dir(error)
      }
    },

    // CONSTRUCTION-FILTERED

    // SUPPLIERS ALL

    async supplierAllNextPage () {
      try {
        const response = await getSuppliersByPage(true, this.completedObject.currentPage += 1)
        await this.setSupplierList(response)
      } catch (error) {
        console.dir(error)
      }
    },
    async supplierAllPreviuosPage () {
      try {
        const response = await getSuppliersByPage(true, this.completedObject.currentPage -= 1)
        await this.setSupplierList(response)
      } catch (error) {
        console.dir(error)
      }
    },

    // USERS ALL

    async userAllNextPage () {
      try {
        const response = await getUsersByPage(this.completedObject.currentPage += 1)
        await this.setUserList(response)
      } catch (error) {
        console.dir(error)
      }
    },

    async userAllPreviousPage () {
      try {
        const response = await getUsersByPage(this.completedObject.currentPage -= 1)
        await this.setUserList(response)
      } catch (error) {
        console.dir(error)
      }
    },

    // SUPPLIERS CATEGORIES

    async supplierCategoriesNextPage () {
      const payload = { subcategoryIds: this.arrayChoosenSubcategoriesIds }
      try {
        const response = await getSuppliersBySubcategories(payload, this.completedObject.currentPage += 1)
        if (response.content.length >= 1) {
          const suppliersList = [] as any
          response.content.map((el:any) => suppliersList.push(el.provider))
          response.content = suppliersList
          this.setSupplierList(response)
        }
        await this.setSupplierList(response)
      } catch (error) {
        console.dir(error)
      }
    },

    async supplierCategoriesPreviousPage () {
      const payload = { subcategoryIds: this.arrayChoosenSubcategoriesIds }
      try {
        const response = await getSuppliersBySubcategories(payload, this.completedObject.currentPage -= 1)
        if (response.content.length >= 1) {
          const suppliersList = [] as any
          response.content.map((el:any) => suppliersList.push(el.provider))
          response.content = suppliersList
          this.setSupplierList(response)
        }
        await this.setSupplierList(response)
      } catch (error) {
        console.dir(error)
      }
    },

    // USERS BY ROLES

    async userRolesNextPage () {
      try {
        const arrUserIds = this.arrayChoosenRolesId.length >= 1 ? { rolsIds: this.arrayChoosenRolesId } : []
        const response = await findUsersByRoles(arrUserIds, this.completedObject.currentPage += 1)
        await this.setUserList(response)
      } catch (error) {
        console.dir(error)
      }
    },
    async userRolesPreviousPage () {
      try {
        const arrUserIds = this.arrayChoosenRolesId.length >= 1 ? { rolsIds: this.arrayChoosenRolesId } : []
        const response = await findUsersByRoles(arrUserIds, this.completedObject.currentPage -= 1)
        await this.setUserList(response)
      } catch (error) {
        console.dir(error)
      }
    },

    // SUPPLIERS SEARCH BY FIELDS

    async supplierByFieldsNextPage () {
      try {
        const response = await getSupplierSearchByFields(this.supplierSearchFormObject, this.completedObject.currentPage += 1)
        this.settingSupplierListByFields(response)
      } catch (error) {
        console.dir(error)
      }
    },

    async supplierByFieldsPreviousPage () {
      try {
        const response = await getSupplierSearchByFields(this.supplierSearchFormObject, this.completedObject.currentPage -= 1)
        this.settingSupplierListByFields(response)
      } catch (error) {
        console.dir(error)
      }
    }

  }
})
