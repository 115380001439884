






















import Vue from 'vue'
export default Vue.extend({
  name: 'FolderIcon',
  props: {
    commingFrom: {
      type: String,
      required: false
    },
    id: {
      type: Number,
      required: false
    },
    goTo: {
      type: String,
      required: true
    },
    constructionId: {
      type: String,
      required: false
    }
  },
  data () {
    return {
      tooltipText: ''
    }
  },
  methods: {
    goingTo () {
      if (this.commingFrom) {
        this.$router.push({ name: this.goTo, params: { idFolder: `${this.id}`, commingFrom: this.commingFrom, idConstruction: `${this.constructionId}` } })
      } else {
        this.$router.push({ name: this.goTo, params: { id: `${this.id}` } })
      }
    },
    setTooltipText () {
      console.log(48484, this.$route.name)
      if (this.$route.name === 'ConstructionNew' || this.$route.name === 'ConstructionMainFoldersManagment') {
        this.tooltipText = 'Subcarpetas'
      } else if (this.$route.name === 'ListaProveedores') {
        this.tooltipText = 'Seleccionar'
      } else if (this.$route.name === 'Constructions') {
        this.tooltipText = 'Documentos'
      } else {
        this.tooltipText = 'Documentosss'
      }
    }
  },
  created () {
    this.setTooltipText()
  }
})
