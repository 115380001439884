





























































































import Vue from 'vue'
import { createNamespacedHelpers } from 'vuex'
import { getConstructionById } from '@/services/Construction'
import ButtonNewElement from '@/components/utils/ButtonNewElement.vue'
import ContractsListDashboard from '@/components/Contracts/ContractsListDashboard.vue'
import LoadingSpinner from '@/components/utils/Loading.vue'
import { defaultPermissions } from '@/shared/types/Permission'
import { Sections } from '@/shared/enums/Sections'
import { PermissionsMixin } from '@/mixins/PermissionsMixin'

const contractModule = createNamespacedHelpers('contracts')

export default Vue.extend({
  name: 'ContractsListManagment',
  mixins: [
    PermissionsMixin
  ],
  components: {
    ButtonNewElement,
    ContractsListDashboard,
    LoadingSpinner
  },

  data () {
    return {
      buttonList: {
        pending: {
          href: '#tab-1',
          key: 'key-contracts-1',
          text: 'Pendientes de validar',
          value: 'tab-1',
          type: 'pending'
        },
        signed: {
          backText: 'contracts signed',
          href: '#tab-3',
          key: 'key-contracts-3',
          text: 'Firmados',
          value: 'tab-3',
          type: 'signed'
        }
      },
      goToNewText: 'NewContract' as string,
      isShowingPending: true as boolean,
      showingSigned: false as boolean,
      idConstruction: '' as string,
      isDataLoaded: false as boolean,
      isLoading: false as boolean,
      selectedTab: 'pending' as string,
      tabs: null,
      permissions: defaultPermissions
    }
  },

  computed: {
    ...contractModule.mapState([
      'objectCurrentContract',
      'isShowingSigned'
    ])
  },

  methods: {
    ...contractModule.mapActions([
      'setIdCurrentConstruction',
      'setObjectCurrentConstruction',
      'settingIsShowingSigned'
    ]),

    backContract (type: string) {
      this.hiddingOtherTab(type)
      this.isShowingPending = false
      this.isShowContractList = true
      this.checkPermissions()
    },

    async gettingCurrentConstructionData () {
      try {
        const response = await getConstructionById(this.$router.currentRoute.params.id)
        if (Object.entries(response).length >= 1) {
          this.setObjectCurrentConstruction(response)
        }
      } catch (error) {
        console.error(error)
      }
    },

    hiddingOtherTab (type: string) {
      this.isLoading = true
      this.selectedTab = type
      this.goToNewText = 'NewContract'
      if (type === 'pending') {
        this.isShowContractList = true
        this.isShowProformList = false
        this.isShowingPending = true
        this.showingSigned = false
      }
      if (type === 'signed') {
        this.isShowContractList = true
        this.isShowProformList = false
        this.isShowingPending = false
        this.showingSigned = true
      }
      this.isLoading = false
    },

    async firstThingToDo () {
      this.isLoading = true
      try {
        await this.setIdCurrentConstruction(this.$router.currentRoute.params.id)
        await this.gettingCurrentConstructionData()
      } catch (error) {
        console.error(error)
      } finally {
        this.isDataLoaded = true
        this.isLoading = false
      }
    },

    checkPermissions () {
      this.permissions = this.getPermissions(Sections.CONTRACTS)
    }
  },

  async beforeMount () {
    await this.firstThingToDo()
    this.checkPermissions()

    if (this.isShowingSigned) {
      this.showingSigned = true
      this.isShowingPending = false
      this.tabs = 1
    }
  },

  beforeDestroy () {
    this.settingIsShowingSigned(false)
  }
})
