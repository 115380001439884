import router from '@/router/index'

const state = {
  refreshPendingsAlerts: 0 as number,
  currentDate: '' as string,
  currentPath: '' as string,
  roles: [
    { role: 'Jefe de obra' },
    { role: 'Arquitecto' },
    { role: 'Ingeniero' },
    { role: 'Administración' }
  ],
  categories: [
    { category: 'Fontanería' },
    { category: 'Arquitectura' },
    { category: 'Ingeniería' },
    { category: 'Administración' }
  ],
  subcategories: [
    { subcategory: 'Gifería' },
    { subcategory: 'Baños' },
    { subcategory: 'Cocina' },
    { subcategory: 'Riegos' }
  ]
}

const actions = {

  setCurrentDate ({ commit }: any, date: string) {
    commit('SETTING_CURRENT_DATE', date)
  },

  setCurrentPath ({ commit }: any) {
    const path = router.currentRoute.path
    commit('SETTING_CURRENT_PATH', path)
  },

  setRefreshPendingAlerts ({ commit }: any) {
    commit('SET_REFRESH_PEDING_ALERTS')
  }
}

const mutations = {

  SETTING_CURRENT_DATE (state: any, date: string) {
    state.currentDate = date
  },

  SETTING_CURRENT_PATH (state: any, path: string) {
    state.currentPath = path
  },

  SET_REFRESH_PEDING_ALERTS (state: any) {
    state.refreshPendingsAlerts += 1
  }

}

export const global = {
  namespaced: true,
  actions,
  mutations,
  state
}
