<style lang="scss" scoped>
  .contract-item {
    display: flex;
    margin: 10px;
    border-radius: 5px;
    box-shadow: 1px 1px 3px 1px #d3d3d3;
    padding: 10px 0px 10px 15px;
    flex-flow: row nowrap;
    align-items: center;
    min-height: 70px;
    max-height: 70px;
    z-index: 0;
    position: relative;

    .main-info {
      margin-right: 2rem;
      flex: 1;
      .construction-name {
        font-weight: 500;
        font-size: 1.3rem;
      }

      .provider-name {
        font-weight: 400;
        color: #999999;
      }
    }

    .contract-price {
      font-size: 1.4rem;
      font-weight: 500;
      color: #fb8c00;
      flex: 1;
      text-align: end;
      margin-right: 70px;
    }

    .date-info {
      text-align: center;

      .updated-at-header {
        font-weight: 500;
      }
    }

    .edit-contract-button {
      position: absolute;
      right: 20px;
      width: 35px;
      height: 35px;

      img {
        width: 18px;
      }

    }

  }
</style>

<template lang="pug">
   .contract-item
      .main-info
        p.construction-name {{ contract.construction.name }}
        p.provider-name {{ contract.provider.name }}
      .date-info
        p.updated-at-header {{ $t('updated at') }}
        p.updated-at {{ new Date(contract.createdAt).toLocaleString('es-ES') }}
      p.contract-price {{ contract.totalPrice.toFixed(2) + '€' }}
      v-btn(
          class="edit-contract-button"
          fab
          small
          color="secondary"
          @click="goToEditContract(contract.id)"
        )
        img(src="@/assets/icons/edit-pencil.svg")
</template>

<script>
import Vue from 'vue'
import { createNamespacedHelpers } from 'vuex'
const contractModule = createNamespacedHelpers('contracts')

/*eslint-disable*/
export default Vue.extend({
  name: 'PendingContractItem',
  props: {
    contract: {}
  },

  methods: {
    ...contractModule.mapActions([
      'setIdCurrentConstruction',
      'setObjectCurrentConstruction',
      'setObjectCurrentContract',
      'settingRec'
    ]),

    goToEditContract (idContract) {
      this.setIdCurrentConstruction(this.contract.construction.id)
      this.setObjectCurrentContract(this.contract)
      this.settingRec(this.contract)
      this.setObjectCurrentConstruction(this.contract.construction)
      this.$emit('closePendingDialog')
      this.$router.push(`/contract-signed-state/${idContract}`)
    },
  }
})
</script>