













































































import Vue from 'vue'
import { createNamespacedHelpers } from 'vuex'
import { getSupplierCategoryAndSubcategory } from '@/services/Supplier'
import { getContractById } from '@/services/Contracts'
import { getProformaById } from '@/services/Proforma'

import LoadingSpinner from '@/components/utils/Loading.vue'

const contractModule = createNamespacedHelpers('contracts')
const proformaModule = createNamespacedHelpers('proforma')

export default Vue.extend({
  name: 'ProformaContractDataHeader',
  props: {
    contractId: {
      type: String,
      required: true
    },
    proformaData: {
      type: Object,
      required: false,
      default: () => ({})
    }
  },

  components: {
    LoadingSpinner
  },

  data () {
    return {
      isDataLoaded: false as boolean,
      isLoading: false as boolean,
      category: '' as string,
      subcategory: '' as string,
      supplierCategory: [],
      contractData: {}
    }
  },

  computed: {
    ...contractModule.mapState([
      'objectCurrentContract',
      'objectCurrentConstruction'
    ]),

    ...proformaModule.mapState([
      'newProformaObject'
    ]),

    getProformaStatus () {
      return this.newProformaObject.signedByCM &&
        this.newProformaObject.signedByGM &&
        this.newProformaObject.signedByPD
        ? 'Validada'
        : 'Faltan firmas'
    }
  },

  methods: {
    async getSupplierCategory () {
      try {
        this.isLoading = true
        if (this.contractData.provider && this.contractData.provider.id) {
          const response = await getSupplierCategoryAndSubcategory(this.contractData.provider.id)
          this.supplierCategory = response[0]
          if (this.supplierCategory.category.name) {
            this.category = this.supplierCategory.category.name
          }
          if (this.supplierCategory.subcategory.name) {
            this.subcategory = this.supplierCategory.subcategory.name
          }
        }
      } catch (error) {
        console.error('Error en getSupplierCategory:', error)
      } finally {
        this.isDataLoaded = true
        this.isLoading = false
      }
    },

    async fetchData () {
      try {
        const contractIdToUse = this.$route.query.contractId || this.contractId
        this.isLoading = true
        this.isDataLoaded = false
        this.contractData = await getContractById(contractIdToUse)

        if (this.$router.currentRoute.params.id) {
          this.proformaData = await getProformaById(this.$router.currentRoute.params.id)
        }
        await this.getSupplierCategory()
      } catch (error) {
        console.error('Error en fetchData:', error)
      } finally {
        this.isDataLoaded = true
        this.isLoading = false
      }
    },

    checkForUpdates () {
      const contractIdFromUrl = this.$route.query.contractId || this.contractId
      if (this.lastContractId !== contractIdFromUrl || this.lastProformaId !== this.proformaData.id) {
        this.lastContractId = contractIdFromUrl
        this.lastProformaId = this.proformaData.id
        this.fetchData()
      }
    }
  },

  watch: {
    '$route.query.contractId': 'checkForUpdates',
    contractId: 'checkForUpdates',
    proformaData: 'checkForUpdates'
  },

  async beforeMount () {
    this.checkForUpdates()
  }
})
