
















































































































































































import Vue from 'vue'
import { sendLogin } from '@/services/Auth'
import RememberPassword from '@/components/Landing/RemeberPassword.vue'

export default Vue.extend({
  name: 'LoginForm',
  components: {
    RememberPassword
  },
  data () {
    return {
      authEmail: '' as string,
      authPassword: '' as string,
      passwordType: 'password' as string,
      iconShowPassword: 'mdi-eye-off' as string,
      showPasswordRecovery: false,
      classObject: {
        onlyButton: true,
        message: false
      },
      rules: {
        required: (value: any) => (value && value !== undefined) || 'Requerido'
      },
      valid: true as boolean
    }
  },
  methods: {
    goToRememberPassword () {
      this.showPasswordRecovery = true
    },

    changePasswordInputType () {
      console.log()
      if (this.passwordType === 'password') {
        this.passwordType = 'text'
        this.iconShowPassword = 'mdi-eye'
      } else {
        this.passwordType = 'password'
        this.iconShowPassword = 'mdi-eye-off'
      }
    },

    async login () {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      if (this.$refs.form.validate()) {
        const credentials = {
          email: this.authEmail,
          password: this.authPassword
        }
        try {
          const { data, status } = await sendLogin(credentials)
          if (status === 201) {
            if ('access_token' in data) {
              this.$cookies.set('user', data.user, { expires: '1d' })
              this.$cookies.set('token', data.access_token, { expires: '1d' })
              this.$router.push({ name: 'Home' })
              this.$showSuccess('Sesión iniciada correctamente')
            }
          }
        } catch (error: any) {
          console.error('Login failed:', error)
          this.$showError('Usuario o contraseña no válidos')
        }
      }
    },

    goToProviderLogin () {
      this.$router.push({ path: '/suppliers/login' })
    }
  }
})
