import { RouteConfig } from 'vue-router'
import ConstructionEdit from '@/views/constructions/ConstructionEdit.vue'
import ConstructionManagment from '@/views/constructions/ConstructionManagment.vue'
import ConstructionNew from '@/views/constructions/ConstructionNew.vue'
import ConstructionSubFolderManager from '@/views/constructions/ConstructionSubFolderManager.vue'
import ConstructionMainFoldersManagment from '@/views/constructions/ConstructionMainFoldersManagment.vue'
import { Roles } from '@/shared/enums/Roles'

const commonRoles = Object.values(Roles)

const routes: Array<RouteConfig> =
[
  {
    path: '/constructions',
    name: 'Constructions',
    component: ConstructionManagment,
    meta: {
      requiresAuth: true,
      requiredRoles: commonRoles
    }
  },
  {
    path: '/constructions/edit/:id',
    name: 'ConstructionEdit',
    component: ConstructionEdit,
    meta: {
      requiresAuth: true,
      requiredRoles: commonRoles
    }
  },
  {
    path: '/constructions/folders-managment/:id',
    name: 'ConstructionMainFoldersManagment',
    component: ConstructionMainFoldersManagment,
    meta: {
      requiresAuth: true,
      requiredRoles: commonRoles
    }
  },
  {
    path: '/constructions/new',
    name: 'ConstructionNew',
    component: ConstructionNew,
    meta: {
      requiresAuth: true,
      requiredRoles: commonRoles.filter((rol: Roles) => [
        Roles.GROUP_MANAGER,
        Roles.PRODUCTION_DIRECTOR,
        Roles.ADMIN,
        Roles.SHOPPING
      ].includes(rol))
    }
  },
  {
    path: '/constructions/documentation/subfolders/:idFolder/:commingFrom/:idConstruction',
    name: 'SubFolderDocumentation',
    component: ConstructionSubFolderManager,
    meta: {
      requiresAuth: true,
      requiredRoles: commonRoles
    }
  }
]

export default routes
