<style lang="scss" scoped>
  .proforma-item {
    display: flex;
    margin: 10px;
    border-radius: 5px;
    box-shadow: 1px 1px 3px 1px #d3d3d3;
    padding: 10px 0px 10px 15px;
    flex-flow: row nowrap;
    align-items: center;
    min-height: 70px;
    max-height: 70px;
    z-index: 0;
    position: relative;

    .main-info {
      margin-right: 2rem;
      flex: 1;
      .contract-provider-name {
        font-weight: 400;
        color: #999999;
      }

      .proforma-name {
        font-weight: 500;
        font-size: 1.3rem;
      }
    }

    .proforma-price {
      font-size: 1.4rem;
      font-weight: 500;
      color: #fb8c00;
      flex: 1;
      text-align: end;
      margin-right: 70px;
    }

    .date-info {
      text-align: center;

      .updated-at-header {
        font-weight: 500;
      }
    }

    .edit-proforma-button {
      position: absolute;
      right: 20px;
      width: 35px;
      height: 35px;

      img {
        width: 18px;
      }

    }

  }
</style>

<template lang="pug">
   .proforma-item
      .main-info
        p.proforma-name {{ $t(monthNames[new Date(proforma.createdAt).getMonth()]) + '-' + proforma.createdAt.substring(0,4) }}
        p.contract-provider-name {{ proforma.contract.provider.name }}
      .date-info
        p.updated-at-header {{ $t('updated at') }}
        p.updated-at {{ new Date(proforma.createdAt).toLocaleString('es-ES') }}
      p.proforma-price {{ getProformaTotal() + '€' }}
      v-btn(
          class="edit-proforma-button"
          fab
          small
          color="secondary"
          @click="goToEditproforma(proforma.id)"
        )
        img(src="@/assets/icons/edit-pencil.svg")
</template>

<script>
import Vue from 'vue'
import { createNamespacedHelpers } from 'vuex'

const contractModule = createNamespacedHelpers('contracts')

/*eslint-disable*/
export default Vue.extend({
  name: 'PendingProformaItem',
  props: {
    proforma: {}
  },

  data () {
    return {
      monthNames: [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December'
      ]
    }
  },

  methods: {
    ...contractModule.mapActions([
      'setObjectCurrentConstruction',
      'setObjectCurrentContract'
    ]),
    getProformaTotal () {
      let summatoryOrigin = 0
      let summatoryBeforeOrigin = 0

      for (const proformaMeasurement of this.proforma.proformaMeasurements) {
        if (proformaMeasurement.totalUnitsPaid > 0 && proformaMeasurement.measurement.unitPrice > 0) {
          summatoryOrigin += proformaMeasurement.measurement.unitPrice * proformaMeasurement.totalUnitsPaid
        }
      }

      if (this.proforma.lastProforma) {
        for (const lastProformaMeasurement of this.proforma.lastProforma.proformaMeasurements) {
          if (lastProformaMeasurement.totalUnitsPaid > 0 && lastProformaMeasurement.measurement.unitPrice > 0) {
            summatoryBeforeOrigin += lastProformaMeasurement.measurement.unitPrice * lastProformaMeasurement.totalUnitsPaid
          }
        }
      }

      const summatoryTotal = (summatoryOrigin - summatoryBeforeOrigin)
      const retention = summatoryTotal * (this.proforma.contract.retention / 100)
      const total = summatoryTotal - retention
      return total.toFixed(2)
    },

    goToEditproforma (idproforma) {
      this.setObjectCurrentContract(this.proforma.contract)
      this.setObjectCurrentConstruction(this.proforma.contract.construction)
      this.$emit('closePendingDialog')
      this.$router.push(`/edit-proforma/${idproforma}`)
    },
  }
})
</script>