
































import Vue from 'vue'
import LogoSupplierCard from '@/components/Suppliers/Logo/LogoSupplierCard.vue'

export default Vue.extend({
  name: 'UsualSuppliers',
  components: {
    LogoSupplierCard
  },
  data () {
    return {
    }
  }

})
