import { render, staticRenderFns } from "./NewProforma.vue?vue&type=template&id=66e5a609&scoped=true&lang=pug&"
import script from "./NewProforma.vue?vue&type=script&lang=ts&"
export * from "./NewProforma.vue?vue&type=script&lang=ts&"
import style0 from "./NewProforma.vue?vue&type=style&index=0&id=66e5a609&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "66e5a609",
  null
  
)

export default component.exports