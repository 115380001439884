<style lang="scss" scoped>
  .pending-to-sign-proformas {
    list-style: none;
    padding: 0;
  }
</style>

<template lang="pug">
    .pending-proform-list
      ul.pending-to-sign-proformas
          li(v-for="proforma of proformas")
            PendingProformaItem(:proforma="proforma" @closePendingDialog="closePendingDialog")
</template>

<script>
import Vue from 'vue'

import PendingProformaItem from '@/components/Proforma/pendingProforma/PendingProformaItem.vue'

/*eslint-disable*/
export default Vue.extend({
  name: 'PendingProformaList',
  props: {
    proformas: {
        type: [],
        required: true
    }
  },

  components: {
    PendingProformaItem
  },

  methods: {
    closePendingDialog () {
      this.$emit('closingDialog')
    }
  }

})
</script>