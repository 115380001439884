
























import Vue from 'vue'
import { createNamespacedHelpers } from 'vuex'

import BackArrowComp from '@/components/utils/BackArrowComp.vue'
import ButtonNewUser from '@/components/utils/ButtonNewUser.vue'
import LoadingSpinner from '@/components/utils/Loading.vue'
import UsersDashboard from '@/components/User/UsersDashboard.vue'
import { defaultPermissions } from '@/shared/types/Permission'
import { Sections } from '@/shared/enums/Sections'
import { PermissionsMixin } from '@/mixins/PermissionsMixin'

const globalModule = createNamespacedHelpers('global')

export default Vue.extend({
  name: 'UsersManagment',
  mixins: [
    PermissionsMixin
  ],
  components: {
    BackArrowComp,
    ButtonNewUser,
    LoadingSpinner,
    UsersDashboard
  },

  data () {
    return {
      permissions: defaultPermissions,
      isDataLoaded: false as boolean,
      isLoading: false as boolean
    }
  },

  computed: {
    currentUser () {
      return this.$cookies.get('user')
    }
  },

  methods: {

    ...globalModule.mapActions([
      'setCurrentPath'
    ]),

    checkPermissions () {
      this.permissions = this.getPermissions(Sections.USERS)
    }
  },

  async beforeMount () {
    this.checkPermissions()
    this.isLoading = true
    this.isDataLoaded = true
    this.setCurrentPath()
    this.isLoading = false
  }

})
