
























































































import Vue from 'vue'
import { createNamespacedHelpers } from 'vuex'

import { getSubcategoryByCategoryId } from '@/services/Category'
import { getSuppliersByPage } from '@/services/Supplier'

import LoadingSpinner from '@/components/utils/Loading.vue'
import NoResultMessageForList from '@/components/utils/NoResultMessageForList.vue'

const categoriesModule = createNamespacedHelpers('categories')
const contractModule = createNamespacedHelpers('contracts')

export default Vue.extend({
  name: 'ContractSelectingProvider',

  components: {
    LoadingSpinner,
    NoResultMessageForList
  },

  props: {
    isShowSupplierMessage: {
      type: Boolean,
      required: true
    }
  },

  data () {
    return {
      isDataLoaded: false as boolean,
      isLoading: false as boolean,
      subcategoriesList: [],
      providerList: []
    }
  },

  computed: {
    ...categoriesModule.mapState([
      'categoriesGlobalList',
      'subcategoriesGlobalList'
    ]),

    ...contractModule.mapState([
      'recProvider'
    ])
  },

  methods: {

    ...categoriesModule.mapActions([
      'setSubategoriesGlobalList',
      'resetSubcategoriesGlobalList'
    ]),

    async callingSupplierList () {
      try {
        const response = await getSuppliersByPage(false, -1)
        this.providerList = response.content
      } catch (error) {
        console.error(error)
      }
    },

    onProviderSelected (selectedProvider) {
      console.log(selectedProvider)
      if (selectedProvider) {
        this.$emit('selected', selectedProvider)
        console.log('Proveedor seleccionado:', selectedProvider)
      }
    },

    async callingSubcategories (value: number) {
      try {
        const response = await getSubcategoryByCategoryId(value)
        this.setSubategoriesGlobalList(response)
      } catch (error) {
        console.dir(error)
      }
    },

    async settingSubcategoriesList (idCategory: number) {
      this.isLoading = true
      if (idCategory) {
        await this.callingSubcategories(idCategory)
      } else {
        this.resetSubcategoriesGlobalList()
      }
      this.isLoading = false
    }

  },

  watch: {
    async 'recProvider.category' (value) {
      this.settingSubcategoriesList(value)
    }

  },

  async beforeMount () {
    this.isLoading = true
    await this.callingSupplierList()
    this.isLoading = false
    this.isDataLoaded = true
  }
})
