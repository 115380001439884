



































import Vue from 'vue'

export default Vue.extend({
  name: 'ProformaWrongData',
  data () {
    return {
      dialog: true as boolean
    }
  },
  methods: {

    sendEmit () {
      this.$emit('emitHideDialog')
    }

  }
})
