














































































































































































import Vue from 'vue'
import { createNamespacedHelpers } from 'vuex'
import { UserSign } from '@/shared/types/UserSigns'
import BackArrowComp from '@/components/utils/BackArrowComp.vue'
import LoadingSpinner from '@/components/utils/Loading.vue'
import SignComponent from '@/components/shared/SignComponent.vue'
import { findOne, updateSigns, downloadFile, remove, sendByEmail } from '@/services/Bills'
import pdf from 'vue-pdf-embed/dist/vue2-pdf-embed'
import ConfirmAction from '@/components/Modals/ConfirmAction.vue'
import { RolNames, Roles } from '@/shared/enums/Roles'
import { PermissionsMixin } from '@/mixins/PermissionsMixin'
import { defaultPermissions } from '@/shared/types/Permission'
import { Sections } from '@/shared/enums/Sections'
import SignsSection from '@/components/shared/SignsSection.vue'
import SendByEmail from '@/components/Modals/SendByEmail.vue'

const contractModule = createNamespacedHelpers('contracts')
const globalModule = createNamespacedHelpers('global')

export default Vue.extend({
  name: 'BillDetail',
  mixins: [
    PermissionsMixin
  ],
  components: {
    BackArrowComp,
    LoadingSpinner,
    pdf,
    SignComponent,
    ConfirmAction,
    SendByEmail,
    SignsSection
  },

  data () {
    return {
      permissions: defaultPermissions,
      dataObjectList: {
        construction: {
          isDisabled: false,
          rolName: 'Jefe de obra',
          signed: false,
          user: {
            name: ''
          }
        },
        group: {
          isDisabled: false,
          rolName: 'Jefe de grupo',
          signed: false,
          user: {
            name: ''
          }
        },
        production: {
          isDisabled: false,
          rolName: 'Director de producción',
          signed: false,
          user: {
            name: ''
          }
        }
      },
      contractId: 0,
      bill: Object,
      provider: Object,
      pdfFile: Object,
      disableDeleteBill: false,
      showConfirm: false,
      isLoading: false,
      constructionManagerSign: Object as () => UserSign,
      groupManagerSign: Object as () => UserSign,
      productionDirectorSign: Object as () => UserSign
    }
  },

  computed: {
    ...contractModule.mapState([
      'selectedContractId',
      'objectCurrentConstruction',
      'rec'
    ]),

    billTotal () {
      return `${parseFloat(this.bill.totalPrice).toFixed(2)} €`
    },

    actionButtonClass () {
      return this.bill.signedByCM
        ? 'bill-data_actions_with_signs'
        : 'bill-data_actions_without_signs'
    },

    disableDischardBill () {
      if (this.bill.signedByCM === false && this.currentRolIsAdministration) {
        return false
      } else {
        return true
      }
    },

    currentRolIsAdministration () {
      return this.$cookies.get('user').rol.id === Roles.ADMINISTRATION
    },

    disablePrintAndSendButton () {
      if (this.currentRolIsAdministration) {
        return false
      } else if (!this.bill.signedByCM && !this.bill.signedByGM) {
        return true
      } else {
        return false
      }
    }
  },

  methods: {
    ...globalModule.mapActions([
      'setRefreshPendingAlerts'
    ]),

    async getBillData () {
      try {
        this.isLoading = true
        const { data, status } = await findOne(this.$router.currentRoute.params.id)
        if (status === 200) {
          this.bill = data
          this.provider = this.bill.contract.provider
          this.settingSignsDataObjectList()
          this.settingUsersDataObjectList()
          this.initSignUsers()
          const pdfResponse = await downloadFile(this.bill.id)
          if (pdfResponse.status === 200) {
            this.pdfFile = URL.createObjectURL(pdfResponse.data)
          } else {
            throw Error('Error al descargar el archivo PDF')
          }
        } else {
          throw Error('Error al obtener los datos de la factura')
        }
      } catch (error) {
        this.$showError('Error obteniendo información')
        console.log(`Error getting bill data: ${error}`)
      }
      this.isLoading = false
    },

    settingSignsDataObjectList () {
      this.dataObjectList.construction.signed = this.bill.signedByCM
      this.dataObjectList.group.signed = this.bill.signedByGM
      this.dataObjectList.production.signed = this.bill.signedByPD
    },

    settingUsersDataObjectList (bill: any) {
      this.dataObjectList.construction.user = this.bill.constructionManager
      this.dataObjectList.group.user = this.bill.groupManager
      this.dataObjectList.production.user = this.bill.productionDirector
    },

    disableSign (rolName: any): boolean {
      switch (rolName.toUpperCase()) {
        case RolNames.GROUP_MANAGER.toUpperCase(): return !this.bill.signedByCM
        case RolNames.PRODUCTION_DIRECTOR.toUpperCase():
          return !this.bill.signedByCM && !this.bill.signedByGD
      }
      return false
    },

    async signBill (rolName: string) {
      try {
        const { data, status } = await updateSigns(this.bill.id, this.getSignPayload(rolName))
        if (status === 200) {
          this.bill = data
          this.updateDataObjectListSign(rolName)
          this.setRefreshPendingAlerts()
          this.$showSuccess('Firma realizada')
        }
      } catch (error) {
        console.log(error)
        this.$showError('Error firmando la factura')
      }
    },

    getSignPayload (rolName: string) {
      const payload = {
        signedByCM: this.bill.signedByCM,
        signedByGM: this.bill.signedByGM,
        signedByPD: this.bill.signedByPD
      }

      switch (rolName.toUpperCase()) {
        case RolNames.CONSTRUCTION_MANAGER.toUpperCase(): payload.signedByCM = true; break
        case RolNames.GROUP_MANAGER.toUpperCase(): payload.signedByGM = true; break
        case RolNames.PRODUCTION_DIRECTOR.toUpperCase(): payload.signedByPD = true; break
      }

      return payload
    },

    updateDataObjectListSign (rolName: string) {
      switch (rolName.toUpperCase()) {
        case RolNames.CONSTRUCTION_MANAGER.toUpperCase(): this.dataObjectList.construction.signed = true; break
        case RolNames.GROUP_MANAGER.toUpperCase(): this.dataObjectList.group.signed = true; break
        case RolNames.PRODUCTION_DIRECTOR.toUpperCase(): this.dataObjectList.production.signed = true; break
      }
    },

    formatDate (value: string) {
      return new Date(value).toLocaleDateString('es-ES')
    },

    async removeBill () {
      this.isLoading = true
      try {
        const { status } = await remove(this.bill.id)

        if (status === 200) {
          this.$router.push(`/contracts-list/${this.objectCurrentConstruction.id}`)
          this.setRefreshPendingAlerts()
          this.$showSuccess('Factura eliminada con éxito')
        } else {
          throw new Error()
        }
      } catch (error) {
        console.log(error)
        this.$showError(`Error eliminando la factura con ID: ${this.bill.id}`)
      }
      this.isLoading = false
    },

    async printBill () {
      try {
        const pdfWindow = window.open(this.pdfFile)
        pdfWindow.print()
      } catch (error) {
        console.error(error)
      }
    },

    async sendMail (to: any) {
      this.isLoading = true
      try {
        const { status } = await sendByEmail(this.sendByEmailPayload(to))
        if (status === 201) {
          this.$showSuccess('Email enviado', `Se ha enviado correctamente a ${to.to}`)
        } else {
          throw new Error()
        }
      } catch (error) {
        console.log(error)
        this.$showError(`Error enviando factura ${this.invoiceNumber}`)
      }
      this.isLoading = false
    },

    sendByEmailPayload (to: any) {
      return {
        to: to.to,
        subject: `Factura del proveedor ${this.provider.name}`,
        template: '' as string,
        fileName: this.bill.invoiceNumber,
        billId: this.bill.id
      }
    },

    initSignUsers () {
      this.constructionManagerSign = {
        signed: this.bill.signedByCM,
        signedDate: this.bill.signedByCMDate,
        user: this.bill.constructionManager
      }

      this.groupManagerSign = {
        signed: this.bill.signedByGM,
        signedDate: this.bill.signedByGMDate,
        user: this.bill.groupManager
      }

      this.productionDirectorSign = {
        signed: this.bill.signedByPD,
        signedDate: this.bill.signedByPDDate,
        user: this.bill.productionDirector
      }
    },

    checkPermissions () {
      this.permissions = this.getPermissions(Sections.BILLS)
    }
  },

  async beforeMount () {
    await this.getBillData()
  },

  async mounted () {
    this.checkPermissions()
  }

})
