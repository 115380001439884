
















































































































































































































import Vue from 'vue'
import { createNamespacedHelpers } from 'vuex'

import moment from 'moment'

import { editSuppierById, getSupplierById, getSupplierCategoryAndSubcategory, updateProfileImage } from '@/services/Supplier'
import { editSupplierSubcategory, getSubcategoryByCategoryId } from '@/services/Category'
import { Sections } from '@/shared/enums/Sections'
import { defaultPermissions } from '@/shared/types/Permission'
import { PermissionsMixin } from '@/mixins/PermissionsMixin'

const categoriesModule = createNamespacedHelpers('categories')
const globalModule = createNamespacedHelpers('global')
const supplierModule = createNamespacedHelpers('suppliers')

export default Vue.extend({
  name: 'EditSupplierForm',
  mixins: [
    PermissionsMixin
  ],
  props: {
    idSupplier: {
      type: Number,
      required: true
    }
  },

  data () {
    return {
      categoryIdLocal: 0 as number,
      isDataLoaded: false as boolean,
      rules: {
        required: (value: any) => (value && value !== undefined) || 'Requerido'
      },
      subcategoriesList: [],
      isEmailRegistered: false,
      emailRegisteredMessage: '',
      subcategoryIdLocal: 0 as number,
      supplierDateMenu: '',
      valid: false,
      permissions: defaultPermissions
    }
  },
  computed: {
    currentUser () {
      return this.$cookies.get('user')
    },

    ...categoriesModule.mapState(
      ['categoriesGlobalList']
    ),

    ...globalModule.mapState([
      'currentDate'
    ]),

    ...supplierModule.mapState(
      ['supplierObjectForEdit']
    ),

    ...supplierModule.mapState([
      'photoProfile'
    ])

  },

  methods: {
    ...supplierModule.mapActions([
      'resetRec',
      'resetSupplierList',
      'resetPhotoProfile',
      'resetPhotoProfileUrl',
      'setSupplierObjectForEdit'
    ]),

    async editSupplier () {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      this.resetErrorState()
      if (this.$refs.form.validate()) {
        try {
          await editSuppierById(this.idSupplier, this.supplierObjectForEdit)
          await this.updatePhotoProfile(this.idSupplier)
          await editSupplierSubcategory(this.idSupplier, this.subcategoryIdLocal)
          this.$router.push({ name: 'Suppliers' })
        } catch (error) {
          if (error.response && error.response.data) {
            if (error.response.data.message === 'El correo electrónico ya está registrado como usuario.') {
              this.isEmailRegistered = true
              this.emailRegisteredMessage = 'Correo ya registrado por un usuario'
            } else if (error.response.data.message.includes('duplicate key value violates unique constraint')) {
              this.isEmailRegistered = true
              this.emailRegisteredMessage = 'El correo electrónico ya está en uso.'
            }
          }
          console.dir(error)
        } finally {
          this.clearStates()
        }
      }
    },

    resetErrorState () {
      this.isEmailRegistered = false
      this.emailRegisteredMessage = ''
    },

    async updatePhotoProfile (id: any) {
      updateProfileImage(id, this.photoProfile)
    },

    async gettingCategoriesOfSupplier () {
      try {
        const response = await getSupplierCategoryAndSubcategory(this.idSupplier)
        this.categoryIdLocal = response[0].category.id
        this.subcategoryIdLocal = response[0].subcategory.id
      } catch (error) {
        console.dir(error)
      }
    },

    checkGetCategoryNeedValue (value: any) {
      if (typeof value === 'number') {
        return value
      } else {
        return value.id
      }
    },

    async settingSupplierData () {
      try {
        const response = await getSupplierById(this.idSupplier)
        this.setSupplierObjectForEdit(response)
      } catch (error) {
        console.dir(error)
      }
    },

    formatDate (value: string) {
      this.supplierDateMenu = moment(value).format('DD/MM/YYYY')
    },

    clearStates () {
      this.resetRec()
      this.resetPhotoProfile()
      this.resetPhotoProfileUrl()
    },

    checkPermissions () {
      this.permissions = this.getPermissions(Sections.PROVIDERS)
    }

  },

  watch: {
    async 'categoryIdLocal' (value) {
      try {
        const confirmValue = this.checkGetCategoryNeedValue(value)
        const response = await getSubcategoryByCategoryId(confirmValue)
        this.subcategoriesList = response
      } catch (error) {
        console.dir(error)
      }
    },

    'supplierObjectForEdit.entryDate' (value) {
      this.formatDate(value)
    }
  },

  async beforeMount () {
    await this.settingSupplierData()
    await this.gettingCategoriesOfSupplier()
    this.formatDate(this.supplierObjectForEdit.entryDate)
    this.checkPermissions()
    this.isDataLoaded = true
  },

  beforeDestroy () {
    this.resetSupplierList()
  }
})
