















































































































































import Vue from 'vue'
import { createNamespacedHelpers } from 'vuex'
import ConfirmAction from '@/components/Modals/ConfirmAction.vue'
import { getContractById, removeContract } from '@/services/Contracts'

const contractModule = createNamespacedHelpers('contracts')
const suppliersModule = createNamespacedHelpers('suppliers')
const extensionsAndProformsModule = createNamespacedHelpers('extensions')

export default Vue.extend({
  name: 'ContractLisTable',
  components: {
    ConfirmAction
  },
  props: {
    contractsList: {
      type: Array,
      required: true
    },
    type: {
      type: String,
      required: true
    },
    permissions: {
      type: Object,
      required: true
    }
  },

  data () {
    return {
      headers: [
        'name',
        'price',
        'observations'
      ],
      showConfirm: false,
      selectedContract: Object
    }
  },

  computed: {
    selectedProviderName () {
      return this.selectedContract?.provider?.name
    }
  },

  methods: {

    ...contractModule.mapActions([
      'setObjectCurrentContract',
      'settingCameFromExtensions',
      'settingSelectedContractId'
    ]),

    ...suppliersModule.mapActions([
      'setSelectedSupplier'
    ]),

    ...extensionsAndProformsModule.mapActions([
      'setIdGoingToExtension',
      'resersetIdGoingToExtension'
    ]),

    getHeaderClass (header: string) {
      return header === 'name'
        ? 'name'
        : header === 'price'
          ? 'small'
          : ''
    },

    async goToProformaAndExtensionsList (contract: any) {
      try {
        this.setSelectedSupplier(contract.provider)
        this.settingSelectedContractId(contract.id)
        this.$router.push({ path: `/contracts-list/related-docs/${contract.id}` })
      } catch (error) {
        console.error(error)
      }
    },

    goToEditCntract (idContract: number) {
      this.$router.push(`/edit-contract/${idContract}`)
    },

    async goToExtensionContract (idContract: number) {
      try {
        await this.resersetIdGoingToExtension()
        this.setIdGoingToExtension(idContract)
      } catch (error) {
        console.error(error)
      }
    },

    async showConfirmAction (contract: any) {
      this.selectedContract = contract
      this.showConfirm = true
    },

    async deleteContract () {
      try {
        const { status } = await removeContract(this.selectedContract.id)

        if (status === 200) {
          this.dialog = false
          this.$showSuccess('Contrato eliminado')
          this.reloadContracts()
        }
      } catch (error) {
        console.log(error)
        this.$showError('Error al eliminar el contrato')
      }
    },

    reloadContracts () {
      this.$emit('reloadContracts')
    },

    resetValues () {
      this.setSelectedSupplier(undefined)
      this.settingCameFromExtensions(false)
      this.settingSelectedContractId(0)
    }
  },

  mounted () {
    this.resetValues()
  }
})
