import __axios from '@/plugins/axios'
import Vue from 'vue'

function getHeaders () {
  const token = Vue.$cookies.get('token')
  return token ? { Authorization: `Bearer ${token}` } : {}
}

async function addSubcategoryToSupplier (idSubCat:number, idSupplier: number): Promise<any> {
  const response = __axios.get(`api/provider/${idSupplier}/add-subcategory/${idSubCat}`, { headers: getHeaders() })
  return response
}

async function banSupplier (supplierId: number): Promise<any> {
  await __axios.get(`api/provider/${supplierId}/block`, { headers: getHeaders() })
}

async function createSupplier (objSupplier: any): Promise<any> {
  try {
    const response = await __axios.post('api/provider', objSupplier, { headers: getHeaders() })

    return response.data
  } catch (error) {
    console.error('Error creating supplier:', error)

    throw error
  }
}

async function updateProfileImage (supplierId: any, file: File): Promise<any> {
  const formData = new FormData()
  formData.append('file', file)
  const response = await __axios.put(`api/provider/${supplierId}/profile-image`, formData, { headers: getHeaders() })
  return response.data
}

async function getProfileImage (supplierId: any) {
  const response = await __axios.get(`api/provider/${supplierId}/profile-image`, {
    responseType: 'blob',
    headers: getHeaders()
  })
  return response.data
}

async function deleteSupplierById (supplierId: number): Promise<any> {
  const response = await __axios.delete(`api/provider/${supplierId}`, { headers: getHeaders() })
  return response
}

async function deleteSubCategoryOfSupplier (supplierId: number, subCatId: number): Promise<any> {
  await __axios.delete(`api/provider/${supplierId}/delete-subcategory/${subCatId}`, { headers: getHeaders() })
}

async function editSuppierById (idSupplier: number, objectToEdit: any): Promise<any> {
  const response = await __axios.put(`api/provider/${idSupplier}`, objectToEdit, { headers: getHeaders() })
  return response.data
}

async function getSupplierSearchByFields (objSearch: any, page: number): Promise<any> {
  const response = await __axios.post(`api/provider/search/${page}`, objSearch, { headers: getHeaders() })
  return response.data
}

async function getSupplierById (supplierId: number): Promise<any> {
  const response = await __axios.get(`api/provider/${supplierId}`, { headers: getHeaders() })
  return response.data
}

async function getSuppliersByPage (bannedSuppliers: boolean, page?: number): Promise<any> {
  const pageToShow = page || 0
  const response = await __axios.post(`api/provider/page/${pageToShow}`, { bannedSuppliers: bannedSuppliers }, { headers: getHeaders() })

  return response.data
}

async function getSuppliersBySubcategories (idsArr: any, page?: number): Promise<any> {
  const pageToShow = page || 0
  const response = await __axios.post(`api/provider/find-by-subcategories/page/${pageToShow}`, idsArr, { headers: getHeaders() })
  return response.data
}

async function getSupplierCategoryAndSubcategory (idSupplier: number): Promise<any> {
  const response = await __axios.get(`api/provider/${idSupplier}/categories`, { headers: getHeaders() })
  return response.data
}

async function unlockSupplier (supplierId: number): Promise<any> {
  await __axios.get(`api/provider/${supplierId}/unlock`, { headers: getHeaders() })
}

export {
  addSubcategoryToSupplier,
  banSupplier,
  createSupplier,
  deleteSubCategoryOfSupplier,
  deleteSupplierById,
  editSuppierById,
  getSupplierSearchByFields,
  getSupplierById,
  getSuppliersByPage,
  getSuppliersBySubcategories,
  getSupplierCategoryAndSubcategory,
  unlockSupplier,
  updateProfileImage,
  getProfileImage
}
