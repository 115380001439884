import { render, staticRenderFns } from "./ConstructionEdit.vue?vue&type=template&id=272c0aea&scoped=true&lang=pug&"
import script from "./ConstructionEdit.vue?vue&type=script&lang=ts&"
export * from "./ConstructionEdit.vue?vue&type=script&lang=ts&"
import style0 from "./ConstructionEdit.vue?vue&type=style&index=0&id=272c0aea&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "272c0aea",
  null
  
)

export default component.exports