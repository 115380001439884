


















































import Vue from 'vue'
import { createNamespacedHelpers } from 'vuex'

import { banSupplier } from '@/services/Supplier'

const suppliersModule = createNamespacedHelpers('suppliers')

export default Vue.extend({
  name: 'BanSupplier',
  props: {
    idToBan: {
      type: Number,
      required: true
    },
    section: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      estoEsUnaPrueba: '2',
      dialog: false as boolean
    }
  },
  methods: {
    ...suppliersModule.mapActions([
      'refreshSupplierListByPage'
    ]),
    async bannningSupplier () {
      await banSupplier(this.idToBan)
      await this.refreshSupplierListByPage()
      this.dialog = false
    }
  }
})
