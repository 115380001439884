
























































































































import Vue from 'vue'
import { createNamespacedHelpers } from 'vuex'

import { getConstructionSubfoldersByDefaultFolderId } from '@/services/Construction'

import AddDocumentSubfolder from '@/components/Modals/AddDocumentSubfolder.vue'
import AddSubfolder from '@/components/Modals/AddSubfolder.vue'
import BackArrowComp from '@/components/utils/BackArrowComp.vue'
import DocumentationTable from '@/components/table/DocumentationTable.vue'
import LoadingSpinner from '@/components/utils/Loading.vue'

const constructionModule = createNamespacedHelpers('constructions')

export default Vue.extend({
  name: 'ConstructionSubFolderManager',
  components: {
    AddDocumentSubfolder,
    BackArrowComp,
    DocumentationTable,
    LoadingSpinner,
    AddSubfolder
  },
  data () {
    return {
      buttonList: {},
      secondaryFolderList: {},
      commingFrom: '' as string,
      headers: [
        'format',
        'fileName',
        'date',
        'observations'
      ],
      idConstruction: '' as string,
      idFolder: '' as string,
      isDataLoaded: false as boolean,
      isLoading: false as boolean,
      isThereSubfolder: false as boolean,
      isThereSecondaryfolder: false as boolean,
      secondaryFolderSelected: false as boolean,
      reRender: 0 as number,
      tabs: null,
      tabSec: undefined,
      text: '' as string
    }
  },
  methods: {

    // paracuadno no haya subcarpetas lo que hacemos es que hacemos un booleano
    // que oculte las carpetas y dejamos sólo la tabla
    // además igualamos el setIdCurrentSubFolder al id de la carpeta principal

    ...constructionModule.mapActions([
      'setIdCurrentSubFolder'
    ]),

    settingCurrentSubfolder (id: string, isSecondary: boolean) {
      this.secondaryFolderSelected = isSecondary
      this.setIdCurrentSubFolder(id)
      if (!isSecondary) {
        this.tabSec = undefined
        this.gettingSecondaryFolder(id)
      }
      this.setReRender()
    },

    chooseWhereBack () {
      if (this.commingFrom && this.commingFrom === 'newSection') {
        return '/constructions/new'
      }

      if (this.commingFrom && this.commingFrom === 'editSection') {
        return `/constructions/folders-managment/${this.idConstruction}`
      }
    },

    async getSubfolderByFolderId (response: any) {
      if (
        response.folderChildren &&
        response.folderChildren.length >= 1
      ) {
        this.isThereSubfolder = true
        this.buttonList = response.folderChildren
        this.setIdCurrentSubFolder(response.folderChildren[0].id)
        this.gettingSecondaryFolder(response.folderChildren[0].id)
      } else {
        this.setIdCurrentSubFolder(this.idFolder)
        this.buttonList = response.fileChildren
      }
    },

    async getSecondaryfolderByFolderId (response: any) {
      if (
        response.folderChildren &&
        response.folderChildren.length >= 1
      ) {
        // this.isThereSubfolder = true
        this.secondaryFolderList = response.folderChildren
        // this.setIdCurrentSubFolder(response.folderChildren[0].id)
        this.isThereSecondaryfolder = true
      } else {
        this.isThereSecondaryfolder = false
      }
    },

    gettingParamsFromURL () {
      this.idConstruction = this.$router.currentRoute.params.idConstruction
      this.idFolder = this.$router.currentRoute.params.idFolder
      this.commingFrom = this.$router.currentRoute.params.commingFrom
    },

    async gettingSubFolder () {
      try {
        const response = await getConstructionSubfoldersByDefaultFolderId(this.idFolder)
        this.text = `Documentación, ${response.name}`
        await this.getSubfolderByFolderId(response)
      } catch (error) {
        console.error(error)
      }
    },

    async gettingSecondaryFolder (id) {
      try {
        const response = await getConstructionSubfoldersByDefaultFolderId(id)
        // this.text = `Documentación, ${response.name}`
        await this.getSecondaryfolderByFolderId(response)
      } catch (error) {
        console.error(error)
      }
    },

    setReRender () {
      this.reRender += 1
    },

    async setComponent () {
      this.isLoading = true
      this.gettingParamsFromURL()
      await this.gettingSubFolder()
      this.isDataLoaded = true
      this.isLoading = false
    }

  },

  async beforeMount () {
    this.setComponent()
  }
})
