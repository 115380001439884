



































import Vue from 'vue'
import { createNamespacedHelpers } from 'vuex'

import BackArrowComp from '@/components/utils/BackArrowComp.vue'
import LoadingSpinner from '@/components/utils/Loading.vue'
import SearchSupplierDashboard from '@/components/Suppliers/SearchSupplierDashboard.vue'
import SupplierSearchForm from '@/components/Suppliers/SupplierSearchForm.vue'
import { defaultPermissions } from '@/shared/types/Permission'
import { Sections } from '@/shared/enums/Sections'
import { PermissionsMixin } from '@/mixins/PermissionsMixin'

const globalModule = createNamespacedHelpers('global')
const suppliersModule = createNamespacedHelpers('suppliers')

export default Vue.extend({
  name: 'SearchSupplier',
  mixins: [
    PermissionsMixin
  ],
  components: {
    BackArrowComp,
    LoadingSpinner,
    SearchSupplierDashboard,
    SupplierSearchForm
  },

  data () {
    return {
      isLoading: false as boolean,
      permissions: defaultPermissions
    }
  },

  computed: {
    ...suppliersModule.mapState(['supplierListSearchByField']),

    computedCheckSupplierListByField () {
      if (this.supplierListSearchByField && this.supplierListSearchByField.content) {
        return true
      } else return false
    }
  },
  methods: {
    ...globalModule.mapActions(
      ['setCurrentPath']
    ),

    checkPermissions () {
      this.permissions = this.getPermissions(Sections.PROVIDERS)
    }
  },
  beforeMount () {
    this.isLoading = true
    this.checkPermissions()
    this.setCurrentPath()
    this.isLoading = false
  }
})
