































































import Vue from 'vue'

import { DefaultFolders } from '@/store/types'

import { getConstructionSubfoldersByDefaultFolderId } from '@/services/Construction'

import FolderIcon from '@/components/Constructions/FolderIcon.vue'
import LoadingSpinner from '@/components/utils/Loading.vue'

export default Vue.extend({
  name: 'ConstructionDocumentation',
  components: {
    FolderIcon,
    LoadingSpinner
  },
  props: {
    id: {
      type: Number,
      required: true
    }
  },
  data () {
    return {
      arrDefaultFoldersList: [{ folders: [{} as DefaultFolders] }],
      isDataLoaded: false as boolean,
      isLoading: false as boolean
    }
  },
  methods: {
    async callDefaultFolders () {
      try {
        // const response = await getConstructionSubfoldersByDefaultFolderId(`${this.id}`)
        const response = await getConstructionSubfoldersByDefaultFolderId(`${this.id}`)
        this.arrDefaultFoldersList = response.folderChildren
      } catch (error) {
        console.dir(error)
      }
    }
  },

  async beforeMount () {
    try {
      this.isLoading = true
      await this.callDefaultFolders()
    } catch (error) {
      console.dir(error)
    } finally {
      this.isDataLoaded = true
      this.isLoading = false
    }
  }
})
