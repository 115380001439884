





















































































import Vue from 'vue'

export default Vue.extend({
  name: 'ConfirmAction',
  props: ['show', 'title', 'subtitle'],
  data () {
    return {
      showDialog: this.show ?? false
    }
  },
  methods: {
    emitClose () {
      this.$emit('close')
    },

    emitOk () {
      this.$emit('OK')
      this.$emit('close')
    }
  },
  watch: {
    show: function (newValue, oldValue) {
      this.showDialog = newValue
    }
  }
})
