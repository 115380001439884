import __axios from '@/plugins/axios'
import Vue from 'vue'

function getHeaders (): any {
  const token = Vue.$cookies.get('token')
  return token ? { Authorization: `Bearer ${token}` } : {}
}

async function editSupplierSubcategory (providerId: number, subcategoryId: number): Promise<any> {
  const response = await __axios.put(`api/provider/${providerId}/delete-all-and-add-subcategory/${subcategoryId}`, { headers: getHeaders() })
  return response.data
}

async function getAllCategoriesSupplier (): Promise<any> {
  const response = await __axios.get('api/category', { headers: getHeaders() })
  return response.data
}

async function getCategoryById (idCategory: number): Promise<any> {
  const response = await __axios.get(`api/category/${idCategory}`, { headers: getHeaders() })
  return response.data
}

async function getSubcategoryById (idSubcategory: number): Promise<any> {
  const response = await __axios.get(`api/subcategory/${idSubcategory}`, { headers: getHeaders() })
  return response.data
}

async function getSubcategoryByCategoryId (idCategory: number): Promise<any> {
  const response = await __axios.get(`api/category/${idCategory}/subcategories`, { headers: getHeaders() })
  return response.data
}

async function getSubcategoriesByIdArray (idsArray: any): Promise<any> {
  const response = await __axios.post('api/category/subcategories', idsArray, { headers: getHeaders() })
  return response.data
}

async function getAllSubcategories (): Promise<any> {
  const response = await __axios.get('api/subcategory', { headers: getHeaders() })
  return response.data
}

export {
  editSupplierSubcategory,
  getAllCategoriesSupplier,
  getCategoryById,
  getSubcategoryById,
  getSubcategoryByCategoryId,
  getSubcategoriesByIdArray,
  getAllSubcategories
}
