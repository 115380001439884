import __axios from '@/plugins/axios'
import Vue from 'vue'

function getHeaders () {
  const token = Vue.$cookies.get('token')
  return token ? { Authorization: `Bearer ${token}` } : {}
}

async function createConstruction (newConstructionObj: any): Promise<any> {
  const response = await __axios.post('api/construction', newConstructionObj, { headers: getHeaders() })
  return response.data
}

async function deleteConstructionById (idToDelete: number): Promise<any> {
  const response = await __axios.delete(`api/construction/${idToDelete}`, { headers: getHeaders() })
  return response
}

async function editConstructionById (idConstruction: string, objectForEdit: any): Promise<any> {
  const response = await __axios.put(`api/construction/${idConstruction}`, objectForEdit, { headers: getHeaders() })
  return response
}

async function getAllConstruction (page: number): Promise<any> {
  const response = await __axios.get(`api/construction/page/${page}`, { headers: getHeaders() })
  return response
}

async function getConstructionById (idConstruction: string): Promise<any> {
  const response = await __axios.get(`api/construction/${idConstruction}`, { headers: getHeaders() })
  return response.data
}

async function getConstructionFinishedOrOnWork (payload: any, page: number): Promise<any> {
  const response = await __axios.post(`api/construction/search/page/${page}`, payload, { headers: getHeaders() })
  return response.data
}

async function getConstructionSubfoldersByDefaultFolderId (idFolder: string): Promise<any> {
  const response = await __axios.get(`api/construction/folder/${idFolder}/content`, { headers: getHeaders() })
  return response.data
}

async function getPendingContractByConstructionId (id: string): Promise<any> {
  const response = await __axios.get(`api/construction/${id}/contracts/pending-sign`, { headers: getHeaders() })
  return response.data
}

async function getFinishedContractByConstructionId (id: string): Promise<any> {
  const response = await __axios.get(`api/construction/${id}/contracts/finished`, { headers: getHeaders() })
  return response.data
}

async function getConstructionAllDefaultFolders (): Promise<any> {
  const response = await __axios.get('api/construction/default-folder/all', { headers: getHeaders() })
  return response.data
}

async function getTypyeConstrunction (): Promise<any> {
  const response = await __axios.get('api/construction-type', { headers: getHeaders() })
  return response.data
}

async function numerationForNewConstruction (): Promise<any> {
  const response = await __axios.get('api/construction/number-construction/next', { headers: getHeaders() })
  return response.data
}

async function allocateUsersToConstruction (constructionId: number, userIds: unknown) {
  const response = await __axios.post(`api/construction/${constructionId}/allocate-users`, { userIds }, { headers: getHeaders() })
  return response
}

async function createSubfolder (subFolder: any) {
  const response = await __axios.post('api/construction/folder', subFolder, { headers: getHeaders() })
  return response
}

async function deallocateUsersFromConstruction (constructionId: number, userIds: unknown) {
  const response = await __axios.post(`api/construction/${constructionId}/deallocate-users`, { userIds }, { headers: getHeaders() })
  return response
}

async function getConstructionsBySupplier (supplierId: string): Promise<Record<any, any>> {
  const response = await __axios.get(`/api/construction/provider/${supplierId}`, { headers: getHeaders() })
  return response
}

export {
  createConstruction,
  deleteConstructionById,
  editConstructionById,
  getAllConstruction,
  getConstructionById,
  getPendingContractByConstructionId,
  getConstructionFinishedOrOnWork,
  getFinishedContractByConstructionId,
  getConstructionSubfoldersByDefaultFolderId,
  getConstructionAllDefaultFolders,
  getTypyeConstrunction,
  numerationForNewConstruction,
  allocateUsersToConstruction,
  deallocateUsersFromConstruction,
  createSubfolder,
  getConstructionsBySupplier
}
