





















import Vue from 'vue'
import { createNamespacedHelpers } from 'vuex'

import BackArrowComp from '@/components/utils/BackArrowComp.vue'
import ConstructionNewSectionWrapper from '@/components/Constructions/NewConstrucction/ConstructionNewWrapper.vue'

const globalModule = createNamespacedHelpers('global')

export default Vue.extend({
  name: 'ConstructionNew',
  components: {
    BackArrowComp,
    ConstructionNewSectionWrapper
  },
  methods: {
    ...globalModule.mapActions(
      ['setCurrentPath']
    )
  },
  async beforeMount () {
    this.setCurrentPath()
  }
})
