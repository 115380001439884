






































































import Vue from 'vue'
import { createNamespacedHelpers } from 'vuex'

import { getAllCategoriesSupplier } from '@/services/Category'
import { getAllRoles } from '@/services/Rol'
import SectionCard from '@/components/Home/SectionCard.vue'
import { PermissionsMixin } from '@/mixins/PermissionsMixin'
import { defaultPermissions } from '@/shared/types/Permission'
import { Sections } from '@/shared/enums/Sections'

const categoriesModule = createNamespacedHelpers('categories')
const globalModule = createNamespacedHelpers('global')
const rolesModule = createNamespacedHelpers('roles')

export default Vue.extend({
  name: 'Home',
  mixins: [
    PermissionsMixin
  ],
  components: {
    SectionCard
  },
  data () {
    return {
      isDataLoaded: false as boolean,
      statsPermissions: defaultPermissions
    }
  },
  methods: {
    ...globalModule.mapActions(
      ['setCurrentPath']
    ),

    ...categoriesModule.mapActions(
      ['setCategoriesGlobalList']
    ),

    ...rolesModule.mapActions([
      'setRolesGlobalList'
    ]),

    async callAllRoles () {
      try {
        const response = await getAllRoles()
        if (response && response.length > 0) {
          this.setRolesGlobalList([...response])
        }
      } catch (error) {
        console.dir(error)
      }
    },

    async settingSupplierCategoryList () {
      try {
        const response = await getAllCategoriesSupplier()
        this.setCategoriesGlobalList(response)
      } catch (error) {
        console.dir(error)
      }
    },

    checkPermissions () {
      this.statsPermissions = this.getPermissions(Sections.STATS)
    }
  },
  async beforeMount () {
    this.checkPermissions()
    this.setCurrentPath()
    await this.settingSupplierCategoryList()
    await this.callAllRoles()
    this.isDataLoaded = true
  }
})
