import __axios from '@/plugins/axios'

async function createNewPassword (token: string, newPassowrd: any): Promise<void> {
  await __axios.post(`api/auth/reset-password/${token}`, newPassowrd)
}

async function resetPassword (emailUser: any): Promise<any> {
  const response = await __axios.post('api/auth/reset-password', emailUser)
  return response
}

async function sendLogin (credentials: any): Promise<any> {
  const response = await __axios.post('api/auth/login', credentials)
  return response
}

async function sendLogout (): Promise<any> {
  const response = await __axios.get('api/auth/logout')
  return response.data
}

async function sendSupplierLogin (credentials: Record<string, string>): Promise<Record<any, any>> {
  const response = await __axios.post('api/auth/login-provider', credentials)
  return response
}

export {
  createNewPassword,
  resetPassword,
  sendLogin,
  sendLogout,
  sendSupplierLogin
}
