








































































































































































































































































































































































import Vue from 'vue'
import { createNamespacedHelpers } from 'vuex'

import BackArrowComp from '@/components/utils/BackArrowComp.vue'
import ContractSelectingProvider from '@/components/Contracts/ContractSelectingProvider.vue'
import ContractSelectedSupplierModule from '@/components/Contracts/selectionSupplier/ContractSelectedSupplierModule.vue'
import LoadingSpinner from '@/components/utils/Loading.vue'

import { editContract, getContractById, importExcel } from '@/services/Contracts'
import { defaultPermissions } from '@/shared/types/Permission'
import { Sections } from '@/shared/enums/Sections'
import { PermissionsMixin } from '@/mixins/PermissionsMixin'

const contractModule = createNamespacedHelpers('contracts')

export default Vue.extend({
  name: 'EditContract',
  mixins: [
    PermissionsMixin
  ],
  components: {
    BackArrowComp,
    ContractSelectingProvider,
    ContractSelectedSupplierModule,
    LoadingSpinner
  },

  data () {
    return {
      permissions: defaultPermissions,
      contractsType: [
        { type: 'Alquiler', id: 1 },
        { type: 'Suministro', id: 2 },
        { type: 'Renting', id: 3 },
        { type: 'Mano de obra', id: 4 },
        { type: 'Subcontrata', id: 5 }
      ],
      idContract: 0 as number,
      isLoading: false as boolean,
      showMessageAlert: false as boolean,
      isSelectRetentionDisabled: true as boolean,
      isSelectIvaDisabled: true as boolean,
      isShowRetentionYes: false as boolean,
      isShowRetentionNo: false as boolean,
      isShowIvaNo: false as boolean,
      isShowIvaYes: false as boolean,
      ivaType2: [
        { type: '4% Reducido', percent: 4 },
        { type: '10% Especial', percent: 10 },
        { type: '21% General', percent: 21 }
      ],
      fileArray: [],
      file: new FormData(),
      retentionsType: [5, 10, 20, 20, 40, 50, 60, 70, 80, 90, 100],
      rules: {
        required: (value: any) => (value && value !== undefined) || 'Requerido'
      },
      showModuleSelectSupplier: true,
      subcategoriesList: {},
      valid: false,
      isExtension: false,
      codigoContrato: ''
    }
  },

  computed: {

    ...contractModule.mapState([
      'idCurrentConstruction',
      'selectedContractId',
      'idSelectedSupplier',
      'rec',
      'recProvider',
      'cameFromExtensions'
    ]),

    goTo () {
      if (this.cameFromExtensions === true && this.selectedContractId !== 0) {
        return `/contracts-list/related-docs/${this.selectedContractId}`
      }

      return `/contracts-list/${this.idCurrentConstruction}`
    }
  },

  methods: {
    ...contractModule.mapActions([
      'resetRec',
      'resetRecProvider',
      'resetIdSelectedProvider',
      'resetSupplierSearched ',
      'resetSelectedSupplierObject',
      'setSelectedSupplierObject',
      'settingSupplierSearched',
      'settingRec',
      'setObjectCurrentContract'
    ]),

    checkData (data: any) {
      this.setIvaInfo(data)
      this.setRetentionInfo(data.retention)
    },

    setRetentionInfo (retention: any) {
      if (retention === 0) {
        this.selectingRetentions('no')
      } else {
        this.selectingRetentions('yes')
      }
    },

    setIvaInfo (data: any) {
      if (data.IVA) {
        this.selectingIva('yes')
      } else {
        this.selectingIva('no')
      }
    },

    async creatingContract () {
      if (!this.selectedSupplierObject.blocked) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        if (this.$refs.form.validate()) {
          try {
            this.isLoading = true
            const payload = {
              ...this.rec,
              provider: this.selectedSupplierObject.id,
              construction: Number(this.idCurrentConstruction),
              codigoContrato: this.rec.codigoContrato
            }
            const response = await editContract(this.idContract, payload)
            await this.importingFile()
            this.$router.push(`/contract-signed-state/${this.idContract}`)
          } catch (error) {
            console.error(error)
          } finally {
            this.isLoading = false
          }
        } else {
          this.$fire({
            title: 'Comprueba los campos obligatorios',
            type: 'warning',
            confirmButtonColor: '#F7A549'
          })
        }
      } else {
        this.$fire({
          title: 'Proveedor Bloqueado',
          text: `Todas las operaciones relacionadas con el proveedor ${this.selectedSupplierObject.name} están bloqueadas`,
          type: 'error',
          confirmButtonColor: '#F7A549'
        })
      }
    },

    async importingFile () {
      const formData = new FormData()
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      if (document.getElementById('file').value) {
        try {
          for (let i = 0; i < this.fileArray.length; i++) {
            formData.append('files', this.fileArray[i])
          }
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          await importExcel(this.$route.params.id, formData)
        } catch (error) {
          console.error(error)
        } finally {
          this.fileArray = []
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          document.getElementById('file').value = null
        }
      }
    },

    inputFilehandleChange (e: any) {
      if (e.target.files[0]) {
        this.isLoading = true
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        this.fileArray.push(e.target.files[0])
        this.isLoading = false
      }
    },

    resetNewcontractObjects () {
      this.resetRec()
      this.resetRecProvider()
      this.resetIdSelectedProvider()
      this.resetSelectedSupplierObject()
      this.showModuleSelectSupplier = true
    },

    setSelectedSupplier (supplier) {
      console.log(99, supplier)
      this.selectedSupplierObject = supplier
    },

    selectingRetentions (isRetention: string) {
      if (this.isExtension) {
        return
      }
      if (isRetention === 'yes') {
        this.isShowRetentionNo = false
        this.isShowRetentionYes = true
        this.isSelectRetentionDisabled = false
      }
      if (isRetention === 'no') {
        this.isSelectRetentionDisabled = true
        this.isShowRetentionYes = false
        this.isShowRetentionNo = true
        this.rec.retention = 0
      }
    },

    selectingIva (isIva: string) {
      if (this.isExtension) {
        return
      }
      if (isIva === 'yes') {
        this.isShowIvaNo = false
        this.isShowIvaYes = true
        this.isSelectIvaDisabled = false
      }
      if (isIva === 'no') {
        this.isShowIvaYes = false
        this.isShowIvaNo = true
        this.rec.IVA = 0
        this.isSelectIvaDisabled = true
      }
    },

    async gettingContractData () {
      try {
        this.isLoading = true
        const response = await getContractById(this.idContract)
        this.setObjectCurrentContract(response)
        this.settingRec(response)
        this.setSelectedSupplier(response.provider)
        this.checkData(response)
        this.isExtension = !!response.originalContract
        this.codigoContrato = response.codigoContrato || ''
      } catch (error) {
        console.error(error)
      } finally {
        this.isLoading = false
      }
    },

    checkPermissions () {
      this.permissions = this.getPermissions(Sections.CONTRACTS)
    },

    disableFields (): boolean {
      return this.rec.signedByPD || !this.permissions.edit
    }
  },

  beforeMount () {
    // this.checkSelectedSupplier()1
    this.idContract = Number(this.$router.currentRoute.params.id)
    this.gettingContractData()
    this.checkPermissions()
  }

})
