





































import Vue from 'vue'
import { createNamespacedHelpers } from 'vuex'

import BackArrowComp from '@/components/utils/BackArrowComp.vue'
import ButtonNewUser from '@/components/utils/ButtonNewUser.vue'
import LoadingSpinner from '@/components/utils/Loading.vue'
import SupplierDashboard from '@/components/Suppliers/SupplierDashboard.vue'
import UsualSuppliers from '@/components/Suppliers/UsualSuppliers.vue'
import { defaultPermissions } from '@/shared/types/Permission'
import { Sections } from '@/shared/enums/Sections'
import { PermissionsMixin } from '@/mixins/PermissionsMixin'

const globalModule = createNamespacedHelpers('global')

export default Vue.extend({
  name: 'UsersManagment',
  mixins: [
    PermissionsMixin
  ],
  components: {
    BackArrowComp,
    ButtonNewUser,
    LoadingSpinner,
    SupplierDashboard,
    UsualSuppliers
  },
  data () {
    return {
      isLoading: false as boolean,
      permissions: defaultPermissions
    }
  },

  computed: {
    currentUser () {
      return this.$cookies.get('user')
    }
  },

  methods: {
    ...globalModule.mapActions(
      ['setCurrentPath']
    ),

    checkPermissions () {
      this.permissions = this.getPermissions(Sections.PROVIDERS)
    }
  },
  beforeMount () {
    this.isLoading = true
    this.setCurrentPath()
    this.checkPermissions()
    this.isLoading = false
  }
})
