


































































































import Vue from 'vue'
import { createNamespacedHelpers } from 'vuex'

import { createConstruction, numerationForNewConstruction } from '@/services/Construction'

import ConstructionNewDocumentationSection from '@/components/Constructions/NewConstrucction/ConstructionNewDocumentationSection.vue'
import NewConstructionForm from '@/components/Constructions/NewConstrucction/ConstructionNewFormSection.vue'
import LoadingSpinner from '@/components/utils/Loading.vue'

const constructionsModule = createNamespacedHelpers('constructions')

export default Vue.extend({
  name: 'ConstructionNew',

  components: {
    ConstructionNewDocumentationSection,
    LoadingSpinner,
    NewConstructionForm
  },

  data () {
    return {
      bullet1: '#F7A549' as string,
      bullet2: '#000000' as string,
      constructionNumeration: '' as string,
      isLoading: false as boolean
    }
  },

  computed: {

    ...constructionsModule.mapState([
      'rec',
      'isShowDocumentationSectionGlobal',
      'constructionManager',
      'productionDirector',
      'groupManager'
    ]),

    currentUser () {
      return this.$cookies.get('user')
    },

    enableContinueButton () {
      return this.constructionManager == null || this.productionDirector == null || this.groupManager == null
    }
  },

  methods: {

    ...constructionsModule.mapActions([
      'setDefaultFolders',
      'setIsShowDocumentationSectionTrue',
      'resetConstructionManager',
      'resetProductionDirector',
      'resetGroupManager'
    ]),

    backToConstructions ():void {
      this.resetUsers()
      this.$router.push({ name: 'Constructions' })
    },

    async creatingNewConstruction () {
      if (Object.entries(this.rec).length >= 1 && this.rec.name.length >= 1) {
        try {
          this.rec.creator = this.currentUser.id
          const response = await createConstruction(this.rec)
          if (response && response.message && response.message.rootFolder.folderChildren) {
            await this.setDefaultFolders(response.message.rootFolder.folderChildren)
          }
          return true
        } catch (error) {
          console.dir(error)
          return false
        }
      } else {
        return false
      }
    },

    getUsersIds () {
      return [this.constructionManager.id, this.productionManager.id, this.groupLeader.id]
    },

    usersAreSelected () {
      return this.constructionManager != null &&
             this.productionDirector != null &&
             this.groupManager != null
    },

    resetUsers () {
      this.resetConstructionManager()
      this.resetGroupManager()
      this.resetProductionDirector()
    },

    async showingDocumentationSection () {
      if (this.usersAreSelected()) {
        try {
          this.isLoading = true
          const response = await this.creatingNewConstruction()
          if (response === true) {
            this.bullet1 = '#000000'
            this.bullet2 = '#F7A549'
            this.setIsShowDocumentationSectionTrue()
          }
        } catch (error) {
          console.dir(error)
        } finally {
          this.isLoading = false
        }
      }
    },

    classTitle1 ():string {
      if (this.isShowDocumentationSectionGlobal) {
        return 'sectionTitleBlack'
      } else return 'sectionTitleOrange'
    },

    classTitle2 ():string {
      if (this.isShowDocumentationSectionGlobal) {
        return 'sectionTitleOrange'
      } else return 'sectionTitleBlack'
    },

    async callingConstructionNumeration () {
      try {
        const response = await numerationForNewConstruction()
        this.constructionNumeration = response
      } catch (error) {
        console.error(error)
      }
    }

  },

  async beforeMount () {
    this.isLoading = true
    this.resetUsers()
    await this.callingConstructionNumeration()
    this.isLoading = false
  }
})
