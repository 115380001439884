import { RouteConfig } from 'vue-router'
import NewUser from '@/views/users/NewUser.vue'
import UsersManagment from '@/views/users/UsersManagment.vue'
import UserProfile from '@/views/users/UserProfile.vue'
import MyProfile from '@/views/users/MyProfile.vue'
import { Roles } from '@/shared/enums/Roles'

const commonRoles = [
  Roles.PRODUCTION_DIRECTOR,
  Roles.CONSULTANT,
  Roles.SHOPPING,
  Roles.ADMIN
]

const routes: Array<RouteConfig> =
[
  {
    path: '/users',
    name: 'Users',
    component: UsersManagment,
    meta: {
      requiresAuth: true,
      requiredRoles: commonRoles
    }
  },
  {
    path: '/users/new',
    name: '/users/NewUser',
    component: NewUser,
    meta: {
      requiresAuth: true,
      requiredRoles: commonRoles.filter(rol => rol === Roles.ADMIN)
    }
  },
  {
    path: '/users/profile/:id',
    name: 'UserProfile',
    component: UserProfile,
    meta: {
      requiresAuth: true,
      requiredRoles: commonRoles
    }
  },
  {
    path: '/users/myprofile/:id',
    name: 'MyProfile',
    component: MyProfile,
    meta: {
      requiresAuth: true,
      requiredRoles: []
    }
  }
]

export default routes
