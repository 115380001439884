












































































import Vue from 'vue'

export default Vue.extend({
  name: 'SendByEmail',
  props: {
    sendBill: {
      type: Boolean,
      required: true
    },
    disableButton: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data () {
    return {
      dialog: false as boolean,
      email: {
        to: '' as string,
        subject: '' as string,
        template: '' as string,
        fileName: ''as string,
        idProforma: 0 as number
      },
      rules: {
        required: (value: any) => (value && value !== undefined) || 'Requerido',
        emailFormat: (value: string) => {
          const pattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
          return pattern.test(value) || 'Formato de correo no válido'
        }
      },
      valid: false as boolean
    }
  },

  methods: {

    async sendProforma () {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      if (this.$refs.form.validate()) {
        this.$emit('emitSendEmail', this.email)
        this.dialog = false
        this.email.to = ''
      }
    },
    resetAndClose () {
      this.email.to = ''
      this.dialog = false
    }
  },
  computed: {
    isEmailValid () {
      return this.rules.required(this.email.to) === true && this.rules.emailFormat(this.email.to) === true
    }
  }

})
