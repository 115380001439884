var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"contract-list-table"},[_c('ConfirmAction',{attrs:{"show":_vm.showConfirm,"title":("Eliminar Contrato del proveedor " + _vm.selectedProviderName),"subtitle":'¿Estás seguro de que quieres elminar el contrato?, es una acción que no podrás deshacer'},on:{"close":function($event){_vm.showConfirm = false},"OK":_vm.deleteContract}}),_c('table',[_c('thead',[_c('tr',{staticStyle:{"borderBottom":"1px solid #B1B1B1"}},[_vm._l((_vm.headers),function(header,index){return _c('th',{key:header,staticClass:"table-header",class:_vm.getHeaderClass(header)},[_vm._v(_vm._s(_vm.$t(header)))])}),_c('th',{staticClass:"table-header"},[_vm._v("Acciones")])],2)]),_c('tbody',[_vm._l((_vm.contractsList),function(element,index){return [_c('tr',{key:index + '1',staticClass:"field-row"},[_c('td',{staticClass:"fields"},[_c('input',{staticClass:"field-input name",attrs:{"type":"text","disabled":""},domProps:{"value":element.provider.name}})]),_c('td',{staticClass:"fields"},[_c('input',{staticClass:"field-input small",attrs:{"type":"text","disabled":"","value":"3 €"},domProps:{"value":element.totalPrice.toFixed(2) + ' ' + '€'}})]),_c('td',{staticClass:"fields"},[_c('input',{staticClass:"field-input",attrs:{"type":"text","disabled":""},domProps:{"value":element.observations}})]),_c('td',{staticClass:"fields actions"},[_c('div',{staticClass:"icons-group"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(element.signedByCM && element.signedByGM && element.signedByPD)?_c('v-btn',_vm._g(_vm._b({attrs:{"fab":"","small":"","color":"secondary"},on:{"click":function($event){return _vm.goToProformaAndExtensionsList(element)}}},'v-btn',attrs,false),on),[_c('img',{attrs:{"src":require("@/assets/icons/paper.svg")}})]):_vm._e()]}}],null,true)},[_c('span',[_vm._v("Ver detalles")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"fab":"","small":"","color":"secondary"},on:{"click":function($event){return _vm.goToEditCntract(element.id)}}},'v-btn',attrs,false),on),[_c('img',{attrs:{"src":require("@/assets/icons/edit-pencil.svg")}})])]}}],null,true)},[_c('span',[_vm._v("Editar")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [((!element.signedByCM && !element.signedByGM && !element.signedByPD) && _vm.permissions.create)?_c('v-btn',_vm._g(_vm._b({attrs:{"fab":"","small":"","color":"secondary"},on:{"click":function($event){return _vm.showConfirmAction(element)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"white"}},[_vm._v("mdi-trash-can-outline")])],1):_vm._e()]}}],null,true)},[_c('span',[_vm._v("Eliminar")])])],1)])])]})],2)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }