











































































































































































































import Vue from 'vue'
import { createNamespacedHelpers } from 'vuex'

import moment from 'moment'
import pdf from 'vue-pdf-embed/dist/vue2-pdf-embed'

import { getContractPdfById, sendByEmailContract, editContract } from '@/services/Contracts'

import BackArrowComp from '@/components/utils/BackArrowComp.vue'
import ContractPrint from '@/components/Modals/ContractPrint.vue'
import LoadingSpinner from '@/components/utils/Loading.vue'
import SendByEmail from '@/components/Modals/SendByEmail.vue'
import SignComponent from '@/components/shared/SignComponent.vue'
import { RolNames } from '@/shared/enums/Roles'

const contractModule = createNamespacedHelpers('contracts')
const globalModule = createNamespacedHelpers('global')

export default Vue.extend({

  name: 'SignedStateManagment',

  components: {
    BackArrowComp,
    ContractPrint,
    LoadingSpinner,
    pdf,
    SendByEmail,
    SignComponent
  },

  data () {
    return {
      contractData: {
        date: '' as string,
        subsupplier: '' as string,
        contractNumber: '' as string,
        construction: '' as string,
        unitContract: '' as string,
        notes: '' as string
      },
      dataObjectList: {
        construction: {
          isDisabled: false,
          rolName: 'Jefe de obra',
          signed: false,
          id: 2,
          user: {
            name: ''
          }
        },
        group: {
          isDisabled: false,
          id: 1,
          rolName: 'Jefe de grupo',
          signed: false,
          user: {
            name: ''
          }
        },
        production: {
          id: 3,
          isDisabled: false,
          rolName: 'Director de producción',
          signed: false,
          user: {
            name: ''
          }
        }
      },
      date: ''as string,
      idContract: ''as string,
      isDataLoaded: false as boolean,
      isLoading: false as boolean,
      isShowActionButtons: false as boolean,
      filePdf: {},
      pdfNumPages: Number,
      valid: false as boolean
    }
  },

  computed: {

    ...contractModule.mapState([
      'objectCurrentContract',
      'objectCurrentConstruction',
      'rec'
    ]),

    ...globalModule.mapState([
      'currentDate'
    ]),

    currentUser () {
      return this.$cookies.get('user')
    },

    contractTypeText (): string {
      if (this.objectCurrentContract.originalContract) {
        return 'Ampliación'
      } else {
        return 'Contrato'
      }
    }

  },

  methods: {
    ...globalModule.mapActions([
      'setRefreshPendingAlerts'
    ]),

    checkCurrentStep () {
      if (
        this.rec.signedByCM === false &&
        this.currentUser.rol.id === 1
      ) {
        this.dataObjectList.construction.isDisabled = false
        this.dataObjectList.group.isDisabled = true
        this.dataObjectList.production.isDisabled = true
      } else if (
        this.rec.signedByCM === true &&
        this.rec.signedByGM === false &&
        this.currentUser.rol.id === 3
      ) {
        this.dataObjectList.construction.isDisabled = true
        this.dataObjectList.group.isDisabled = false
        this.dataObjectList.production.isDisabled = true
      } else if (
        this.rec.signedByCM === true &&
        this.rec.signedByGM === true &&
        this.rec.signedByPD === false &&
        this.currentUser.rol.id === 4
      ) {
        this.dataObjectList.construction.isDisabled = true
        this.dataObjectList.group.isDisabled = true
        this.dataObjectList.production.isDisabled = false
      } else {
        this.dataObjectList.construction.isDisabled = true
        this.dataObjectList.group.isDisabled = true
        this.dataObjectList.production.isDisabled = true
      }
    },

    checkSomeoneIsSigned () {
      if (
        this.rec.signedByCM &&
        this.rec.signedByGM &&
        this.rec.signedByPD
      ) {
        this.isShowActionButtons = true
        return null
      } else {
        this.checkCurrentStep()
      }
    },

    async emitSendEmail (emailObject: any) {
      try {
        this.isLoading = true
        const date = new Date()
        const month = date.toLocaleString('default', { month: 'long' })
        emailObject.fileName = `Contract ${month}`
        emailObject.idContract = this.idContract
        await sendByEmailContract(emailObject)
      } catch (error) {
        console.error(error)
      } finally {
        this.isLoading = false
      }
    },

    async firstThingToDo () {
      try {
        this.isLoading = true
        this.settingContractData()
        this.settingContractID()
        await this.gettingPdf()
        this.settingUsersDataObjectList()
        this.settingSignsDataObjectList()
        this.settingDisabledSignForNoAuthorizedUsers()
        this.settingDate()
        console.log('Datos del contrato:', this.objectCurrentContract)
      } catch (error) {
        console.error(error)
      } finally {
        this.isDataLoaded = true
        this.isLoading = false
      }
    },

    settingContractData () {
      this.contractData.unitContract = this.objectCurrentContract.unitContract ?? ''
      this.contractData.notes = this.objectCurrentContract.notes ?? ''
    },

    async gettingPdf () {
      try {
        const response = await getContractPdfById(this.idContract)
        this.filePdf = response
      } catch (error) {
        console.error(error)
      }
    },

    goingToExtension () {
      this.$router.push({ name: 'NewExtension' })
    },

    goingToProforma () {
      this.$router.push({ name: 'NewProforma' })
    },

    savingContract () {
      this.isLoading = true
      try {
        const iframe = `<iframe width='100%' height='100%' src='${this.filePdf}'></iframe> `

        const x = window.open()

        x.document.open()
        x.document.write(iframe)
        x.document.close()
      } catch (error) {
        console.error(error)
      }
      this.isLoading = false
    },

    settingSignsDataObjectList () {
      this.dataObjectList.construction.signed = this.rec.signedByCM
      this.dataObjectList.group.signed = this.rec.signedByGM
      this.dataObjectList.production.signed = this.rec.signedByPD
    },

    settingUsersDataObjectList () {
      this.dataObjectList.construction.user = this.rec.constructionManager
      this.dataObjectList.group.user = this.rec.groupManager
      this.dataObjectList.production.user = this.rec.productionDirector
    },

    settingContractID () {
      this.idContract = this.$router.currentRoute.params.id.toString()
    },

    settingDisabledSignForNoAuthorizedUsers () {
      if (this.currentUser.rol.id === 1 ||
          this.currentUser.rol.id === 3 ||
          this.currentUser.rol.id === 4
      ) {
        this.checkSomeoneIsSigned()
      } else {
        this.dataObjectList.construction.isDisabled = true
        this.dataObjectList.group.isDisabled = true
        this.dataObjectList.production.isDisabled = true
      }
    },

    settingDate () {
      this.date = moment(this.objectCurrentContract.createdAt).format('DD/MM/YYYY')
    },

    async signContract (user: any) {
      try {
        const response = await editContract(this.rec.id, this.updateSignPayload(user))

        if (response.statusCode && response.statusCode === 200) {
          this.updateDataObjectListSign(user)
          this.setRefreshPendingAlerts()
          this.$showSuccess('Firma realizada')
        }
      } catch (error) {
        console.log('error: ', error)
      }
    },

    updateSignPayload (user: any) {
      const payload = {
        signedByCM: this.rec.signedByCM,
        signedByGM: this.rec.signedByGM,
        signedByPD: this.rec.signedByPD
      }

      switch (user.rolName.toUpperCase()) {
        case RolNames.CONSTRUCTION_MANAGER.toUpperCase(): payload.signedByCM = true; break
        case RolNames.GROUP_MANAGER.toUpperCase(): payload.signedByGM = true; break
        case RolNames.PRODUCTION_DIRECTOR.toUpperCase(): payload.signedByPD = true; break
      }

      return payload
    },

    updateDataObjectListSign (user: any) {
      switch (user.rolName.toUpperCase()) {
        case RolNames.CONSTRUCTION_MANAGER.toUpperCase(): this.dataObjectList.construction.signed = true; break
        case RolNames.GROUP_MANAGER.toUpperCase(): this.dataObjectList.group.signed = true; break
        case RolNames.PRODUCTION_DIRECTOR.toUpperCase(): this.dataObjectList.production.signed = true; break
      }
    },

    disableSign (rolName: any): boolean {
      switch (rolName.toUpperCase()) {
        case RolNames.GROUP_MANAGER.toUpperCase(): return !this.rec.signedByCM
        case RolNames.PRODUCTION_DIRECTOR.toUpperCase():
          return !this.rec.signedByCM || !this.rec.signedByGM
      }
      return false
    }
  },

  beforeMount () {
    this.firstThingToDo()
  },

  watch: {
    '$route' (to, from) {
      this.firstThingToDo()
    }
  }

})
