<style lang="scss" scoped>
  @import '@/styles/mixins.scss';

  .user-item-info {
    display: flex;
    flex-flow: column nowrap;
    align-items: start;
    justify-content: start;
    .user-name, .user-surname {
      color: black;
      margin: 0 10px;
   }
   .user-name {
      font-weight: 800;
      font-size: 1.05rem;
   }
   .user-surname {
      font-size: 0.9rem;
   }
  }

  .delete-button {
    position: absolute;
    right: -10px;
    top:-10px;

    button {
      width: 24px;
      height: 24px;
    }
  }

</style>

<template lang="pug">
  section.user-item
    v-icon(color="#7c7c7c") mdi-account
    .user-item-info
      p.user-name {{ constructionManager.name }}
      p.user-surname {{ constructionManager.surnames }}
    .delete-button
      v-btn(:disabled="disabled" fab x-small color="#F7A549" @click="removeUser()")
        v-icon(x-small color="#fff") mdi-close
</template>

<script>
import Vue from 'vue'
import { createNamespacedHelpers } from 'vuex'

const usersModule = createNamespacedHelpers('users')

export default Vue.extend({
  name: 'ConstructionManagerItem',

  props: {
    constructionManager: {},
    disabled: {
      type: Boolean,
      required: false
    }
  },

  computed: {
    ...usersModule.mapState([
      'constructionManagers'
    ])
  },

  data () {
    return {
      dialog: false
    }
  },

  methods: {
    removeUser () {
      const cmCopy = this.constructionManagers

      for (let i = 0; i < cmCopy.length; i++) {
        const user = cmCopy[i]
        if (user.id === this.constructionManager.id) {
          this.constructionManagers.splice(i, 1)
        }
      }
    }
  }

})
</script>
