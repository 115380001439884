

























import Vue from 'vue'
import { createNamespacedHelpers } from 'vuex'

import BackArrowComp from '@/components/utils/BackArrowComp.vue'
import ProfileForm from '@/components/User/ProfileForm.vue'

const globalModule = createNamespacedHelpers('global')
const usersModule = createNamespacedHelpers('users')

export default Vue.extend({
  name: 'UserProfile',

  components: {
    BackArrowComp,
    ProfileForm
  },

  computed: {
    ...usersModule.mapState(
      ['userObjectToModify']
    )
  },

  methods: {
    ...globalModule.mapActions(
      ['setCurrentPath']
    )
  },
  beforeMount () {
    this.setCurrentPath()
  }
})
