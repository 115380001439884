import { RouteConfig } from 'vue-router'
import Stats from '@/views/stats/Stats.vue'
import { Roles } from '@/shared/enums/Roles'

const commonRoles = [
  Roles.PRODUCTION_DIRECTOR,
  Roles.CFO,
  Roles.CONSULTANT,
  Roles.ADMIN,
  Roles.SHOPPING
]

const routes: Array<RouteConfig> =
[
  {
    path: '/stats',
    name: 'Stats',
    component: Stats,
    meta: {
      requiresAuth: true,
      requiredRoles: commonRoles
    }
  }
]

export default routes
