





































import Vue from 'vue'
import LoadingSpinner from '@/components/utils/Loading.vue'
import ConstructionUserItem from '@/components/Constructions/ConstructionUser/ConstructionUserItem.vue'
import { findUsersByRol } from '@/services/Users'

/*eslint-disable */
export default Vue.extend({
  name: 'ConstructionUserList',
  props: {
    constructionUserProps: {
      type: Object,
      required: false
    }
  },
  components: {
    LoadingSpinner,
    ConstructionUserItem
  },
  inject: ['constructionUserProps'],
  computed: {
    getRolId () {
      switch (this.rol) {
        case 'Jefe de Obra': {
          return 1
        }

        case 'Director de producción': {
          return 4
        }

        case 'Jefe de Grupo': {
          return 3
        }
      }
    }
  },
  data () {
    return {
      dialog: false,
      isLoading: false,
      rol: this.constructionUserProps?.rol,
      items: []
    }
  },
  methods: {
    async getUsersByRol () {
      try{
        const response = await findUsersByRol(this.getRolId)
        this.items = response
      }catch(err) {
        console.log(err)
      }
    },
    childCloseDialog () {
      this.dialog = false
    }
  }
})
