










































import Vue from 'vue'

import { resetPassword } from '@/services/Auth'

export default Vue.extend({
  name: 'RemeberPassword',
  data () {
    return {
      emailUser: '' as string,
      rules: {
        required: (value: any) => (value && value !== undefined) || 'Requerido'
      },
      valid: true as boolean
    }
  },

  methods: {
    async sendResetPassword () {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      if (this.$refs.form.validate()) {
        try {
          await resetPassword({ email: this.emailUser })
          this.$router.push('/')
        } catch (error) {
          console.dir(error)
        }
      }
    }
  }
})
