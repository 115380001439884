














































import Vue from 'vue'
import VueI18n from 'vue-i18n'
import { createNamespacedHelpers } from 'vuex'

import LateralMenu from '@/components/Layout/LateralMenu.vue'
import NavBar from '@/components/Layout/NavBar.vue'

const globalModule = createNamespacedHelpers('global')

export default Vue.extend({
  name: 'App',
  components: {
    LateralMenu,
    NavBar
    // Landing
  },

  data: () => ({
    t: VueI18n
  }),
  computed: {
    isHome () {
      return this.$router.currentRoute.path === '/'
    },

    isLogin () {
      return this.$cookies.isKey('user') && this.$cookies.isKey('token')
    }
  },
  methods: {
    ...globalModule.mapActions([
      'setCurrentDate',
      'setCurrentPath'
    ])
  },

  async beforeMount () {
    const today = new Date().toISOString()
    await this.setCurrentPath()
    await this.setCurrentDate(today)
  }
})
