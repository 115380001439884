import { RouteConfig } from 'vue-router'
import EditProforma from '@/views/proforma/EditProforma.vue'
import NewProforma from '@/views/proforma/NewProforma.vue'
import { Roles } from '@/shared/enums/Roles'

const commonRoles = Object.values(Roles)

const routes: Array<RouteConfig> =
[
  {
    path: '/new-proforma',
    name: 'NewProforma',
    component: NewProforma,
    meta: {
      requiresAuth: true,
      requiredRoles: commonRoles.filter((rol: Roles) => [
        Roles.ASSISTAN_FOREMAN,
        Roles.CONSTRUCTION_MANAGER
      ].includes(rol))
    }
  },
  {
    path: '/edit-proforma/:id',
    name: 'EditProforma',
    component: EditProforma,
    meta: {
      requiresAuth: true,
      requiredRoles: commonRoles
    }
  }
]

export default routes
