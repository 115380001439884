<style lang="scss" scoped>
  @import '@/styles/mixins.scss';
  .v-dialog {
      position: relative !important;
  }
  .card-construction-managers {
    min-height: 150px;
    padding: 1rem;
    .cm-list {
      list-style: none;
      padding-left: 0 !important;
    }
  }

  .close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: 1;
  }

  .construction-managers-list {
    list-style: none;
  }

  .user-item-info {
    display: flex;
    flex-flow: column nowrap;
    align-items: start;
    justify-content: start;
    .user-name, .user-surname {
      color: black;
      margin: 0 10px;
   }
   .user-name {
      font-weight: 800;
      font-size: 1.25rem;
   }
   .user-surname {
      font-size: 1.15rem;
   }
  }

  .choose-button {
    position: absolute;
    right: 20px;
  }
</style>

<template lang="pug">
  .add-construction-managers
    v-dialog(v-model="dialog" width="777" content-class="elevation-0")
      template(v-slot:activator="{ on, attrs }")
        v-btn(:disabled="disabled" large color="#F7A549" v-bind="attrs" v-on="on")
          v-icon(x-large color="white") mdi-plus

      v-card.card-construction-managers.d-flex.flex-column.justify-space-between
        v-btn.close-button(color="secondary" fab x-small @click="dialog = false")
          v-icon(medium color="white") mdi-close

        ul.construction-managers-list
          li.construction-manager-item(v-for="user in this.constructionManagerList")
            section.user-item
              v-icon(class="user-item-icon" color="#7c7c7c" ) mdi-account
              .user-item-info
                p.user-name {{ user.name }}
                p.user-surname {{ user.surnames }}
              .choose-button
                v-btn(
                  @click="addUser(user)"
                )
                  v-icon(medium color="primary") mdi-account-plus

</template>

<script>
import Vue from 'vue'
import { createNamespacedHelpers } from 'vuex'
import { findUsersByRol } from '@/services/Users'

const usersModule = createNamespacedHelpers('users')

export default Vue.extend({
  name: 'AddConstructionManagers',

  props: {
    disabled: {
      type: Boolean,
      required: false
    }
  },

  data () {
    return {
      dialog: false,
      constructionManagerList: []
    }
  },

  computed: {
    ...usersModule.mapState([
      'constructionManagers'
    ])
  },

  methods: {
    async getConstructionManagers () {
      try {
        const users = await findUsersByRol(1)

        this.constructionManagerList.push(...users)
      } catch (error) {
        console.log(error)
      }
    },

    addUser (user) {
      if (!this.existsUser(user)) {
        this.constructionManagers.push({
          id: user.id,
          name: user.name,
          surnames: user.surnames
        })
      } else {
        this.$fire({
          title: `El usuario ${user.name} ya ha sido añadido`,
          type: 'warning',
          confirmButtonColor: '#F7A549'
        })
      }
    },

    existsUser (user) {
      for (const cm of this.constructionManagers) {
        if (cm.id === user.id) {
          return true
        }
      }

      return false
    }
  },

  beforeMount () {
    this.getConstructionManagers()
  }
})
</script>
