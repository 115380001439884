import __axios from '@/plugins/axios'
import Vue from 'vue'

function getHeaders () {
  const token = Vue.$cookies.get('token')
  return token ? { Authorization: `Bearer ${token}` } : {}
}

export const findRelatedDocumentsByContract = async (idContract: string) => {
  const response = await __axios.get(`api/contract/${idContract}/find-related-documents`, { headers: getHeaders() })
  return response
}

export const deleteExtension = async (idContract: string) => {
  const response = await __axios.delete(`api/contract/${idContract}`, { headers: getHeaders() })
  return response
}
