
































import { canCreateProforma } from '@/services/Proforma'
import Vue from 'vue'
import { createNamespacedHelpers } from 'vuex'

const contractModule = createNamespacedHelpers('contracts')
const suppliersModule = createNamespacedHelpers('suppliers')
const constructionsModule = createNamespacedHelpers('constructions')

export default Vue.extend({
  name: 'CreateButton',

  props: {

    text: {
      type: String,
      required: true
    },

    icon: {
      type: String,
      required: false
    },

    goTo: {
      type: String,
      required: true
    },

    id: {
      type: Number,
      required: false
    }

  },

  computed: {
    ...contractModule.mapState([
      'selectedContractId'
    ]),

    ...suppliersModule.mapState([
      'selectedSupplier'
    ])
  },

  methods: {
    ...constructionsModule.mapActions([
      'resetRec',
      'setIsShowDocumentationSectionFalse'
    ]),

    ...contractModule.mapActions([
      'settingSelectedContractId'
    ]),

    async goToNewPage () {
      let follow = true
      let message = ''
      if (this.goTo === 'NewProforma') {
        if (this.selectedSupplier.blocked) {
          this.$fire({
            title: 'Proveedor Bloqueado',
            text: `Todas las operaciones relacionadas con el proveedor ${this.selectedSupplier.name} están bloqueadas`,
            type: 'error',
            confirmButtonColor: '#F7A549'
          })
        } else {
          const response = await canCreateProforma(this.selectedContractId).then((res) => { return res.data })

          follow = response.canCreateNew
          message = response.message
        }
      }

      if (follow) {
        await this.setIsShowDocumentationSectionFalse()
        await this.resetRec()
        if (this.goTo === 'NewExtension') {
          this.settingSelectedContractId(this.id)
          setTimeout(() => {
            this.$router.push({ name: `${this.goTo}`, params: { id: `${this.id}` } })
          }, 300)
        } else {
          setTimeout(() => {
            this.$router.push({ name: `${this.goTo}` })
          }, 300)
        }
      } else {
        this.$fire({
          title: 'Error creando proforma',
          text: message,
          type: 'error',
          confirmButtonColor: '#F7A549'
        })
      }
    }
  }
})
