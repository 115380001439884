







































































































































import store from '@/store'
import { sendSupplierLogin } from '../../services/Auth'
import RememberPassword from '@/components/Landing/RemeberPassword.vue'

export default {
  name: 'SuppliersLoginForm',
  components: {
    RememberPassword
  },
  data: () => ({
    valid: false,
    cif: '',
    cifRules: [
      v => !!v || 'El CIF es necesario.',
      v => v.length !== 10 || 'El CIF debe tener 9 carácteres.'
    ],

    password: '',
    passwordRules: [
      v => !!v || 'Por favor, introduce contraseña'
    ],

    email: '',

    showPasswordRecovery: false,
    showPassword: false
  }),

  methods: {
    togglePasswordRecoveryForm (): void {
      this.showPasswordRecovery = !this.showPasswordRecovery
    },

    async loginSupplier (): Promise<void> {
      if (!this.cif || !this.password) return

      const credentials = { CIF: this.cif, password: this.password }

      try {
        const { data, status } = await sendSupplierLogin(credentials)

        if (status === 201) {
          store.dispatch('suppliers/updateSupplierId', data.provider.id)

          if ('access_token' in data) {
            this.$cookies.set('token', data.access_token, { expires: '1d' })
            this.goToUploadInvoiceForm()
          }
        }
      } catch (error) {
        this.$showError('No se pudo acceder', 'Por favor revisa las credenciales')
      }
    },

    goToUploadInvoiceForm (): void {
      this.$router.push('/suppliers/upload-invoice')
      this.$showSuccess('Sesión iniciada correctamente')
    }
  }
}

