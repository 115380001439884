




































import { getContractPdfById } from '@/services/Contracts'
import Vue from 'vue'

export default Vue.extend({

  name: 'ContractPrint',

  props: {
    idContract: {
      type: Number,
      required: true
    }
  },

  data () {
    return {
      dialog: false as boolean
    }
  },

  methods: {
    async printContract () {
      try {
        const response = await getContractPdfById(this.idContract.toString())

        const iframe = `<iframe width='100%' height='100%' src='${response}'></iframe> `

        const x = window.open()

        x.document.open()
        x.document.write(iframe)
        x.document.close()
      } catch (error) {
        console.error(error)
      } finally {
        this.dialog = false
      }
    }
  }

})
