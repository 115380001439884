import __axios from '@/plugins/axios'
import Vue from 'vue'

function getHeaders () {
  const token = Vue.$cookies.get('token')
  return token ? { Authorization: `Bearer ${token}` } : {}
}

async function getAllRoles () {
  const response = await __axios.get('api/rol', { headers: getHeaders() })
  return response.data
}

async function postRol () {
  const newRolName = {
    name: 'MArcle'
  }
  await __axios.post('api/rol', newRolName, { headers: getHeaders() })
}

export {
  getAllRoles,
  postRol
}
