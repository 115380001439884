



























































import Vue from 'vue'
import { createNamespacedHelpers } from 'vuex'

import { getUsersByPage } from '@/services/Users'
import DisplayTable from '@/components/table/DisplayTable.vue'
import FilterSupplier from '@/components/Dialog/FilterUsers.vue'
import LoadingSpinner from '@/components/utils/Loading.vue'
import { Permission, defaultPermissions } from '@/shared/types/Permission'
import { Sections } from '@/shared/enums/Sections'
import { PermissionsMixin } from '@/mixins/PermissionsMixin'

const userModule = createNamespacedHelpers('users')

export default Vue.extend({
  name: 'UsersDashboard',
  mixins: [
    PermissionsMixin
  ],
  components: {
    DisplayTable,
    FilterSupplier,
    LoadingSpinner
  },

  data () {
    return {
      permissions: defaultPermissions,
      isDataLoaded: false,
      isLoading: false,
      columns: [
        'name',
        'rol',
        'groupLeader'
      ],
      users: []
    }
  },
  computed: {
    ...userModule.mapState(['userList']),

    userListCompleteObject () {
      if (Object.entries(this.userList).length >= 1) {
        return this.userList
      } else return {}
    }
  },
  methods: {
    ...userModule.mapActions(['setUserList']),

    async callAllUser () {
      try {
        const response = await getUsersByPage(0)
        this.setUserList(response)
      } catch (error) {
        console.dir(error)
      }
    },

    initPermissions () {
      this.permissions = this.getPermissions(Sections.USERS)
    }
  },

  async beforeMount () {
    this.isLoading = true
    this.initPermissions()
    await this.callAllUser()
    this.isDataLoaded = true
    this.isLoading = false
  }
})
