











































































































































import Vue from 'vue'
import { createNamespacedHelpers } from 'vuex'
import ConfirmAction from '../Modals/ConfirmAction.vue'
import { sendLogout } from '@/services/Auth'
import { Sections } from '@/shared/enums/Sections'
import { PermissionsMixin } from '@/mixins/PermissionsMixin'
import { defaultPermissions } from '@/shared/types/Permission'

const globalModule = createNamespacedHelpers('global')
const loginModule = createNamespacedHelpers('login')

export default Vue.extend({
  name: 'LateralMenu',
  mixins: [
    PermissionsMixin
  ],
  components: {
    ConfirmAction
  },
  data () {
    return {
      section: Sections.HOME,
      arriesId: ['home', 'construction', 'supplier', 'user', 'stats'],
      buttonsList: {},
      color: '#797979' as string,
      isDataLoaded: false as boolean,
      stylingBorder: '0.40625rem solid #F7A549' as string,
      statsPermissions: defaultPermissions,
      userPermissions: defaultPermissions,
      providerPermissions: defaultPermissions,
      showConfirm: false as boolean
    }
  },
  computed: {
    ...globalModule.mapState([
      'currentPath'
    ]),

    currentUser () {
      return this.$cookies.get('user')
    }
  },
  methods: {
    ...loginModule.mapActions([
      'resetCurrentUser'
    ]),

    computedCheckUserRole () {
      return this.currentUser.rol.id === 8
    },

    goingHome () {
      if (this.currentPath.includes('home')) {
        return null
      } else this.$router.push({ name: 'Home' })
    },

    goingTo (goTo: string, mainPath: string) {
      if (this.currentPath && this.currentPath === mainPath) {
        return null
      } else {
        this.$router.push({ name: goTo })
      }
    },

    launcherActions (goTo: string, id: string, mainPath: string) {
      this.settingBorder(id)
      this.goingTo(goTo, mainPath)
    },

    settingBorder (id: string) {
      this.arriesId.map(elId => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const element = document.getElementById(elId)
        if (element) {
          element.style.border = 'unset'
        }
      })
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const element = document.getElementById(id)
      if (element) {
        element.style.border = this.stylingBorder
      }
    },

    checkUserPermission () {
      this.statsPermissions = this.getPermissions(Sections.STATS)
      this.userPermissions = this.getPermissions(Sections.USERS)
      this.providerPermissions = this.getPermissions(Sections.PROVIDERS)
    },

    async logout () {
      try {
        await sendLogout()
        this.resetCurrentUser()
        this.$cookies.remove('token')
        this.$cookies.remove('user')
        this.$router.push('/')
      } catch (error) {
        console.dir(error)
      } finally {
        this.dialog = false
      }
    }
  },

  watch: {
    currentPath () {
      Object.values(this.buttonsList).map((el:any) => {
        if (this.currentPath.includes(el.id)) {
          this.settingBorder(el.id)
        }
      })
    }
  },

  beforeMount () {
    this.checkUserPermission()
    this.isDataLoaded = true
  }
})
