
























































































































































































import pdf from 'vue-pdf-embed/dist/vue2-pdf-embed'
import { getConstructionsBySupplier } from '@/services/Construction'
import { getContractPdfById, getContractsBySupplierAndConstruction } from '@/services/Contracts'
import { sendSupplierInvoice, uploadInvoiceFile } from '@/services/Bills'
import { getProformaById, getProformaCodesByContract, getProformaCodesAndIdByContract } from '@/services/Proforma'

export default {
  name: 'SuppliersUploadInvoiceForm',

  components: {
    pdf
  },

  data () {
    return {
      contractPdf: {},
      contractDataVisible: false,
      invoiceData: {} as Record<string, string>,
      constructions: [] as Record<string, string>[],
      contracts: [] as Record<string, string>[],
      proformaCodes: [] as number[],
      rules: {
        required: (value: any) => (value && value !== undefined) || 'Requerido'
      },
      fileInvoice: {},
      isErrorVisible: false,
      showSuccessModal: false
    }
  },

  watch: {
    'invoiceData.constructionId': 'getContracts',
    'invoiceData.contractId': 'getProformaCodes'
  },

  computed: {
    supplierId (): string | null {
      return this.$store.state.suppliers.loggedInSupplierId
    }
  },

  methods: {
    async getConstructions (): Promise<void> {
      try {
        const response = await getConstructionsBySupplier(this.supplierId) as Record<any, any>
        this.constructions = response.data
      } catch (error) {
        console.error('Error fetching constructions:', error)
      }
    },

    async getContracts (): Promise<void> {
      const constructionId = this.invoiceData.constructionId
      if (constructionId) {
        try {
          const response = await getContractsBySupplierAndConstruction(this.invoiceData.constructionId, this.supplierId)
          this.contracts = response.data
        } catch (error) {
          console.error('Error fetching contracts:', error)
        }
      }
    },

    async getProformaCodes (): Promise<void> {
      const contractId = this.invoiceData.contractId
      if (contractId) {
        try {
          const response = await getProformaCodesAndIdByContract(contractId)
          this.proformaCodes = response.data
        } catch (error) {
          console.error('Error fetching proforma codes:', error)
        }
      }
    },

    showContractData (): void {
      this.getPdf()
      this.contractDataVisible = true
    },

    async getPdf (): Promise<void> {
      try {
        const response = await getContractPdfById(this.invoiceData.contractId)
        this.contractPdf = response
      } catch (error) {
        console.error(error)
      }
    },

    async sendInvoice (): Promise<void> {
      try {
        if (this.fileInvoice) {
          const { data, status } = await uploadInvoiceFile(this.fileInvoice, this.invoiceData.contractId)
          const proforma = await getProformaById(this.invoiceData.proforma)
          const total = await this.getProformaTotal(proforma)
          if (total !== this.invoiceData.base) {
            this.$fire({
              title: 'El importe base de la factura no coincide con la provisión de origen de la proforma seleccionada',
              type: 'error',
              confirmButtonColor: '#F7A549'
            })
            return
          }
          if (status === 201) {
            this.invoiceData.contract = this.contracts.find((contract: any) => contract.id === this.invoiceData.contractId)
            this.invoiceData.amount = parseFloat(this.invoiceData.amount)
            this.invoiceData.base = parseFloat(this.invoiceData.base)
            this.invoiceData.filePath = data

            const { status } = await sendSupplierInvoice(this.invoiceData)

            if (status === 201) {
              this.$fire({
                title: 'Factura enviada con éxito',
                type: 'success',
                confirmButtonColor: '#F7A549'
              })
            } else {
              throw Error()
            }
          }
        }
      } catch (error) {
        console.log(error)
        this.$showError('Error enviando la factura')
      }
    },

    getProformaTotal (proforma: any): string {
      const calculateSummatory = (measurements) =>
        measurements.reduce((sum, measurement) => {
          if (measurement.totalUnitsPaid > 0 && measurement.measurement.unitPrice > 0) {
            return sum + measurement.measurement.unitPrice * measurement.totalUnitsPaid
          }
          return sum
        }, 0)

      const summatoryOrigin = calculateSummatory(proforma.proformaMeasurement)
      // const summatoryBeforeOrigin = proforma.lastProforma
      //   ? calculateSummatory(proforma.lastProforma.proformaMeasurement)
      //   : 0
      // const summatoryTotal = summatoryOrigin - summatoryBeforeOrigin
      // const retention = summatoryTotal * (proforma.contract.retention / 100)
      // const total = summatoryTotal - retention

      console.log(summatoryOrigin.toFixed(2))

      return summatoryOrigin.toFixed(2)
    },

    inputFilehandleChange (file: any) {
      if (file) {
        this.fileInvoice = file
      }
    },

    closeSuccessModal (): void {
      this.showSuccessModal = false
      this.resetForm()
      this.contractDataVisible = false
      this.contractPdf = {}
    },

    resetForm (): void {
      this.$refs.uploadInvoiceForm.reset()
    }
  },

  mounted (): void {
    this.getConstructions()
  }
}

