





























































































































































































































import Vue from 'vue'
import { createNamespacedHelpers } from 'vuex'
import ConstructionUserCard from '@/components/Constructions/ConstructionUser/ConstructionUserCard.vue'
import LoadingSpinner from '@/components/utils/Loading.vue'

import moment from 'moment'
import { editConstructionById, getConstructionById } from '@/services/Construction'
import { Sections } from '@/shared/enums/Sections'
import { PermissionByRol } from '@/shared/types/Permission'

const constructionModule = createNamespacedHelpers('constructions')
const globalModule = createNamespacedHelpers('global')

export default Vue.extend({
  name: 'ConstructionEditFormSection',
  components: {
    LoadingSpinner,
    ConstructionUserCard
  },
  props: {
    permissions: Object as () => PermissionByRol
  },
  data () {
    return {
      section: Sections.CONSTRUCTIONS,
      constructionsKindList: [
        'Residencial',
        'Centro Comercial',
        'Nave industrial',
        'Oficinas'
      ],
      date: '' as string,
      idConstruction: '' as string,
      isDataLoaded: false as boolean,
      isLoading: false as boolean,
      valid: false as boolean,
      constructionManager: {},
      productionManager: {},
      groupLeader: {}
    }
  },
  computed: {
    ...constructionModule.mapState([
      'constructionEditObject',
      'constructionType',
      'constructionManager',
      'productionDirector',
      'groupManager'
    ]),

    ...globalModule.mapState(['currentDate'])
  },

  methods: {

    ...constructionModule.mapActions([
      'setConstructionEditObject',
      'setConstructionManager',
      'setProductionDirector',
      'setGroupManager',
      'resetConstructionManager',
      'resetProductionDirector',
      'resetGroupManager'
    ]),

    async saveEditConstruction () {
      if (this.usersAreSelected()) {
        this.isLoading = true
        try {
          await editConstructionById(this.idConstruction, this.constructionEditObject)
        } catch (error) {
          console.dir(error)
        } finally {
          this.$router.push({ name: 'Constructions' })
          this.isLoading = false
        }
      }
    },

    usersAreSelected () {
      return this.constructionManager != null &&
             this.productionDirector != null &&
             this.groupManager != null
    },

    clearUsers () {
      this.resetConstructionManager()
      this.resetGroupManager()
      this.resetProductionDirector()
    },

    async settingObjForEdit ():Promise<any> {
      try {
        const response = await getConstructionById(this.idConstruction)
        this.setUsers(response)
        this.setConstructionEditObject(response)
      } catch (error) {
        console.dir(error)
      }
    },

    setUsers (construction: any) {
      if (construction) {
        this.setConstructionManager(construction.constructionManager)
        this.setGroupManager(construction.groupManager)
        this.setProductionDirector(construction.productionDirector)
      }
    }
  },

  watch: {
    'constructionEditObject.date' (value) {
      this.date = moment(value).format('DD/MM/YYYY')
    }
  },

  async beforeMount () {
    this.idConstruction = this.$router.currentRoute.params.id
    this.isLoading = true
    try {
      this.clearUsers()
      await this.settingObjForEdit()
    } catch (error) {
      console.dir(error)
    } finally {
      this.isDataLoaded = true
      this.isLoading = false
    }
  }

})
