








































































import Vue from 'vue'
import { createNamespacedHelpers } from 'vuex'
import { getConstructionFinishedOrOnWork, getTypyeConstrunction } from '@/services/Construction'
import ButtonNewElement from '@/components/utils/ButtonNewElement.vue'
import ConstructionDasboard from '@/components/Constructions/ConstructionDashboard.vue'
import LoadingSpinner from '@/components/utils/Loading.vue'
import { defaultPermissions } from '@/shared/types/Permission'
import { PermissionsMixin } from '@/mixins/PermissionsMixin'
import { Sections } from '@/shared/enums/Sections'

const constructionsModule = createNamespacedHelpers('constructions')
const globalModule = createNamespacedHelpers('global')

export default Vue.extend({
  name: 'ConstructionManagment',
  mixins: [
    PermissionsMixin
  ],
  components: {
    ButtonNewElement,
    ConstructionDasboard,
    LoadingSpinner
  },
  data () {
    return {
      buttonList: {
        onWork: {
          href: '#tab-1',
          key: 'key-1',
          table: 'onWork',
          text: 'Obras en curso',
          value: 'tab-1'
        },
        ends: {
          href: '#tab-3',
          key: 'key-3',
          table: 'ends',
          text: 'Obras finalizadas',
          value: 'tab-3'
        }
      },
      isDataLoaded: false,
      isLoading: false,
      tabs: null,
      permissions: defaultPermissions
    }
  },
  computed: {
    ...constructionsModule.mapState([
      'constructionFinishedList',
      'constructionOnWorkList'
    ]),

    currentUser () {
      return this.$cookies.get('user')
    }
  },
  methods: {
    ...globalModule.mapActions(
      ['setCurrentPath']
    ),
    ...constructionsModule.mapActions([
      'setConstructionFinishedList',
      'setConstructionOnWorkList',
      'setConstructionType',
      'setFilterObject'
    ]),

    async settingFinishedList () {
      try {
        const responseConstructionFinished = await getConstructionFinishedOrOnWork({ finished: true, groupOf: this.currentUser.id }, 0)
        this.setConstructionFinishedList(responseConstructionFinished)
      } catch (error) {
        console.dir(error)
      }
    },

    async settingOnWorkList () {
      try {
        const responseConstructionOnwork = await getConstructionFinishedOrOnWork({ finished: false, groupOf: this.currentUser.id }, 0)
        this.setConstructionOnWorkList(responseConstructionOnwork)
      } catch (error) {
        console.dir(error)
      }
    },

    async settingConstructionType () {
      try {
        const response = await getTypyeConstrunction()
        await this.setConstructionType(response)
      } catch (error) {
        console.dir(error)
      }
    },

    checkPermissions () {
      this.permissions = this.getPermissions(Sections.CONSTRUCTIONS)
    }
  },

  async beforeMount () {
    this.isLoading = true
    this.checkPermissions()
    await this.setFilterObject('')
    await this.settingConstructionType()
    await this.setCurrentPath()
    await this.settingFinishedList()
    await this.settingOnWorkList()
    this.isDataLoaded = true
    this.isLoading = false
  }
})
