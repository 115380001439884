<style lang="scss" scoped>
  @import "@/styles/variables.scss";

  .main {
    background-color: white;
    border-radius: 1.875rem;
    height: 35.25rem;
    padding: 1rem 0 2.6875rem 0;
    overflow: auto;
    &__close {
      padding-right:1.75rem;
      text-align: end;
    }
    &__title {
      font-size: 1.75rem;
      font-weight: 700;
      padding-left:2.375rem;
      text-align: start;
    }
    &__search-bar {
      margin-top: 1rem;
      padding-inline: 2.6875rem 2.375rem ;
        .search {
          background-image: url('../../assets/icons/lupa.svg');
          background-position-x: 0.625rem;
          background-position-y: 1rem;
          background-repeat: no-repeat;
          border-radius: 0.75rem;
          box-shadow: 0 0.25rem 0.25rem rgba(0, 0, 0, 0.25);
          height: 4rem;
          padding-left: 3rem;
          padding-left: 3.5rem;
          width: 100%;
        }
      }
    &__sections {
      padding-inline: 3.1875rem 2.375rem ;
      display: flex;
      margin-top: 2rem;
      .categories {
        &__list {
          .list-title {
            font-size: 1.625rem;
            font-weight: 500;
          }
          ul {
            margin-top: 2rem;
            li{
              padding-inline: 0;
            }
          }
        }
      }
    }
    .see-filter-button {
      margin-top: 4rem;
      padding-right: 2rem;
    }
  }
  .buttons button{
    margin: 5px;
  }

  .v-menu{
    &__content {
      border-radius: 1.875rem;
      box-shadow: unset !important;
    }
  }

</style>

<template lang="pug">
  .filter-wrapper
    LoadingSpinner(v-show="isLoading")
    v-menu(
      bottom
      :close-on-content-click="false"
      :nudge-width="nudgeMenuWidth"
      style="boxShadow:'unset'"
      v-model="openMenu"
      )
      template(v-slot:activator="{ on, attrs }")
        .buttons
          v-tooltip(bottom)
            template(v-slot:activator="{ on, attrs }")
              v-btn(
                fab
                v-bind="attrs"
                v-on="on"
                @click="refreshInfo"
              )
                v-icon() mdi-refresh
            span Restaurar filtros
          v-tooltip(bottom)
            template(v-slot:activator="{ on, attrs }")
              v-btn(
                @click="resetWhenOpen"
                color="tertiary"
                fab
                v-bind="attrs"
                v-on="on"
                )
                img(
                  loading="lazy"
                  src="@/assets/icons/filter.svg"
                )
            span Filtrar
      .main(v-if="openMenu")
        .main__close
          v-btn(
                  @click="closeFilter"
                  color="secondary"
                  fab
                  small
                )
            v-icon(color="white") mdi-close-box-outline
        .main__title
          p
            v-icon.mr-4(@click="arrowAction" color="black") mdi-arrow-left
            span Filtro por nombre
        .main__search-bar
          input(
                autocomplete="false"
                type="text"
                v-model="textForSearch"
                ).search
        //- .main__sections
        //-   .elements
        //-     .elements__list
        //-       p.list-title {{$t('roles')}}
        //-       ul
        //-         template(v-for="(role, index) in computedFilteredCategories")
        //-           li.checkbox-group
        //-             label.label(readonly) {{role.name}}
        //-               input.input-element(
        //-                     type="checkbox"
        //-                     :id="role.role"
        //-                     v-model="arrayChoosenRolesIdsLocal"
        //-                     :value="role.id"
        //-                     :disabled="isDisabled"
        //-               )
        //-               span.span-element
        .see-filter-button(v-if="isShowSearchButton")
          v-btn.see-button(@click="searching" color="tertiary") {{$t('see')}}
            img.ml-1(src="@/assets/icons/double-arrow.svg")
</template>

<script>
import Vue from 'vue'
import { createNamespacedHelpers } from 'vuex'

import { getConstructionFinishedOrOnWork } from '@/services/Construction'

import LoadingSpinner from '@/components/utils/Loading.vue'

const constructionsModule = createNamespacedHelpers('constructions')
const loginModule = createNamespacedHelpers('login')

export default Vue.extend({
  name: 'Filterconstruction',

  props: {
    constructionTypeList: {
      type: String,
      required: false
    }
  },

  components: {
    LoadingSpinner
  },

  data () {
    return {
      openMenu: false,
      arrayChoosenRolesIdsLocal: [],
      isArrowForClose: true,
      isDisabled: false,
      isLoading: false,
      isSearchByName: false,
      isShowSearchButton: false,
      nudgeMenuWidth: '500',
      textForSearch: ''
    }
  },

  computed: {

    ...constructionsModule.mapState([
      'constructionType'
    ]),

    currentUser () {
      return this.$cookies.get('user')
    },

    computedFilteredCategories () {
      const rolesCopy = [...this.constructionType]
      if (this.textForSearch) {
        return rolesCopy.filter(role => {
          return role.name.toLowerCase().includes(this.textForSearch)
        })
      } else {
        return rolesCopy
      }
    }
  },

  methods: {

    ...constructionsModule.mapActions(
      [
        'setConstructionFinishedList',
        'setConstructionOnWorkList',
        'setFilterObject'
      ]
    ),

    arrowAction () {
      if (this.isArrowForClose) {
        this.closeFilter()
      }
    },

    closeFilter () {
      this.openMenu = !this.openMenu
      if (!this.openMenu) {
        this.reset()
      }
    },

    async findingConstructionType () {
      try {
        this.isLoading = true
        const userList = await getConstructionFinishedOrOnWork({ constructionType: 1 }, 0)
        this.setUserList(userList)
        this.setArraChoosenRolesId(this.arrayChoosenRolesIdsLocal)
      } catch (error) {
        console.dir(error)
      } finally {
        this.openMenu = false
        this.isLoading = false
      }
    },

    async searchByName () {
      try {
        if (this.constructionTypeList === 'onWork') {
          this.isLoading = true
          const responseOnWork = await getConstructionFinishedOrOnWork({ finished: false, name: this.textForSearch }, 0)
          await this.setConstructionOnWorkList(responseOnWork)
        }
        if (this.constructionTypeList === 'finished') {
          this.isLoading = true
          const responseFinished = await getConstructionFinishedOrOnWork({ finished: true, name: this.textForSearch }, 0)
          await this.setConstructionFinishedList(responseFinished)
        }
      } catch (error) {
        console.error(error)
      } finally {
        this.isLoading = false
      }
    },

    async searching () {
      await this.searchByName()
      this.setFilterObject(this.textForSearch)
      this.closeFilter()
      // if (this.isSearchByName) {
      // } else {
      //   await this.findingConstructionType()
      //   this.closeFilter()
      // }
    },

    setToSearchByName (value) {
      if (value === '') {
        this.isDisabled = false
        this.isSearchByName = false
        this.isShowSearchButton = false
      } else {
        this.isDisabled = true
        this.isSearchByName = true
        this.isShowSearchButton = true
      }
    },

    reset () {
      this.arrayChoosenRolesIdsLocal = []
      this.isDisabled = false
      this.isSearchByName = false
      this.isShowSearchButton = false
      this.isShowSubcategories = false
      this.nudgeMenuWidth = '513'
      this.textForSearch = ''
      // el array para el search
    },

    resetWhenOpen () {
      this.reset()
      this.openMenu = true
    },

    async refreshInfo () {
      this.setFilterObject('')
      try {
        if (this.constructionTypeList === 'onWork') {
          this.isLoading = true
          const responseOnWork = await getConstructionFinishedOrOnWork({ finished: false, groupOf: this.currentUser.id }, 0)
          await this.setConstructionOnWorkList(responseOnWork)
        }
        if (this.constructionTypeList === 'finished') {
          this.isLoading = true
          const responseFinished = await getConstructionFinishedOrOnWork({ finished: true, groupOf: this.currentUser.id }, 0)
          await this.setConstructionFinishedList(responseFinished)
        }
      } catch (error) {
        console.error(error)
      } finally {
        this.isLoading = false
      }
    }

  },

  watch: {
    textForSearch (value) {
      this.setToSearchByName(value)
    }
  }
  // arrayChoosenRolesIdsLocal (value) {
  //   if (value.length >= 1) {
  //     this.isShowSearchButton = true
  //     this.isDisabled = true
  //   } else {
  //     this.isShowSearchButton = false
  //     this.isDisabled = false
  //   }
  // }
  // }

})
</script>
