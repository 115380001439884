




































































import Vue from 'vue'
import { createNamespacedHelpers } from 'vuex'

import { getSuppliersByPage } from '@/services/Supplier'

import DisplayTable from '@/components/table/DisplayTable.vue'
import FilterSupplier from '@/components/Dialog/FilterSupplier.vue'
import LoadingSpinner from '@/components/utils/Loading.vue'
import Pagination from '@/components/table/Pagination.vue'
import { PermissionsMixin } from '@/mixins/PermissionsMixin'
import { Sections } from '@/shared/enums/Sections'

const categoriesModule = createNamespacedHelpers('categories')
const suppliersModule = createNamespacedHelpers('suppliers')

export default Vue.extend({
  name: 'SupplierDashboard',
  mixins: [
    PermissionsMixin
  ],
  components: {
    DisplayTable,
    FilterSupplier,
    LoadingSpinner,
    Pagination
  },
  props: {
    permissions: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      isDataLoaded: false,
      isLoading: false,
      columns: [
        'name',
        'id',
        'entryDate',
        'updatedAt',
        'blocked'
      ]
    }
  },
  computed: {
    ...suppliersModule.mapState([
      'supplierList'
    ]),

    supplierCompleteObject () {
      if (Object.entries(this.supplierList)) {
        return this.supplierList
      } else return {}
    },

    currentPage () {
      if (this.supplierList && this.supplierList.currentPage) {
        return this.supplierList.currentPage
      } else {
        return 0
      }
    }
  },
  methods: {
    ...categoriesModule.mapActions(
      ['setCategoriesGlobalList']
    ),
    ...suppliersModule.mapActions([
      'resetSupplierList',
      'setSupplierList'
    ]),
    async callAllSupplier () {
      try {
        const response = await getSuppliersByPage(true, 0)
        await this.setSupplierList(response)
      } catch (error) {
        console.dir(error)
      } finally {
        this.isDataLoaded = true
      }
    },

    checkPermissions () {
      this.permissions = this.getPermissions(Sections.PROVIDERS)
    }
  },

  async beforeMount () {
    this.isLoading = true
    this.checkPermissions()
    // await this.setCategoriesGlobalList()
    await this.callAllSupplier()
    this.isDataLoaded = true
    this.isLoading = false
  },

  async beforeDestroy () {
    await this.resetSupplierList()
  }

})
