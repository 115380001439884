
































import Vue from 'vue'

import { createNamespacedHelpers } from 'vuex'

import ContractSupplierstable from '@/components/table/ContractSuppliersTable.vue'
import NoResultMessage from '@/components/utils/NoResultMessage.vue'

const contractModule = createNamespacedHelpers('contracts')

export default Vue.extend({
  name: 'ContractSelectingSupplierDashboard',

  components: {
    ContractSupplierstable,
    NoResultMessage
  },

  data () {
    return {
      showNotResult: false as boolean
    }
  },

  computed: {

    ...contractModule.mapState([
      'supplierSearched'
    ])

  },

  methods: {

    checkSupplierSearchedIsEmpty () {
      this.supplierSearched.length === 0 ? this.showNotResult = true : this.showNotResult = false
    }

  },

  beforeMount () {
    this.checkSupplierSearchedIsEmpty()
  }

})
