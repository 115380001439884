

































































































































































import Vue from 'vue'
import { createNamespacedHelpers } from 'vuex'

import { getUserbById, modifyUserbById, uploadProfileImage, uploadProfileSign } from '@/services/Users'
import InputPhotoProfile from '@/components/User/InputPhotoProfile.vue'
import InputSignProfile from '@/components/User/InputSignProfile.vue'
import ConstructionManagers from '@/components/User/assistantForeman/ConstructionManagers.vue'
import { defaultPermissions } from '@/shared/types/Permission'
import { PermissionsMixin } from '@/mixins/PermissionsMixin'
import { Sections } from '@/shared/enums/Sections'
import ConfirmAction from '../Modals/ConfirmAction.vue'

const rolesModule = createNamespacedHelpers('roles')
const usersModule = createNamespacedHelpers('users')

export default Vue.extend({
  name: 'MyProfile',
  mixins: [
    PermissionsMixin
  ],
  components: {
    ConfirmAction,
    InputPhotoProfile,
    InputSignProfile,
    ConstructionManagers
  },
  props: {
    isMyProfile: {
      type: Boolean,
      required: false
    }
  },

  data () {
    return {
      permissions: defaultPermissions,
      isDataLoaded: false,
      isReadOnly: true,
      name: '',
      surnames: '',
      originalPassword: '',
      showConfirm: false
    }
  },
  computed: {
    currentUser () {
      return this.$cookies.get('user')
    },

    ...rolesModule.mapState([
      'rolesGlobalList'
    ]),

    ...usersModule.mapState([
      'userObjectToModify',
      'photoProfile',
      'constructionManagers'
    ]),

    computedRolSelecDisabled () {
      return this.isMyProfile === true || this.isReadOnly === true
    }
  },
  methods: {
    ...usersModule.mapActions([
      'setUserObjectToModify',
      'setConstructionManagers',
      'resetPhotoProfile',
      'resetPhotoProfileUrl',
      'resetSign',
      'resetSignUrl'
    ]),

    letEdit () {
      this.isReadOnly = !this.isReadOnly
      this.userObjectToModify.password = this.isReadOnly ? this.originalPassword : ''
    },

    async gettingUser () {
      try {
        const response = await getUserbById(this.$router.currentRoute.params.id)
        console.log(response)
        // eslint-disable-next-line no-unused-expressions
        await this.setUserObjectToModify(response)
        await this.setConstructionManagers(response.constructionManagers)
        this.name = this.userObjectToModify.name
        this.surnames = this.userObjectToModify.surnames
        this.originalPassword = this.userObjectToModify.password
      } catch (error) {
        console.dir(error)
      }
    },

    async updateUser () {
      try {
        this.isLoading = true
        this.userObjectToModify.constructionManagers = this.constructionManagers
        const { status, data } = await modifyUserbById(this.userObjectToModify.id, this.userObjectToModify)
        console.log(data)
        if (status === 200) {
          // await this.updateProfileImage()
          // await this.uploadProfi11leSign()
          const userRole = this.$cookies.get('user').rol.name
          const userId = this.$cookies.get('user').id
          if (userRole !== 'Administrador del sistema y usuarios' || (userRole === 'Administrador del sistema y usuarios' && this.userObjectToModify.id === userId)) {
            this.$cookies.set('user', data.data)
          }
          this.$showSuccess('Usuario modificado')
          if (data.message.includes('206')) {
            this.$fire({
              title: 'Actualización del Rol',
              text: 'No fue posible actualizar el rol del usuario debido a que este está asociado a alguna obra',
              type: 'warning',
              confirmButtonColor: '#F7A549'
            })
          }
        }
      } catch (error) {
        this.$showError('Error al actualizar el usuario')
        console.dir(error)
      } finally {
        this.clearStates()
        this.isLoading = false
      }
    },

    async updateProfileImage () {
      uploadProfileImage(this.userObjectToModify.id, this.photoProfile)
    },

    async uploadProfileSign () {
      if (this.sign != null) {
        uploadProfileSign(this.userObjectToModify.id, this.sign)
      }
    },

    clearStates () {
      this.resetPhotoProfile()
      this.resetPhotoProfileUrl()
      this.resetSign()
      this.resetSignUrl()
    },

    checkPermissions () {
      this.permissions = this.getPermissions(Sections.USERS)
    }
  },

  async beforeMount () {
    await this.gettingUser()
    this.checkPermissions()
    this.isDataLoaded = true
  }
})
