














































































import Vue from 'vue'
export default Vue.extend({
  name: 'SectionCard',
  props: {
    goTo: {
      type: String,
      required: true
    },
    icon: {
      type: String,
      required: true
    },
    text1: {
      type: String,
      required: true
    },
    text2: {
      type: String,
      required: false
    },
    title: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      img: 'big-wrench.svg'
    }
  }
})
