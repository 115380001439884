































































































import Vue from 'vue'
import { createNamespacedHelpers } from 'vuex'
import { getProfileImage } from '@/services/Users'

const usersModule = createNamespacedHelpers('users')

export default Vue.extend({
  name: 'InputPhotoProfile',
  props: {
    name: {
      type: String,
      required: false
    },
    logo: {
      type: String,
      required: false
    }
  },
  computed: {
    ...usersModule.mapState([
      'photoProfileUrl',
      'photoProfile'
    ])
  },
  methods: {
    ...usersModule.mapActions([
      'setPhotoProfile',
      'setPhotoProfileUrl',
      'resetPhotoProfile',
      'resetPhotoProfileUrl'
    ]),
    showPreviewImage (event: { target: { files: string[] } }) {
      this.setPhotoProfile(event.target.files[0])
      this.setPhotoProfileUrl(event.target.files[0])
    },
    removeLogo () {
      this.resetPhotoProfile()
      this.resetPhotoProfileUrl()
    },

    async downloadProfileImage () {
      const image = await getProfileImage(this.$router.currentRoute.params.id)
      if (image) {
        this.setPhotoProfileUrl(image)
      }
    }
  },
  mounted () {
    this.removeLogo()
    this.downloadProfileImage()
  }
})
