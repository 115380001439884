import __axios from '@/plugins/axios'
import Vue from 'vue'

function getHeaders () {
  const token = Vue.$cookies.get('token')
  return token ? { Authorization: `Bearer ${token}` } : {}
}

async function createContract (payload: any) {
  const response = await __axios.post('api/contract', payload, { headers: getHeaders() })
  return response
}

async function createExtension (idContract: unknown, payload:any) {
  const response = await __axios.post(`api/contract/${idContract}/create-contract-extension`, payload, { headers: getHeaders() })
  return response.data
}

async function editContract (idContract: number, payload: any) {
  const response = await __axios.put(`api/contract/${idContract}`, payload, { headers: getHeaders() })
  return response.data
}

async function getContractById (idContract: number) {
  const response = await __axios.get(`api/contract/${idContract}`, { headers: getHeaders() })
  return response.data
}

async function getExtensionContract (idContract: number) {
  const response = await __axios.get(`api/contract/${idContract}/contract-extensions/page/${-1}`, { headers: getHeaders() })
  return response.data
}

async function getContractPdfById (idContract: string) {
  const response = await __axios.get(`api/contract/${idContract}/generate-pdf`, { headers: getHeaders() })
  return response.data
}

async function saveContractPdfById (idContract: string) {
  const response = await __axios.get(`api/contract/${idContract}/save-pdf`, { headers: getHeaders() })
  return response.data
}

async function importExcel (idContract: number, file: any) {
  const response = await __axios.post(`api/contract/${idContract}/import-excel`, file, { headers: getHeaders() })
  return response
}

async function sendByEmailContract (payload: any) {
  const response = await __axios.post('api/contract/sendByMail', payload, { headers: getHeaders() })
  return response.data
}

async function removeContract (id: number) {
  const response = await __axios.delete(`api/contract/${id}`, { headers: getHeaders() })
  return response
}

async function getContractsPendingToSignByUser (userId: number) {
  const response = await __axios.get(`api/contract/pendings-to-sign/${userId}`, { headers: getHeaders() })
  return response
}

async function getContractsBySupplierAndConstruction (constructionId: string, supplierId: string): Promise<Record<any, any>> {
  const response = await __axios.get(`/api/contract/construction-provider/${constructionId}/${supplierId}`, { headers: getHeaders() })
  return response
}

export {
  createContract,
  createExtension,
  editContract,
  getContractById,
  getExtensionContract,
  getContractPdfById,
  sendByEmailContract,
  importExcel,
  saveContractPdfById,
  removeContract,
  getContractsPendingToSignByUser,
  getContractsBySupplierAndConstruction
}
