<style lang="scss" scoped>
  @import "@/styles/variables.scss";

  .main {
    background-color: white;
    border-radius: 1.875rem;
    height: 35.25rem;
    padding: 1rem 0 2.6875rem 0;
    overflow: auto;
    &__close {
      padding-right:1.75rem;
      text-align: end;
    }
    &__title {
      font-size: 1.75rem;
      font-weight: 700;
      padding-left:2.375rem;
      text-align: start;
    }
    &__search-bar {
      margin-top: 1rem;
      padding-inline: 2.6875rem 2.375rem ;
        .search {
          background-image: url('../../assets/icons/lupa.svg');
          background-position-x: 0.625rem;
          background-position-y: 1rem;
          background-repeat: no-repeat;
          border-radius: 0.75rem;
          box-shadow: 0 0.25rem 0.25rem rgba(0, 0, 0, 0.25);
          height: 4rem;
          padding-left: 3rem;
          padding-left: 3.5rem;
          width: 100%;
        }
      }
    &__sections {
      padding-inline: 2.6875rem 2.375rem ;
      display: flex;
      flex-direction: row;
      margin-top: 1rem;
      &__categories {
        width: 27rem;
        &__list {
          width: 100%;
          p {
            font-size: 1.75rem;
            font-weight: 700;
          }
          ul {
            padding: 0;
            li {
              list-style-type: none;
              margin-top: 0.5rem;
              padding-inline: 0.5rem 1rem;
              display: flex ;
              justify-content: space-between;
              align-items: center;
              label {
                font-size: 1.5rem;
                font-weight: 400;
                cursor: pointer;
                position: relative;
                width: 100%;
                .span-cat {
                  color: #34495E;
                  padding: 0.5rem 0.25rem;
                }
                .input-category {
                  height: 25px;
                  width: 25px;
                  -webkit-appearance: none;
                  -moz-appearance: none;
                  -o-appearance: none;
                  appearance: none;
                  border: 1px solid black;
                  outline: none;
                  transition-duration: 0.3s;
                  background-color: white;
                  cursor: pointer;
                  float: right;
                  &:checked {
                    border: 1px solid black;
                    background-color: white;
                  }
                  &:checked + span::before {
                    content: '\2713';
                    /* display: block; */
                    text-align: center;
                    color: $corp-yellow;
                    position: absolute;
                    left: 95%;
                    bottom: 5px;
                  }
                  &:active{
                    border: 2px solid #34495E;
                  }
                }
              }
            }
          }
        }
      }
      &__divider {
        align-items: top;
        display: flex;
        justify-content: center;
        margin-top:1rem;
        width: 6.125rem;
        hr {
          border: 2px solid $corp-yellow;
          height: 15.8125rem;
        }
      }
      &__aside {
        &__sections {
          /* overflow: auto; */
          /* height: 18rem; */
          overflow-x: hidden;
          display:flex;
          &__section {
            width: 28rem;
            margin: 0 0 2rem 2rem;
          }
        }
      }
    .space { justify-content: space-between}
    }
  }
  .v-menu{
    &__content {
      overflow: hidden;
      border-radius: 1.875rem;
      box-shadow: unset !important;
      margin: 0 0 4rem 0
    }
  }
</style>

<template lang="pug">
  .filter-wrapper
    LoadingSpinner(v-show="isLoading")
    v-menu(
      bottom
      :close-on-content-click="false"
      :nudge-width="nudgeMenuWidth"
      style="boxShadow:'unset'"
      v-model="isMenuOpen"
      )
      template(v-slot:activator="{ on, attrs }")
            v-btn(
                color="tertiary"
                fab
                v-bind="attrs"
                v-on="on"
              )
              v-tooltip(bottom)
                template(v-slot:activator="{ on, attrs }")
                  img(
                    loading="lazy"
                    src="@/assets/icons/filter.svg"
                    v-bind="attrs"
                    v-on="on"
                  )
                span Filtro
      .main(v-if="isMenuOpen")
        .main__close
          v-btn(
                  @click="toggleOpenCloseFilter"
                  color="secondary"
                  fab
                  small
                )
            v-icon(color="white") mdi-close-box-outline
        .main__title
          p
            v-icon.mr-4(@click="arrowAction" color="black") mdi-arrow-left
            span Filtro por área profesional
        .main__search-bar
          input(
                autocomplete="false"
                type="text"
                v-model="textForSearch"
                ).search
        .main__sections
          .main__sections__categories
            .main__sections__categories__list
              p Categorías
              ul
                li(v-for="(category, index) in computedFilteredCategories" :key='category.name+"id"' :id="category.name")
                  label(readonly) {{category.name}}
                    input.input-category(
                          type="checkbox"
                          :id="category.name"
                          v-model="arrayChoosenCategories"
                          :value="category.id"
                          :disabled="isDisabled"
                    )
                    span.span-cat
            //- .see-filter-button(v-if="lengthArrChoosenCategories>=1")
            .see-filter-button(v-if="lengthArrChoosenCategories>=1 && isShowCategorySeeButton")
              v-btn.see-button(@click.prevent="showingSubcategories" color="tertiary") {{$t('see')}}
                img.ml-1(src="@/assets/icons/double-arrow.svg")
          .main__sections__divider(v-if="isShowSubcategories")
            hr(style="display: inline-block;")
          .main__sections__aside(
              v-if="isShowSubcategories"
            )
            .main__sections__aside__sections(
                                  :class="computedSettingAsideClass"
                                )
              .main__sections__aside__sections__section(v-if="lengthArrayLeftColumnSubcat >= 1")
                .elements
                  .elements__list(v-for="(element, index) in arrayLeftColumnSubcat" :key="element.name")
                    p.list-title {{element.name}}
                    ul
                      li.checkbox-group(
                                          v-for="(subcategory, index) in setSubCategoriesColumnFilterBySearchBar(element.subcategories)"
                                          :key="subcategory.name"
                                          )
                        label.label(readonly) {{subcategory.name}}
                          input.input-element(
                                type="checkbox"
                                :id="subcategory.name"
                                v-model="arrayChoosenSubcategories"
                                :value="subcategory.id"
                          )
                          span.span-element
              .main__sections__aside__sections__section(v-if="lengthArrayRightColumnSubcat >= 1")
                .elements
                  .elements__list(v-for="(element, index) in arrayRightColumnSubcat" :key="element.name")
                    p.list-title {{element.name}}
                    ul
                      li.checkbox-group(
                                          v-for="(subcategory, index) in setSubCategoriesColumnFilterBySearchBar(element.subcategories)"
                                          :key="subcategory.name"
                                          )
                        label.label(readonly) {{subcategory.name}}
                          input.input-element(
                                type="checkbox"
                                :id="subcategory.name"
                                v-model="arrayChoosenSubcategories"
                                :value="subcategory.id"
                          )
                          span.span-element
            .main__sections__aside__button(v-if="computedCheckArrayChoosenSubCategoriesIsEmpty")
              .see-filter-button.text-right
                v-btn.see-button(@click.prevent="searchSupplierBySubcategories" color="tertiary") {{$t('see')}}
                  img.ml-1(src="@/assets/icons/double-arrow.svg")
</template>

<script>
import Vue from 'vue'
import { createNamespacedHelpers } from 'vuex'

import { getSubcategoriesByIdArray } from '@/services/Category'
import { getSuppliersBySubcategories } from '@/services/Supplier'

import LoadingSpinner from '@/components/utils/Loading.vue'

const categoriesModule = createNamespacedHelpers('categories')
const supplierModule = createNamespacedHelpers('suppliers')

export default Vue.extend({
  name: 'FilterSupplier',

  components: {
    LoadingSpinner
  },

  data () {
    return {
      arrayChoosenCategories: [],
      arrayChoosenSubcategories: [],
      arrayLeftColumnSubcat: [],
      arrayRightColumnSubcat: [],
      isArrowForClose: true,
      isDisabled: false,
      isMenuOpen: false,
      isShowCategorySeeButton: true,
      isShowSubcategories: false,
      isLoading: false,
      nudgeMenuWidth: '513',
      subcategoriesList: {},
      textForSearch: ''
    }
  },

  computed: {

    ...categoriesModule.mapState([
      'categoriesGlobalList'
    ]),

    computedCheckArrayChoosenSubCategoriesIsEmpty () {
      return this.arrayChoosenSubcategories.length >= 1
    },

    computedFilteredCategories () {
      if (this.textForSearch && this.isArrowForClose) {
        const categoriesValuesCopy = Object.values({ ...this.categoriesGlobalList })
        return categoriesValuesCopy.filter(category => {
          return category.name.toLowerCase().includes(this.textForSearch)
        })
      } else {
        return Object.values({ ...this.categoriesGlobalList })
      }
    },

    lengthArrChoosenCategories () {
      return this.arrayChoosenCategories.length
    },

    lengthArrayLeftColumnSubcat () {
      return this.arrayLeftColumnSubcat.length
    },
    lengthArrayRightColumnSubcat () {
      return this.arrayRightColumnSubcat.length
    },
    computedSettingAsideClass () {
      if (this.arrayChoosenCategories.length > 1) {
        return 'space'
      } else return ''
    }

  },

  methods: {

    ...supplierModule.mapActions([
      'setArrayChoosenSubcategoriesIds',
      'setSupplierList'
    ]),

    arrowAction () {
      // eslint-disable-next-line no-unused-expressions
      this.isArrowForClose ? this.toggleOpenCloseFilter() : this.backToCategorySection()
    },

    backToCategorySection () {
      this.arrayChoosenSubcategories = []
      this.arrayLeftColumnSubcat = []
      this.arrayRightColumnSubcat = []
      this.isShowSubcategories = false
      this.nudgeMenuWidth = '513'
      this.isDisabled = false
      this.isShowCategorySeeButton = true
      this.isArrowForClose = true
      // cambiar el array de buscador
    },

    dividingSubcategoriesTwoColumns (subCatList) {
      for (let i = 0; i < subCatList.length; i++) {
        if (i % 2 === 0) {
          this.arrayLeftColumnSubcat.push(subCatList[i])
        } else {
          this.arrayRightColumnSubcat.push(subCatList[i])
        }
      }
    },

    async searchSupplierBySubcategories () {
      const payload = { subcategoryIds: this.arrayChoosenSubcategories }
      this.isLoading = true
      try {
        const response = await getSuppliersBySubcategories(payload, 0)
        await this.setArrayChoosenSubcategoriesIds(this.arrayChoosenSubcategories)
        if (response.content.length >= 1) {
          const suppliersList = []
          response.content.map(el => suppliersList.push(el.provider))
          response.content = suppliersList
        }
        this.setSupplierList(response)
      } catch (error) {
        console.dir(error)
      } finally {
        this.isLoading = false
        this.isMenuOpen = false
      }
    },

    setSubCategoriesColumnFilterBySearchBar (subcatList) {
      if (this.textForSearch && this.isArrowForClose === false) {
        const arrFilterBysearch = subcatList.filter(subcat => {
          return subcat.name.toLowerCase().includes(this.textForSearch)
        })
        return arrFilterBysearch
      } else {
        return subcatList
      }
    },

    settingNudgeMenuWidth () {
      if (this.lengthArrChoosenCategories === 0) {
        this.nudgeMenuWidth = '513'
      } else if (this.lengthArrChoosenCategories === 1) {
        this.nudgeMenuWidth = '1054'
      } else {
        this.nudgeMenuWidth = '1567'
      }
    },

    async showingSubcategories () {
      try {
        const objectToSearch = { categoryIds: this.arrayChoosenCategories }
        const response = await getSubcategoriesByIdArray(objectToSearch)
        this.dividingSubcategoriesTwoColumns(response)
        this.isArrowForClose = false
        this.isDisabled = true
        this.isShowCategorySeeButton = false
        this.isShowSubcategories = true
        this.settingNudgeMenuWidth()
      } catch (error) {
        console.dir(error)
      }
    },

    reset () {
      this.arrayChoosenCategories = []
      this.arrayChoosenSubcategories = []
      this.arrayLeftColumnSubcat = []
      this.arrayRightColumnSubcat = []
      this.isArrowForClose = true
      this.isDisabled = false
      this.isShowCategorySeeButton = true
      this.isShowSubcategories = false
      this.nudgeMenuWidth = '513'
      this.subcategoriesList = {}
      this.textForSearch = ''
    },

    toggleOpenCloseFilter () {
      this.isMenuOpen = !this.isMenuOpen
    }

  },
  watch: {
    isMenuOpen () {
      if (this.isMenuOpen === false) {
        this.reset()
      }
    }
  }
})
</script>
