







































import { canCreateProforma } from '@/services/Proforma'
import Vue from 'vue'
import { createNamespacedHelpers } from 'vuex'

import LoadingSpinner from '@/components/utils/Loading.vue'

const constructionsModule = createNamespacedHelpers('constructions')
const contractModule = createNamespacedHelpers('contracts')
const proformaModule = createNamespacedHelpers('proforma')

export default Vue.extend({
  name: 'ButtonNewElement',

  components: {
    LoadingSpinner
  },

  props: {
    goTo: {
      type: String,
      required: true
    },
    text: {
      type: String,
      required: true
    },
    id: {
      type: Number,
      required: false
    }
  },

  data () {
    return {
      isLoading: false as boolean
    }
  },

  computed: {

    ...contractModule.mapState([
      'objectCurrentContract'
    ])

  },

  methods: {
    ...constructionsModule.mapActions([
      'resetRec',
      'setIsShowDocumentationSectionFalse'
    ]),

    async goingToProp () {
      let follow = true
      let message = ''
      if (this.goTo === 'NewProforma') {
        if (this.objectCurrentContract.provider.blocked) {
          this.$fire({
            title: 'Proveedor Bloqueado',
            text: `Todas las operaciones relacionadas con el proveedor ${this.objectCurrentContract.provider.name} están bloqueadas`,
            type: 'error',
            confirmButtonColor: '#F7A549'
          })
        } else {
          const response = await canCreateProforma(this.objectCurrentContract.id).then((res) => { return res.data })

          follow = response.canCreateNew
          message = response.message
        }
      }
      if (follow === true) {
        await this.setIsShowDocumentationSectionFalse()
        await this.resetRec()
        if (this.goTo === 'NewExtension') {
          setTimeout(() => {
            this.$router.push({ name: `${this.goTo}`, params: { id: `${this.id}` } })
          }, 300)
        } else {
          setTimeout(() => {
            this.$router.push({ name: `${this.goTo}` })
          }, 300)
        }
      } else {
        this.$fire({
          title: 'Error creando proforma',
          text: message,
          type: 'error',
          confirmButtonColor: '#F7A549'
        })
      }
    }
  }
})
