




















































































































import Vue from 'vue'
import { createNamespacedHelpers } from 'vuex'

import { getContractsPendingToSignByUser } from '@/services/Contracts'
import { getProformasPendingToSignByUser } from '@/services/Proforma'
import { getBillsPendingToSignByUser } from '@/services/Bills'
import PendingContractsList from '@/components/Contracts/pendingContracts/PendingContractsList.vue'
import PendingProformaList from '@/components/Proforma/pendingProforma/PendingProformaList.vue'
import PendingCardItem from '@/components/shared/PendingCardItem.vue'
import { PendingItem } from '@/shared/types/PendingItem'
import { PendingItemType } from '@/shared/enums/PendingItemTypes'
import { Roles } from '@/shared/enums/Roles'

const globalModule = createNamespacedHelpers('global')
const contractModule = createNamespacedHelpers('contracts')

/*eslint-disable*/
export default Vue.extend({
  name: 'PendingsToSignByUser',
  components: {
    PendingContractsList,
    PendingProformaList,
    PendingCardItem
  },
  props: {
    userId: {
      type: Number,
      required: true
    }
  },

  data () {
    return {
      dialog: false,
      pendingItems: Array as () => PendingItem[],
      monthNames: [
        'Enero',
        'Febrero',
        'Marzo',
        'Abril',
        'Mayo',
        'Junio',
        'Julio',
        'Agosto',
        'Septiembre',
        'Octubre',
        'Noviembre',
        'Diciembre'
      ]
    }
  },

  computed: {
    ...globalModule.mapState ([
      'refreshPendingsAlerts'
    ]),

    getPendingItemsNumber () {
      return this.pendingItems.length
    },

    rolId () {
      return this.$cookies.get('user').rol.id
    }
  },

  methods: {
    ...contractModule.mapActions([
      'setIdCurrentConstruction',
      'setObjectCurrentConstruction',
      'setObjectCurrentContract',
      'settingRec'
    ]),

    async getPendingToSign () {
      try {
        if (Roles.ADMINISTRATION === this.rolId) {
          const { data: bills, status: billStatus} = await getBillsPendingToSignByUser(this.userId)
          if (billStatus === 200) {
            this.addPendingItemsToList(bills, PendingItemType.BILL)
          }
        } else {
          const { data: contracts, status: contractStatus } = await getContractsPendingToSignByUser(this.userId)
          const { data: proformas, status: proformaStatus } = await getProformasPendingToSignByUser(this.userId)
          const { data: bills, status: billStatus} = await getBillsPendingToSignByUser(this.userId)

          if (contractStatus === 200) {
            this.addPendingItemsToList(contracts, PendingItemType.CONTRACT)
          }

          if (proformaStatus === 200) {
            this.addPendingItemsToList(proformas, PendingItemType.PROFORMA)
          }

          if (billStatus === 200) {
            this.addPendingItemsToList(bills, PendingItemType.BILL)
          }
        }
      } catch (err) {
        console.log(err)
      }
    },

    addPendingItemsToList (items: any[], itemType: PendingItemType) {
      for (const item of items) {
        let myItemType = itemType
        if (itemType === PendingItemType.CONTRACT && item.originalContract) {
          myItemType = PendingItemType.EXTENSION
        }
        this.pendingItems.push({
          id: item.id,
          itemType: myItemType,
          title: this.getTitleDependingOfItemType(item, itemType),
          provider: this.getProvider(item, itemType),
          construction: itemType === PendingItemType.CONTRACT ? item?.construction : item?.contract?.construction || undefined,
          contract: itemType === PendingItemType.CONTRACT ? item : item?.contract,
          total: item.totalPrice
        })
      }
    },

    getProvider (item: any, itemType: PendingItemType) {
      switch (itemType) {
        case PendingItemType.CONTRACT:
          const provider = item.provider
          if (provider) {
            provider['category'] = item.providerCategory
          }
          return provider
        default:
          const myProvider = item?.contract.provider
          if (myProvider) {
            myProvider['category'] = item?.contract.providerCategory
          }
          return myProvider
      }
    },

    getTitleDependingOfItemType (item: any, itemType: PendingItemType) {
      switch (itemType) {
        case PendingItemType.CONTRACT:
          return item.construction.name
        case PendingItemType.PROFORMA:
          return item.contract.construction.name;
        default:
          return this.monthNames[new Date(item.createdAt).getMonth()] + ' ' + (item.createdAt).substring(0,4)
      }
    },

    closingDialog () {
      this.dialog = false
    },

    handleAction (item: PendingItem) {
      switch (item.itemType) {
        case PendingItemType.CONTRACT: this.goToEditContract(item); break
        case PendingItemType.EXTENSION: this.goToEditContract(item); break
        case PendingItemType.PROFORMA: this.goToEditproforma(item); break
        case PendingItemType.BILL: this.goToEditBill(item); break
      }
    },

    goToEditContract (item: PendingItem) {
      this.setIdCurrentConstruction(item.construction.id)
      this.setObjectCurrentContract(item.contract)
      this.settingRec(item.contract)
      this.setObjectCurrentConstruction(item.construction)
      this.dialog = false
      this.$router.push(`/contract-signed-state/${item.id}`)
    },

    goToEditproforma (item: PendingItem) {
      this.setObjectCurrentContract(item.contract)
      this.setObjectCurrentConstruction(item.construction)
      this.dialog = false
      const currentRoute = this.$route.path
    const targetRoute = `/edit-proforma/${item.id}`
    const targetQuery = { contractId: String(item.contract.id) }

    if (currentRoute === targetRoute) {
      this.$router.push({ path: currentRoute, query: targetQuery }).catch(err => {})
      this.$root.$emit('refreshData', targetQuery.contractId)
    } else {
      this.$router.push({ path: targetRoute, query: targetQuery })
    }
  },

    goToEditBill (item: PendingItem) {
      this.setObjectCurrentContract(item.contract)
      this.setObjectCurrentConstruction(item.construction)
      this.dialog = false
      this.$router.push(`/edit-bill/${item.id}`)
    },
  },

  mounted () {
    this.pendingItems = [];
    this.getPendingToSign()
  },

  watch: {
    'refreshPendingsAlerts' (value) {
      this.pendingItems = [];
      this.getPendingToSign()
    }
  }

})
