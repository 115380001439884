













































































































import Vue from 'vue'
import { createNamespacedHelpers } from 'vuex'
import { PendingItem } from '@/shared/types/PendingItem'
import { PendingItemType } from '@/shared/enums/PendingItemTypes'

export default Vue.extend({
  name: 'PendingCardItem',
  props: {
    itemType: {
      type: String as () => PendingItemType,
      required: true
    },
    item: {
      type: Object as () => PendingItem,
      required: true
    }
  },
  computed: {
    bgColor () {
      const classes = 'pending-card '
      return this.itemType === PendingItemType.CONTRACT
        ? classes.concat(' contract-bg')
        : this.itemType === PendingItemType.PROFORMA
          ? classes.concat('proforma-bg')
          : this.itemType === PendingItemType.BILL
            ? classes.concat('bill-bg')
            : classes.concat('extension-bg')
    }
  },
  methods: {
    emitAction () {
      this.$emit('action')
    }
  }
})
