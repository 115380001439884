import Vue from 'vue'
import Router, { NavigationGuardNext, Route, RouteConfig } from 'vue-router'
import NotFound from '@/views/404/NotFound.vue'
import AccessDenied from '@/views/accessDenied/AccessDenied.vue'
import routesConstructions from '@/router/sections/routerConstructions'
import routesContracts from '@/router/sections/routerContracts'
import routesExtensions from '@/router/sections/routerExtensions'
import routesGlobal from '@/router/sections/routerGlobal'
import routesSuppliers from '@/router/sections/routerSuppliers'
import routesUsers from '@/router/sections/routerUsers'
import routesStats from '@/router/sections/routerStats'
import routesProforma from '@/router/sections/routerProforma'
import routesBill from '@/router/sections/routerBills'
import VueCookies from 'vue-cookies-reactive'
Vue.use(Router)
Vue.use(VueCookies)

const routes = routesUsers.concat(routesGlobal).concat(routesSuppliers)
  .concat(routesConstructions).concat(routesStats).concat(routesContracts)
  .concat(routesProforma).concat(routesExtensions).concat(routesBill)
  .concat([
    {
      path: '*',
      meta: {
        requiresAuth: true,
        requiredRoles: []
      },
      component: NotFound
    },
    {
      path: '/access-denied',
      meta: {
        requiresAuth: true,
        requiredRoles: []
      },
      component: AccessDenied
    }
  ])

const router = new Router({
  routes,
  mode: 'history'
})

const needToken = (to: Route): boolean => {
  const token = Vue.$cookies.get('token')

  if (to.meta.requiresAuth && !token) {
    return true
  }

  return false
}

const checkRole = (to: Route): boolean => {
  const rolId = Vue.$cookies.get('user')?.rol.id
  const requiredRoles = to.meta.requiredRoles

  if (requiredRoles.length === 0) {
    return true
  }

  if (rolId && requiredRoles.includes(rolId)) {
    return true
  }

  return false
}

const routeValidations = (to: Route, from: Route, next: NavigationGuardNext) => {
  if (needToken(to)) {
    next('/')
  } else if (checkRole(to)) {
    next()
  } else {
    next('/access-denied')
  }
}

const isSupplierInvoiceRoute = (to: Route): boolean => {
  const isSupplierInvoiceRoute = to.meta?.supplierInvoiceRoute
  if (isSupplierInvoiceRoute && isSupplierInvoiceRoute === true) {
    return true
  }

  return false
}

const supplierInvoiceRouteValidation = (to: Route, from: Route, next: NavigationGuardNext) => {
  if (needToken(to)) {
    next('/suppliers/login')
  } else {
    next()
  }
}

const normalRouteValidation = (to: Route, from: Route, next: NavigationGuardNext) => {
  if (to.path === '/') {
    next()
  } else {
    routeValidations(to, from, next)
  }
}

router.beforeEach((to, from, next) => {
  if (isSupplierInvoiceRoute(to)) {
    supplierInvoiceRouteValidation(to, from, next)
  } else {
    normalRouteValidation(to, from, next)
  }
})

export default router
