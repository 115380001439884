




































import { generateProfomaPDF } from '@/services/Proforma'
import Vue from 'vue'

export default Vue.extend({

  name: 'ProformaPrint',

  props: {
    idProforma: {
      type: Number,
      required: true
    }
  },

  data () {
    return {
      dialog: false as boolean
    }
  },

  methods: {
    async printProforma () {
      try {
        const response = await generateProfomaPDF(this.idProforma)

        const blob = new Blob([response], { type: 'application/pdf' })

        const dataUrl = URL.createObjectURL(blob)

        const pdfWindow = window.open(dataUrl)

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        pdfWindow.print()
      } catch (error) {
        console.error(error)
      } finally {
        this.dialog = false
      }
    }
  }

})
