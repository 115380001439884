
















































import Vue from 'vue'
import { createNamespacedHelpers } from 'vuex'

import BackArrowComp from '@/components/utils/BackArrowComp.vue'
import LoadingSpinner from '@/components/utils/Loading.vue'
import ProformaContractDataHeader from '@/components/Proforma/table/ProformaContractDataHeader.vue'
import ProformaTable from '@/components/Proforma/ProformaTable.vue'
import { defaultPermissions } from '@/shared/types/Permission'
import { PermissionsMixin } from '@/mixins/PermissionsMixin'
import { Sections } from '@/shared/enums/Sections'
import { createProforma, getProformaById } from '@/services/Proforma'

const proformaModule = createNamespacedHelpers('proforma')
const contractModule = createNamespacedHelpers('contracts')
const globalModule = createNamespacedHelpers('global')
const loginModule = createNamespacedHelpers('login')
const suppliersModule = createNamespacedHelpers('suppliers')

export default Vue.extend({
  name: 'NewProforma',
  mixins: [PermissionsMixin],
  components: {
    BackArrowComp,
    LoadingSpinner,
    ProformaContractDataHeader,
    ProformaTable
  },
  data () {
    return {
      isDataLoaded: false as boolean,
      isLoading: false as boolean,
      reRender: 0,
      permissions: defaultPermissions,
      proformaData: {}
    }
  },
  computed: {
    ...suppliersModule.mapState(['selectedSupplier']),
    ...contractModule.mapState(['objectCurrentConstruction', 'rec', 'selectedContractId']),
    ...globalModule.mapState(['currentDate']),
    ...loginModule.mapState(['currentUser']),
    ...proformaModule.mapState(['newProformaObject'])
  },
  methods: {
    ...proformaModule.mapActions(['setNewProformaObject']),
    async gettigProforma () {
      try {
        const response = await getProformaById(this.newProformaObject.id)
        if (response.lastProforma) {
          const lastProforma = await getProformaById(response.lastProforma.id)
          response.lastProforma = lastProforma
        }
        await this.setNewProformaObject(response)
      } catch (error) {
        console.error(error)
      }
      this.isLoading = false
    },
    async getProformaAgain () {
      await this.gettigProforma()
      this.reRender += 1
    },
    async creatingProforma () {
      try {
        this.isLoading = true
        const userId = this.$cookies.get('user').id
        const contractObject = {
          contract: this.selectedContractId,
          createdBy: userId
        }

        const { status, data } = await createProforma(contractObject)

        if (status === 201) {
          await this.setNewProformaObject(data.message)
          this.proformaData = data.message // Set the proforma data
          this.$showSuccess('Proforma creada')
        } else {
          throw new Error()
        }
      } catch (error) {
        console.error(error)
        this.$showError('Error al crear proforma')
      } finally {
        this.isDataLoaded = true
        this.isLoading = false
      }
    },
    checkPermissions () {
      this.permissions = this.getPermissions(Sections.PROFORMA)
    }
  },
  async beforeMount () {
    this.checkPermissions()
    await this.creatingProforma()
  }
})
