
































import Vue from 'vue'
import { createNamespacedHelpers } from 'vuex'

import BackArrowComp from '@/components/utils/BackArrowComp.vue'
import LoadingSpinner from '@/components/utils/Loading.vue'
import LogoSupplierCard from '@/components/Suppliers/Logo/LogoSupplierCard.vue'
import NewSupplierForm from '@/components/Suppliers/NewSupplierForm.vue'

const globalModule = createNamespacedHelpers('global')

export default Vue.extend({
  name: 'NewSupplier',
  components: {
    BackArrowComp,
    LoadingSpinner,
    LogoSupplierCard,
    NewSupplierForm
  },
  data () {
    return {
      isLoading: false as boolean
    }
  },
  methods: {
    ...globalModule.mapActions(
      ['setCurrentPath']
    )
  },
  beforeMount () {
    this.isLoading = true
    this.setCurrentPath()
    this.isLoading = false
  }
})
