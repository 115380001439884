<style lang="scss" scoped>
  @import "@/styles/variables.scss";

  .main {
    background-color: white;
    border-radius: 1.875rem;
    height: 35.25rem;
    padding: 1rem 0 2.6875rem 0;
    overflow: auto;
    &__close {
      padding-right:1.75rem;
      text-align: end;
    }
    &__title {
      font-size: 1.75rem;
      font-weight: 700;
      padding-left:2.375rem;
      text-align: start;
    }
    &__search-bar {
      margin-top: 1rem;
      padding-inline: 2.6875rem 2.375rem ;
        .search {
          background-image: url('../../assets/icons/lupa.svg');
          background-position-x: 0.625rem;
          background-position-y: 1rem;
          background-repeat: no-repeat;
          border-radius: 0.75rem;
          box-shadow: 0 0.25rem 0.25rem rgba(0, 0, 0, 0.25);
          height: 4rem;
          padding-left: 3rem;
          padding-left: 3.5rem;
          width: 100%;
        }
      }
    &__sections {
      padding-inline: 3.1875rem 2.375rem ;
      display: flex;
      margin-top: 2rem;
      .categories {
        &__list {
          .list-title {
            font-size: 1.625rem;
            font-weight: 500;
          }
          ul {
            margin-top: 2rem;
            li{
              padding-inline: 0;
            }
          }
        }
      }
    }
  }

  .v-menu{
    &__content {
      border-radius: 1.875rem;
      box-shadow: unset !important;
    }
  }

</style>

<template lang="pug">
  .filter-wrapper
    LoadingSpinner(v-show="isLoading")
    v-menu(
      bottom
      :close-on-content-click="false"
      :nudge-width="nudgeMenuWidth"
      style="boxShadow:'unset'"
      v-model="openMenu"
      )
      template(v-slot:activator="{ on, attrs }")
        v-btn(
                @click="resetWhenOpen"
                color="tertiary"
                fab
                v-bind="attrs"
                v-on="on"
              )
          v-tooltip(bottom)
            template(v-slot:activator="{ on, attrs }")
              img(
                loading="lazy"
                src="@/assets/icons/filter.svg"
                v-bind="attrs"
                v-on="on"
              )
            span Filtro
      .main(v-if="openMenu")
        .main__close
          v-btn(
                  @click="closeFilter"
                  color="secondary"
                  fab
                  small
                )
            v-icon(color="white") mdi-close-box-outline
        .main__title
          p
            v-icon.mr-4(@click="arrowAction" color="black") mdi-arrow-left
            span {{$t('profesional area filter')}}
        .main__search-bar
          input(
                autocomplete="false"
                type="text"
                v-model="textForSearch"
                ).search
        .main__sections
          .elements
            .elements__list
              p.list-title {{$t('roles')}}
              ul
                template(v-for="(role, index) in computedFilteredCategories")
                  li.checkbox-group
                    label.label(readonly) {{role.name}}
                      input.input-element(
                            type="checkbox"
                            :id="role.role"
                            v-model="arrayChoosenRolesIdsLocal"
                            :value="role.id"
                            :disabled="isDisabled"
                      )
                      span.span-element
            .see-filter-button(v-if="computedArrChoosenCategories>=1")
              v-btn.see-button(@click="findingUsersByRoles" color="tertiary") {{$t('see')}}
                img.ml-1(src="@/assets/icons/double-arrow.svg")
</template>

<script>
import Vue from 'vue'
import { createNamespacedHelpers } from 'vuex'

import { findUsersByRoles } from '@/services/Users'

import LoadingSpinner from '@/components/utils/Loading.vue'

const rolesModule = createNamespacedHelpers('roles')
const usersModule = createNamespacedHelpers('users')

export default Vue.extend({
  name: 'FilterSupplier',

  components: {
    LoadingSpinner
  },

  data () {
    return {
      openMenu: false,
      arrayChoosenRolesIdsLocal: [],
      isArrowForClose: true,
      isDisabled: false,
      isLoading: false,
      nudgeMenuWidth: '500',
      textForSearch: ''
    }
  },

  computed: {
    ...rolesModule.mapState({
      rolesObjectList: (state) => state.rolesGlobalList
    }),
    computedFilteredCategories () {
      const rolesCopy = [...this.rolesObjectList]
      if (this.textForSearch) {
        return rolesCopy.filter(role => {
          return role.name.toLowerCase().includes(this.textForSearch)
        })
      } else {
        return rolesCopy
      }
    },

    computedArrChoosenCategories () {
      return this.arrayChoosenRolesIdsLocal.length
    }

  },

  methods: {

    ...usersModule.mapActions(
      [
        'setArraChoosenRolesId',
        'setUserList'
      ]
    ),

    arrowAction () {
      // eslint-disable-next-line no-unused-expressions
      this.isArrowForClose ? this.closeFilter() : null
    },

    closeFilter () {
      this.openMenu = !this.openMenu
      // eslint-disable-next-line no-unused-expressions
      this.openMenu === false ? this.reset() : null
    },

    async findingUsersByRoles () {
      try {
        this.isLoading = true
        const userList = await findUsersByRoles({ rolsIds: this.arrayChoosenRolesIdsLocal }, 0)
        this.setUserList(userList)
        this.setArraChoosenRolesId(this.arrayChoosenRolesIdsLocal)
      } catch (error) {
        console.dir(error)
      } finally {
        this.openMenu = false
        this.isLoading = false
      }
    },

    reset () {
      this.arrayChoosenRolesIdsLocal = []
      this.computedArrChoosenCategories = 0
      this.isDisabled = false
      this.isShowSubcategories = false
      this.nudgeMenuWidth = '513'
      // el array para el search
    },
    resetWhenOpen () {
      this.reset()
    }
  }
})
</script>
