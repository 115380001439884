import { RouteConfig } from 'vue-router'

import ContractSuppliersList from '@/views/contracts/ContractSuppliersList.vue'
import ContractsListManagment from '@/views/contracts/ContractsListManagment.vue'
import EditContract from '@/views/contracts/EditContract.vue'
import NewContract from '@/views/contracts/NewContract.vue'
import SignedStateManagment from '@/views/contracts/SignedStateManagment.vue'
import { Roles } from '@/shared/enums/Roles'
import RelatedDocuments from '@/views/contracts/RelatedDocuments.vue'

const commonRoles = Object.values(Roles)

const routes: Array<RouteConfig> =
[
  {
    path: '/contracts-list/:id',
    name: 'ContractsListManagment',
    component: ContractsListManagment,
    meta: {
      requiresAuth: true,
      requiredRoles: commonRoles
    }
  },
  {
    path: '/new-contract',
    name: 'NewContract',
    component: NewContract,
    meta: {
      requiresAuth: true,
      requiredRoles: commonRoles.filter((rol: Roles) => [
        Roles.ASSISTAN_FOREMAN,
        Roles.CONSTRUCTION_MANAGER,
        Roles.GROUP_MANAGER,
        Roles.PRODUCTION_DIRECTOR,
        Roles.SHOPPING
      ].includes(rol))
    }
  },
  {
    path: '/edit-contract/:id',
    name: 'EditContract',
    component: EditContract,
    meta: {
      requiresAuth: true,
      requiredRoles: commonRoles
    }
  },
  {
    path: '/contract-suppliers-list',
    name: 'ContractSuppliersList',
    component: ContractSuppliersList,
    meta: {
      requiresAuth: true,
      requiredRoles: commonRoles
    }
  },
  {
    path: '/contract-signed-state/:id',
    name: 'SignedSateManagment',
    component: SignedStateManagment,
    meta: {
      requiresAuth: true,
      requiredRoles: commonRoles
    }
  },
  {
    path: '/contracts-list/related-docs/:id',
    name: 'RelatedDocuments',
    component: RelatedDocuments,
    meta: {
      requiresAuth: true,
      requiredRoles: commonRoles
    }
  }
]

export default routes
