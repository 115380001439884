





























































































































































































import Vue from 'vue'
import { createNamespacedHelpers } from 'vuex'

import moment from 'moment'
import ConstructionUserCard from '@/components/Constructions/ConstructionUser/ConstructionUserCard.vue'

const constructionsModule = createNamespacedHelpers('constructions')
const globalModule = createNamespacedHelpers('global')

export default Vue.extend({
  name: 'ConstructionNewForm',
  data () {
    return {
      supplierDateMenu: '' as string,
      valid: false
    }
  },
  components: {
    ConstructionUserCard
  },
  computed: {
    ...constructionsModule.mapState([
      'constructionType',
      'rec'
    ]),
    ...globalModule.mapState([
      'currentDate'
    ])
  },
  watch: {
    'rec.date' (value) {
      this.supplierDateMenu = moment(value).format('DD/MM/YYYY')
    }
  }
})
