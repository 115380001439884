



























































































import Vue from 'vue'
import { createNamespacedHelpers } from 'vuex'
import { deleteExtension } from '@/services/ExtensionsAndProformas'
import { deleteProforma } from '@/services/Proforma'

const contractModule = createNamespacedHelpers('contracts')

export default Vue.extend({
  name: 'ExtensionAndProformListTable',

  props: {
    extensionAndProformaList: {
      type: Array,
      required: true
    },
    contractId: {
      type: String,
      required: true
    }
  },

  data () {
    return {
      bgWhite: 'bg-white',
      headers: [
        {
          text: 'TIPO DE DOCUMENTO',
          value: 'type',
          class: 'grey'
        },
        {
          text: 'CÓDIGO',
          value: 'name',
          class: 'grey'
        },
        {
          text: 'PRECIO',
          value: 'price',
          class: 'grey'
        },
        {
          text: 'FECHA CREACIÓN',
          value: 'createdAt',
          class: 'grey'
        },
        {
          text: 'ESTADO',
          value: 'status',
          class: 'grey'
        },
        {
          text: '',
          value: 'detail',
          sortable: false,
          class: 'grey'
        }
      ]
    }
  },

  computed: {
    ...contractModule.mapState([
      'objectCurrentConstruction',
      'objectCurrentContract'
    ])
  },

  methods: {
    ...contractModule.mapActions([
      'settingCameFromExtensions',
      'settingSelectedContractId'
    ]),

    goToDetail (type: string, id: number) {
      type === 'Proforma'
        ? this.$router.push(`/edit-proforma/${id}`)
        : type === 'Ampliación'
          ? this.goToExtensionDetail(id)
          : this.$router.push(`/edit-bill/${id}`)
    },

    canDelete (item) {
      console.log(this.extensionAndProformaList)
      const currentUser = this.$cookies.get('user')
      const currentUserId = currentUser.id
      const currentUserRole = currentUser.rol.name
      const isJefe = currentUserRole === 'Jefe de obra'
      const areJefes = currentUserRole === 'Jefe de obra' || 'Jefe de grupo'
      if (item.type === 'Ampliación') {
        const signedAmpliacion = item.content.signedByGM === false
        let createdByCurrentUserAmpliacion = false
        if (item.content.createdBy) {
          createdByCurrentUserAmpliacion = item.content.createdBy.id === currentUserId
        }
        return signedAmpliacion && (isJefe || createdByCurrentUserAmpliacion)
      }
      if (item.type === 'Proforma') {
        const signedProforma = item.content.signedByGM === false
        let createdByCurrentUserProforma = false
        if (item.content.createdBy) {
          createdByCurrentUserProforma = item.content.createdBy.id === currentUserId
        }
        return signedProforma && (areJefes || createdByCurrentUserProforma)
      }
      return false
    },

    async deleteExtensionOrProforma (item) {
      if (item.type === 'Ampliación') {
        await deleteExtension(item.content.id)
        window.location.reload()
      }
      if (item.type === 'Proforma') {
        await deleteProforma(item.content.id)
        window.location.reload()
      }
    },

    goToExtensionDetail (id: number) {
      this.settingCameFromExtensions(true)
      console.log(this.contractId)
      this.settingSelectedContractId(this.contractId)
      this.$router.push(`/edit-contract/${id}`)
    }
  }
})
