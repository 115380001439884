import __axios from '@/plugins/axios'
import Vue from 'vue'

function getHeaders () {
  const token = Vue.$cookies.get('token')
  return token ? { Authorization: `Bearer ${token}` } : {}
}

async function createProforma (proformaData: any) {
  const response = await __axios.post('api/proforma', proformaData, { headers: getHeaders() })
  return response
}

async function deleteProforma (idProforma:number) {
  const response = await __axios.delete(`api/proforma/${idProforma}`, { headers: getHeaders() })
  return response
}

async function editProforma (idProforma: number, payload: any) {
  const response = await __axios.put(`api/proforma/${idProforma}`, payload, { headers: getHeaders() })
  return response
}

async function updateSigns (idProforma: number, payload: any) {
  const response = await __axios.put(`api/proforma/${idProforma}/update-signs`, payload, { headers: getHeaders() })
  return response.data
}

async function getProformaById (idProforma: string) {
  const response = await __axios.get(`api/proforma/${idProforma}`, { headers: getHeaders() })
  return response.data
}

async function getProformaFromContractId (idContract: string) {
  const response = await __axios.get(`api/proforma/contract/${idContract}`, { headers: getHeaders() })
  return response.data
}

async function generateProfomaPDF (idProforma: number) {
  const response = await __axios.get(`api/proforma/${idProforma}/generate-pdf`, { headers: getHeaders() })
  return response.data
}

async function sendByEmailProforma (payload: any) {
  const response = await __axios.post('api/proforma/sendByMail', payload, { headers: getHeaders() })
  return response.data
}

async function getProformasPendingToSignByUser (userId: number) {
  const response = await __axios.get(`api/proforma/pendings-to-sign/${userId}`, { headers: getHeaders() })
  return response
}

async function canCreateProforma (contractId: number) {
  const response = await __axios.get(`api/proforma/already-exists-proforma-current-month/${contractId}`, { headers: getHeaders() })
  return response
}

async function getProformaCodesByContract (contractId: number) {
  const response = await __axios.get(`api/proforma/codes/${contractId}`, { headers: getHeaders() })
  console.log(response)
  return response
}
async function getProformaCodesAndIdByContract (contractId: number) {
  const response = await __axios.get(`api/proforma/codes-and-id/${contractId}`, { headers: getHeaders() })
  console.log(response)
  return response
}

export {
  createProforma,
  editProforma,
  getProformaById,
  getProformaFromContractId,
  generateProfomaPDF,
  updateSigns,
  sendByEmailProforma,
  deleteProforma,
  getProformasPendingToSignByUser,
  canCreateProforma,
  getProformaCodesByContract,
  getProformaCodesAndIdByContract
}
