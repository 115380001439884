















































































































import Vue from 'vue'
import { createNamespacedHelpers } from 'vuex'

import { createExtension, getContractById, importExcel } from '@/services/Contracts'

import BackArrowComp from '@/components/utils/BackArrowComp.vue'
import ContractSelectingProvider from '@/components/Contracts/ContractSelectingProvider.vue'
import NewExtensionListTable from '@/components/extensions/NewExtensionListTable.vue'
import LoadingSpinner from '@/components/utils/Loading.vue'
import { ObjectContarct } from '@/store/types'

const contractModule = createNamespacedHelpers('contracts')
const extensionModule = createNamespacedHelpers('extensions')

export default Vue.extend({

  name: 'NewExtension',

  components: {
    BackArrowComp,
    ContractSelectingProvider,
    NewExtensionListTable,
    LoadingSpinner
  },

  data () {
    return {
      contractData: {} as ObjectContarct,
      isDataLoaded: false as boolean,
      isLoading: false as boolean,
      fileArray: [],
      valid: false,
      codigoContrato: '',
      userId: this.$cookies.get('user').id,
      codigoContratoRules: [v => !!v || 'El código de contrato es requerido']
    }
  },

  computed: {
    ...contractModule.mapState([
      'idCurrentConstruction',
      'selectedContractId'
    ]),

    ...extensionModule.mapState([
      'idToExtension'
    ]),

    goTo () {
      if (this.selectedContractId !== 0) {
        return `/contracts-list/related-docs/${this.selectedContractId}`
      }

      return `/contracts-list/${this.idCurrentConstruction}`
    }
  },

  methods: {

    async createExtension () {
      const payload = {
        createdBy: this.userId,
        codigoContrato: this.codigoContrato
      }
      try {
        const response = await createExtension(this.selectedContractId, payload)
        await this.importingFile(response.message.id)
        this.$router.push(`/contracts-list/related-docs/${this.selectedContractId}`)
        this.$showSuccess('Ampliación creada correctamente')
      } catch (error: any) {
        this.$showError('Error al crear la ampliación')
      }
    },

    validateCodigoContrato () {
      this.valid = this.codigoContratoRules.every(rule => rule(this.codigoContrato) === true)
    },

    inputFilehandleChange (e: any) {
      if (e.target.files[0]) {
        this.isLoading = true
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        this.fileArray.push(e.target.files[0])
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        // document.getElementById('file').value = null
        this.isLoading = false
      }
    },

    async gettingContractInfo () {
      try {
        this.isLoading = true
        this.contractData = await getContractById(this.$route.params.id)
      } catch (error) {
        console.error(error)
      } finally {
        this.isDataLoaded = true
        this.isLoading = false
      }
    },

    async importingFile (idExtension: number) {
      const formData = new FormData()
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      if (this.fileArray.length >= 1) {
        try {
          console.log(111111)
          for (let i = 0; i < this.fileArray.length; i++) {
            formData.append('files', this.fileArray[i])
          }
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          await importExcel(idExtension, formData)
        } catch (error) {
          console.error(777777, error)
          this.$fire({
            title: 'Error subiendo excell',
            text: 'Por favor corrige el excell y vuelve a intentarlo',
            type: 'error',
            confirmButtonColor: '#F7A549'
          })
        } finally {
          this.fileArray = []
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          document.getElementById('file').value = null
        }
      }
    }
  },

  beforeMount () {
    this.gettingContractInfo()
  }

})
