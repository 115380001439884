













import Vue from 'vue'
import CreateNewPassword from '@/components/Landing/CreateNewPassword.vue'

export default Vue.extend({
  name: 'NewPassword',
  components: {
    CreateNewPassword
  },
  data () {
    return {
      isShowRemenberPassword: false
    }
  },
  methods: {
    showingRemenbercomponent () {
      this.isShowRemenberPassword = true
    }
  }
})
