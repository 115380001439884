





































































































































import Vue from 'vue'

export default Vue.extend({
  name: 'NewExtensionListTable',

  props: {
    contractData: {
      type: Object,
      required: true
    }
  },

  data () {
    return {
      headers: [
        'construction',
        'name',
        'M2',
        'price',
        'observations',
        'contract state'
      ],
      isDataLoaded: false as boolean,
      proformList: [
        {
          construction: 'x124',
          name: 'x124asdasd',
          M2: 200,
          price: 200,
          observations: 'wqweqweqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqq'
        }
      ],
      stateItems: [
        { state: 'Completo', id: '1' },
        { state: 'Pendiente de firmar', id: '2' }
      ],
      proformStatus: '' as string
    }
  },

  methods: {
    checkContractStatus () {
      if (this.contractData.signedByCM &&
          this.contractData.signedByGM &&
          this.contractData.signedByPD
      ) {
        this.proformStatus = 'Completo'
      } else {
        this.proformStatus = 'Pendiente de firma'
      }
    }
  },

  beforeMount () {
    this.checkContractStatus()
    this.isDataLoaded = true
  }

})
