import { GlobalPermissions, Permission, PermissionByRol } from '../shared/types/Permission'
import { Sections } from '../shared/enums/Sections'
import { Roles, RolNames } from '../shared/enums/Roles'
import Vue from 'vue'

export const PermissionsMixin = {
  methods: {
    getPermissions (section: Sections): PermissionByRol {
      const rolId = Vue.$cookies.get('user').rol.id
      const requiredSection = GlobalPermissions[section]

      if (requiredSection) {
        const rolPermissions = requiredSection[rolId]
        return rolPermissions
      }

      return undefined
    }
  }
}
